import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  return <WrappedComponent {...props} params={params} navigate={navigate} />;
};
export default withRouter;
