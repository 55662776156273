import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Button, Col, Row, Typography } from 'antd';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useParams } from 'react-router-dom';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import { mapBoxAccessToken } from '../../core/constant';

const SosMap = () => {
  const { id } = useParams();
  const mapContainerRef = useRef();
  const markerRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    mapboxgl.accessToken = mapBoxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [96.14402811407751, 16.88561065060059],
      zoom: 14,
    });

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    findSosAlert();
  }, []);

  const findSosAlert = async () => {
    await ApiHandler({
      url: Api.sos,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        addMarker(response, [response?.longitude, response?.latitude]);
      })
      .catch(() => {});
  };

  const PopupContent = ({ data }) => (
    <Row style={{ padding: 10 }}>
      <Col span={24} style={{ fontWeight: 'bold' }}>
        <Typography.Text>Name: </Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Text> {data?.driverName ?? '-'}</Typography.Text>
      </Col>
      <Col span={24} style={{ fontWeight: 'bold' }}>
        <Typography.Text>Driver No: </Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Text> {data?.driverNo ?? '-'}</Typography.Text>
      </Col>
      <Col span={24} style={{ fontWeight: 'bold' }}>
        <Typography.Text>Vehicle Number: </Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Text>{data?.licenseNumber ?? '-'}</Typography.Text>
      </Col>
    </Row>
  );

  const addMarker = (data, coordinate) => {
    if (markerRef.current) {
      markerRef.current.remove();
    }
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(/images/marker/sos_marker.png)`;
    el.style.width = '50px';
    el.style.height = '50px';
    el.style.backgroundSize = '100%';

    const popupNode = document.createElement('div');
    const root = ReactDOM.createRoot(popupNode);
    root.render(<PopupContent data={data} />);

    const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupNode);
    markerRef.current = new mapboxgl.Marker({ element: el, draggable: false })
      .setLngLat(coordinate)
      .setPopup(popup)
      .addTo(mapRef.current);
    popup.addTo(mapRef.current);
    mapRef.current.flyTo({ center: coordinate, essential: true, zoom: 14 });
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Link to={CustomPath.sos}>
            <Button type="primary" style={{ marginBottom: 20 }}>
              Back
            </Button>
          </Link>
        </Col>
        <Col span={24} style={{ width: '100%' }}>
          <div ref={mapContainerRef} style={{ width: '100%', height: '75vh', borderRadius: 5 }}></div>
        </Col>
      </Row>
    </>
  );
};

export default SosMap;
