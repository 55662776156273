import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row, Skeleton, Space, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVSelect,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import Theme from '../../component/theme';
import { SvgZoneFilled } from '../../component/custom-svg-icon';
import CustomPath from '../../route/custom-path';
import { Link } from 'react-router-dom';
import { NumberOnlyValidator } from '../../core/validator';

function ZonePage() {
  const pageSize = Constant.pageSize;
  const type = 'ZONE';

  const updateFormRef = useRef();

  const [page, setPage] = useState(1);
  const [totalPagination, setPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [updateDataTemp, setUpdateDataTemp] = useState(null);

  const [openCreateFormDrawer, setOpenCreateFormDrawer] = useState(false);
  const [openUpdateFormDrawer, setOpenUpdateFormDrawer] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    fetchData();
    fetchRegion();
  }, []);

  const fetchData = async () => {
    setDataFetching(true);
    await ApiHandler({
      url: Api.zone_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.FORM_DATA,
    })
      .then((response) => {
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  const fetchRegion = async () => {
    await ApiHandler({
      url: Api.regions_all,
      method: HTTP_METHOD.GET,
    })
      .then((response) => {
        const temp = response?.map((region) => {
          return {
            label: region.regionName,
            value: region.id,
            data: region,
          };
        });
        setRegions(temp);
      })
      .catch(() => {});
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Zone Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Base Fare',
      key: 'baseFare',
      dataIndex: 'baseFare',
    },
    {
      title: 'Region',
      key: 'regionName',
      render: (zone) => <>{zone?.region?.regionName}</>,
    },
    {
      title: 'Setup Zone',
      key: 'regionName',
      width: 60,
      render: (zone) => (
        <Link to={`${CustomPath.zone_set_up}/${zone.id}`}>
          <Button size={'middle'} type="primary" shape="round">
            <SvgZoneFilled width={20} height={20} color={'white'} />
          </Button>
        </Link>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (zone) => (
        <Space>
          <NJVEditButton
            handleClick={() => {
              setUpdateDataTemp(zone);
              console.log(zone);
              setOpenUpdateFormDrawer(true);
            }}
            type={type}
          />
        </Space>
      ),
    },
  ];

  const handlePaginationChange = () => {};

  const closeCreateFormDrawer = () => {
    setOpenCreateFormDrawer(false);
  };
  const closeUpdateFormDrawer = () => {
    setOpenUpdateFormDrawer(false);
  };

  const createZone = async (formData) => {
    console.log(formData);
    setCreateLoading(true);
    const requestData = {
      name: formData.name,
      baseFare: formData.baseFare,
      region: {
        id: formData.region,
      },
    };
    await ApiHandler({
      url: Api.zone,
      method: HTTP_METHOD.POST,
      mediaType: MEDIA_TYPE.JSON,
      requestData,
    })
      .then(() => {
        setCreateLoading(false);
        closeCreateFormDrawer();
        fetchData();
      })
      .catch(() => {
        setCreateLoading(false);
      });
  };
  const updateZone = async (formData) => {
    const requestData = {
      id: updateDataTemp.id,
      name: formData.name,
      baseFare: formData.baseFare,
      region: {
        id: formData.region,
      },
    };
    await ApiHandler({
      url: Api.zone,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.JSON,
      requestData,
    })
      .then(() => {
        setUpdateLoading(false);
        closeUpdateFormDrawer();
        setUpdateDataTemp(null);
        fetchData();
      })
      .catch(() => {
        setUpdateLoading(false);
      });
  };

  const CreateFormDrawer = () => (
    <Drawer
      title="Create a new zone"
      width={500}
      onClose={closeCreateFormDrawer}
      open={openCreateFormDrawer}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form layout="vertical" onFinish={createZone}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="name" label={'Zone Name'} rules={[{ required: true, message: '*(requried)' }]}>
              <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter zone name'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="baseFare"
              label={'Base Fare'}
              rules={[{ required: true, message: '*(requried)' }, { validator: NumberOnlyValidator }]}
            >
              <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter base fare'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="region" label={'Region'} rules={[{ required: true, message: '*(requried)' }]}>
              <NJVSelect
                bgcolor={Theme.colors.light_gray}
                style={{ width: '100%' }}
                options={regions}
                placeholder="Select region"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <NJVButton type="primary" htmlType="submit" loading={createLoading}>
                Create
              </NJVButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );

  const UpdateFormDrawer = () => (
    <Drawer
      title="Update zone"
      width={500}
      onClose={closeUpdateFormDrawer}
      open={openUpdateFormDrawer}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form
        layout="vertical"
        onFinish={updateZone}
        initialValues={{ name: updateDataTemp?.name, baseFare: updateDataTemp?.baseFare }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="name" label={'Zone Name'} rules={[{ required: true, message: '*(requried)' }]}>
              <NJVInput value={updateDataTemp?.name} bgcolor={'#f1f1f1'} placeholder={'Enter zone name'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="baseFare"
              label={'Base Fare'}
              rules={[{ required: true, message: '*(requried)' }, { validator: NumberOnlyValidator }]}
            >
              <NJVInput value={updateDataTemp?.baseFare} bgcolor={'#f1f1f1'} placeholder={'Enter base fare'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="region" label={'Region'} rules={[{ required: true, message: '*(requried)' }]}>
              <NJVSelect
                defaultValue={updateDataTemp?.region?.id}
                bgcolor={Theme.colors.light_gray}
                style={{ width: '100%' }}
                options={regions}
                placeholder="Select region"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <NJVButton type="primary" htmlType="submit" loading={updateLoading}>
                Update
              </NJVButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );

  return (
    <>
      <CreateFormDrawer />
      <UpdateFormDrawer />
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Zone'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NJVAddButton type={type} handleClick={() => setOpenCreateFormDrawer(true)} />
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default ZonePage;
