import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { PhoneNumberValidator } from '../../core/validator';
import {
  AutoComplete,
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tag,
  Timeline,
  Typography,
} from 'antd';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import { mapBoxAccessToken } from '../../core/constant';
import { useDebounce, useEffectOnce, useGeolocation } from 'react-use';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';
import { AwsPlaceDetail, AwsPlaceFinder, AwsPositionFinder, AwsRoute } from '../../awsservice/aws-network-service';
import './trip-assignment.css';
import { EyeFilled, SearchOutlined, UserOutlined } from '@ant-design/icons';
import Title from 'antd/es/skeleton/Title';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SvgMarkerFilled } from '../../component/custom-svg-icon';
import { cardStyle, DetailDescription, labelStyle } from './trip-history-detail';
import CustomPath from '../../route/custom-path';
import { Link, useNavigate } from 'react-router-dom';
dayjs.extend(customParseFormat);
const disableDateForAssign = (current) => !current || !current.isSame(dayjs(), 'day');
const disableDateForSchedule = (current) => !current || current < dayjs().startOf('day');

const disabledDateTimeForAssign = (selectedDate) => {
  if (!selectedDate) return {};
  const now = dayjs();
  const nextHour = now.add(30, 'minute');
  const currentHour = now.hour();
  const nextHourTime = nextHour.hour();
  const currentMinute = now.minute();
  const nextMinute = nextHour.minute();

  return {
    disabledHours: () => {
      const hours = Array.from({ length: 24 }, (_, i) => i);
      return hours.filter((hour) => hour !== currentHour && hour !== nextHourTime);
    },
    disabledMinutes: (selectedHour) => {
      if (selectedHour === currentHour) {
        return Array.from({ length: 60 }, (_, i) => i).filter((min) => min < currentMinute);
      }
      if (selectedHour === nextHourTime) {
        return Array.from({ length: 60 }, (_, i) => i).filter((min) => min > nextMinute);
      }
      return Array.from({ length: 60 }, (_, i) => i);
    },
  };
};

const disabledDateTimeForSchedule = (selectedDate) => {
  const currentDateTime = dayjs().add(30, 'minute');
  const isToday = selectedDate && selectedDate.isSame(currentDateTime, 'day');

  if (isToday) {
    const currentHour = currentDateTime.hour();
    const currentMinute = currentDateTime.minute();

    return {
      disabledHours: () => Array.from({ length: currentHour + 1 }, (_, i) => i),
      disabledMinutes: (selectedHour) =>
        selectedHour === currentHour ? Array.from({ length: currentMinute + 1 }, (_, i) => i) : [],
    };
  }
  return {};
};

const TripAssignment = () => {
  const [form] = Form.useForm();
  const isScheduled = Form.useWatch('isScheduled', form);
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const fetchRef = useRef(0);
  const pickupMarkerRef = useRef();
  const dropoffMarkerRef = useRef();
  const placesMarkerRef = useRef([]);
  const navigate = useNavigate();
  // const [markers, setMarkers] = useState([]);
  const geoLocation = useGeolocation();
  const [searchValue, setSearchValue] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  let stompClient = null;
  const [isConfirmRouteLoading, setConfirmRouteLoading] = useState(false);
  const [inputPhoneNumber, setInputPhoneNumber] = useState();
  const [selectedPassenger, setSelectedPassenger] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();
  const [addressSuggestion, setAddressSuggestion] = useState([]);
  const [pickupAddress, setPickupAddress] = useState();
  const [dropoffAddress, setDropoffAddress] = useState();
  const [pickupLngLat, setPickupLngLat] = useState();
  const [dropoffLngLat, setDropoffLngLat] = useState();
  const [distance, setDistance] = useState(0);
  const [distancePickup, setDistancePickup] = useState(0);
  const [waitingDuration, setWaitingDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const [fees, setFees] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddressOpen, setModalAddressOpen] = useState({ open: null, label: null });
  const [carMarkers, setCarMarkers] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [region, setRegion] = useState(2);
  const [options, setOptions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [allDriversForSchedule, setAllDriversForSchedule] = useState([]);
  const [isConfirmedRoute, setIsConfirmedRoute] = useState(false);
  const [driverLngLat, setDriverLngLat] = useState({});

  useDebounce(
    () => {
      if (!searchValue) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      AwsPlaceFinder(searchValue)
        .then((res) => {
          if (fetchId !== fetchRef.current) return;
          setAddressSuggestion(
            res.Results?.map((result) => {
              const Text = result.Text.split(',');
              return {
                ...result,
                Text: `${Text[0]}, ${Text[1]}, ${Text[2]}, ${Text[3]}`,
              };
            }),
          );
        })
        .catch(() => {});
    },
    500,
    [searchValue],
  );

  const onChangePhoneNumber = (e) => {
    setInputPhoneNumber(e.target.value);
  };

  useDebounce(
    () => {
      fetchPassengers(inputPhoneNumber);
    },
    500,
    [inputPhoneNumber],
  );

  const calculateFare = () => {
    ApiHandler({
      url: Api.fare_calculate,
      method: HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: {
        pickupLatLng: pickupLngLat,
        dropOffLatLng: dropoffLngLat,
      },
      disableShowMessage: true,
    }).then((res) => {
      setFees(res.data.total);
    });
  };

  const searchNearPlace = async (lngLat) => {
    const res = await AwsPositionFinder(lngLat);
    const nearestPlace = res.Results.reduce((min, result) => (result.Distance < min.distance ? result : min));
    const Text = nearestPlace.Place.Label.split(',');
    const label = `${Text[0]}, ${Text[1]}, ${Text[2]}, ${Text[3]}`;
    setAddressSuggestion((prev) => [...prev, { PlaceId: nearestPlace.PlaceId, Text: label }]);
    return {
      value: nearestPlace.PlaceId,
      key: nearestPlace.PlaceId,
      label,
    };
  };

  const onSelectPickupAddress = (address) => {
    if (pickupMarkerRef.current) {
      pickupMarkerRef.current.remove();
    }
    setIsConfirmedRoute(false);
    setPickupAddress(address);
    AwsPlaceDetail(address.value)
      .then((placeDetail) => {
        const lngLat = placeDetail.Place.Geometry.Point;
        setPickupLngLat({ lng: lngLat[0], lat: lngLat[1] });
        pickupMarkerRef.current = new mapboxgl.Marker({ draggable: true, color: '#52c41a' })
          .setLngLat(lngLat)
          .addTo(mapRef.current);

        pickupMarkerRef.current.on('dragend', () => {
          const lngLat = pickupMarkerRef.current.getLngLat();
          flyTo([lngLat.lng, lngLat.lat]);
          setPickupLngLat(lngLat);
          searchNearPlace([lngLat.lng, lngLat.lat])
            .then((nearestPlace) => {
              setPickupAddress(nearestPlace);
              pickupMarkerRef.current.getElement().onclick = (e) => e.stopPropagation();

              pickupMarkerRef.current.getElement().addEventListener('click', () => {
                setModalAddressOpen({ open: true, label: nearestPlace?.label });
              });
            })
            .catch(() => {});
        });
        pickupMarkerRef.current.getElement().onclick = (e) => e.stopPropagation();

        pickupMarkerRef.current.getElement().addEventListener('click', () => {
          setModalAddressOpen({ open: true, label: pickupAddress?.label });
        });
        flyTo(lngLat);
      })
      .catch(() => {});
  };

  const onSelectDropoffAddress = (address) => {
    if (dropoffMarkerRef.current) {
      dropoffMarkerRef.current.remove();
    }
    setIsConfirmedRoute(false);
    setDropoffAddress(address);
    AwsPlaceDetail(address.value)
      .then((placeDetail) => {
        const lngLat = placeDetail.Place.Geometry.Point;
        setDropoffLngLat({ lng: lngLat[0], lat: lngLat[1] });
        dropoffMarkerRef.current = new mapboxgl.Marker({ draggable: true, color: '#f5222d' })
          .setLngLat(lngLat)
          .addTo(mapRef.current);
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
        dropoffMarkerRef.current.on('dragend', () => {
          const lngLat = dropoffMarkerRef.current.getLngLat();
          flyTo([lngLat.lng, lngLat.lat]);
          setDropoffLngLat(lngLat);
          searchNearPlace([lngLat.lng, lngLat.lat])
            .then((nearestPlace) => {
              setDropoffAddress(nearestPlace);
              dropoffMarkerRef.current.getElement().onclick = (e) => e.stopPropagation();

              dropoffMarkerRef.current.getElement().addEventListener('click', () => {
                setModalAddressOpen({ open: true, label: nearestPlace?.label });
              });
            })
            .catch(() => {});
        });
        dropoffMarkerRef.current.getElement().onclick = (e) => e.stopPropagation();

        dropoffMarkerRef.current.getElement().addEventListener('click', () => {
          setModalAddressOpen({ open: true, label: dropoffAddress?.label });
        });
        flyTo(lngLat);
      })
      .catch(() => {});
  };

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const timeParts = [];
    if (days > 0) timeParts.push(`${days}d`);
    if (hours > 0) timeParts.push(`${hours}h`);
    if (minutes > 0) timeParts.push(`${minutes}m`);
    if (remainingSeconds > 0 || timeParts.length === 0) timeParts.push(`${remainingSeconds.toFixed(0)}s`);

    return timeParts.join(' ');
  };

  const flyTo = (lngLat) => {
    mapRef.current.flyTo({
      center: lngLat,
      zoom: 18,
      essential: true,
      duration: 1200,
    });
  };

  // const calculatePickUpDistance = (driverData) => {
  //   AwsRoute(driverData, pickupLngLat).then((res) => {
  //     setIsConfirmedRoute(true);
  //     setPickupDistance(res.Summary.Distance);
  //     setDuration(res.Summary.DurationSeconds);
  //   });
  // };

  const confirmRoute = () => {
    if (mapRef.current.getLayer('route-layer')) {
      mapRef.current.removeLayer('route-layer');
    }
    if (mapRef.current.getSource('route')) {
      mapRef.current.removeSource('route');
    }
    setConfirmRouteLoading(true);
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([pickupLngLat.lng, pickupLngLat.lat]);
    bounds.extend([dropoffLngLat.lng, dropoffLngLat.lat]);

    mapRef.current.fitBounds(bounds, {
      padding: { top: 30, bottom: 30, left: 30, right: 30 },
      maxZoom: 15,
    });
    calculateFare();
    AwsRoute(pickupLngLat, dropoffLngLat)
      .then((res) => {
        setIsConfirmedRoute(true);
        setDistance(res.Summary.Distance);
        setDuration(res.Summary.DurationSeconds);
        const routeCoordinates = res.Legs[0].Geometry.LineString;
        mapRef.current.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: routeCoordinates,
            },
          },
        });
        mapRef.current.addLayer({
          id: 'route-layer',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': 'rgb(33, 150, 243)',
            'line-width': 4,
          },
        });
      })
      .catch(() => {})
      .finally(() => setConfirmRouteLoading(false));
  };

  const connectSocket = () => {
    try {
      if (!stompClient) {
        const Sock = new SockJS(Api.driver_location_host + '/ws');
        stompClient = over(Sock);
      }

      stompClient.connect(
        {},
        () => {
          stompClient.subscribe(
            `/driver/${region}/locations`,
            (payload) => {
              onReceiveDriverLocation(payload);
            },
            (error) => {
              console.error('Subscription error:', error);
            },
          );
        },
        (error) => {
          console.error('WebSocket connection error:', error);
        },
      );
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  const MarkerComponent = (marker) => {
    const el = document.createElement('div');
    el.style.width = '30px';
    el.style.height = '58px';
    el.style.backgroundSize = 'contain';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.transformOrigin = 'center';

    if (marker.tripStatus === 'Active') {
      el.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
    } else if (marker.driverStatus === 'Inactive' || marker.driverStatus === 'Offline') {
      el.style.backgroundImage = 'url(/images/marker/gray-car.png)';
    } else if (marker.driverStatus === 'Idle') {
      el.style.backgroundImage = 'url(/images/marker/purple-car.png)';
    } else if (marker.driverStatus === 'Available') {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    } else if (marker.driverStatus === 'Busy') {
      el.style.backgroundImage = 'url(/images/marker/purple-car.png)';
    } else {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    }

    const popupNode = document.createElement('div');
    popupNode.innerHTML = `
      <div style="display: flex; align-items: center; height: 17px">
        <img src="${marker.driverPhotoUrl}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
        <div style="display: flex; flex-direction: column;">
          <span style="font-weight: bold;">${marker.driverName}</span>
          <span style="font-size: small;">${marker.vehiclePlateNumber}</span>
        </div>
      </div>`;

    const initialPopup = new mapboxgl.Popup({
      offset: 25,
      closeOnClick: false,
      closeButton: false,
      openOnClick: false,
    }).setDOMContent(popupNode);

    const newMarker = new mapboxgl.Marker({ element: el })
      .setLngLat([marker.lng, marker.lat])
      .addTo(mapRef.current)
      .setPopup(initialPopup);

    newMarker.setRotation(marker.direction);
    initialPopup.addTo(mapRef.current);

    newMarker.getElement().onclick = (e) => e.stopPropagation();

    newMarker.getElement().addEventListener('click', () => {
      setIsModalOpen(true);
      setDriverData(marker);
    });

    return newMarker;
  };

  const handleSearch = (value) => {
    const filteredOptions = carMarkers
      ?.filter((driver) => driver.driverName && driver.driverName.toLowerCase().includes(value.toLowerCase()))
      .map((driver) => ({ value: driver.driverName, label: driver.driverName }));

    setOptions(filteredOptions);
  };

  const getAllDrivers = () => {
    const requestParams = {
      regionId: region,
    };
    ApiHandler({
      url: Api.driver_location,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    }).then((response) => {
      setCarMarkers((prevMarkers) => {
        const newMarkers = [];

        response
          .filter((driver) => driver.driverStatus === 'Available')
          .forEach((markerData) => {
            const existingMarker = prevMarkers.find((m) => m.driverId === markerData.driverId);

            if (!existingMarker) {
              const newMarker = MarkerComponent(markerData);
              newMarkers.push({
                driverId: markerData.driverId,
                lat: markerData.lat,
                lng: markerData.lng,
                markerInstance: newMarker,
                direction: markerData.direction,
                driverName: markerData.driverName,
                vehiclePlateNumber: markerData.vehiclePlateNumber,
              });
            }
          });

        return [...prevMarkers, ...newMarkers];
      });
    });
  };

  const onReceiveDriverLocation = useCallback(
    (payload) => {
      if (!payload) return;

      try {
        const markerData = JSON.parse(payload.body);
        if (!mapRef.current || markerData.driverStatus !== 'Available') return;

        setCarMarkers((prevMarkers) => {
          const existingMarkerIndex = prevMarkers.findIndex((marker) => marker.driverId === markerData.driverId);

          if (existingMarkerIndex !== -1) {
            const existingMarker = prevMarkers[existingMarkerIndex];
            if (existingMarker.lat !== markerData.lat || existingMarker.lng !== markerData.lng) {
              const startCoordinates = [existingMarker.lng, existingMarker.lat];
              const endCoordinates = [markerData.lng, markerData.lat];

              animateMarker(existingMarker.markerInstance, startCoordinates, endCoordinates);
            }

            existingMarker.markerInstance.setRotation(existingMarker.direction);

            const popupContent = `<div style="display: flex; align-items: center; height: 17px">
            <img src="${markerData.driverPhotoUrl}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
            <div style="display: flex; flex-direction: column;">
              <span style="font-weight: bold;">${markerData.driverName}</span>
              <span style="font-size: small;">${markerData.vehiclePlateNumber || '-'}</span>
            </div>
          </div>`;
            existingMarker.markerInstance.getPopup().setHTML(popupContent);

            const markerElement = existingMarker.markerInstance.getElement();
            if (markerData.tripStatus === 'Active') {
              markerElement.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
            } else if (markerData.driverStatus === 'Inactive') {
              markerElement.style.backgroundImage = 'url(/images/marker/gray-car.png)';
            } else if (markerData.driverStatus === 'Idle') {
              markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
            } else if (markerData.driverStatus === 'Available') {
              markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
            } else if (markerData.driverStatus === 'Busy') {
              markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
            } else {
              markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
            }

            existingMarker.markerInstance.getElement().addEventListener('click', () => {
              setIsModalOpen(true);
              setDriverData(markerData);
            });

            existingMarker.markerInstance.setRotation(existingMarker.direction);

            return prevMarkers.map((marker, index) =>
              index === existingMarkerIndex
                ? {
                    ...marker,
                    lat: markerData.lat,
                    lng: markerData.lng,
                    direction: markerData.direction,
                    driverName: markerData.driverName,
                    driverStatus: markerData.driverStatus,
                    vehiclePlateNumber: markerData.vehiclePlateNumber || '-',
                  }
                : marker,
            );
          } else {
            const newMarker = MarkerComponent(markerData);

            return [
              ...prevMarkers,
              {
                driverId: markerData.driverId,
                lat: markerData.lat,
                lng: markerData.lng,
                markerInstance: newMarker,
                direction: markerData.direction,
                driverName: markerData.driverName,
                vehiclePlateNumber: markerData.vehiclePlateNumber || '-',
              },
            ];
          }
        });
      } catch (error) {
        console.error('Error parsing WebSocket data:', error);
      }
    },
    [mapRef, setCarMarkers],
  );

  const animateMarker = (markerInstance, startCoordinates, endCoordinates) => {
    const startTime = performance.now();
    const duration = 1000;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const newLng = startCoordinates[0] + (endCoordinates[0] - startCoordinates[0]) * progress;
      const newLat = startCoordinates[1] + (endCoordinates[1] - startCoordinates[1]) * progress;

      if (!isNaN(newLng) && !isNaN(newLat)) {
        markerInstance.setLngLat([newLng, newLat]);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const handleSelect = (driverName) => {
    const requestParams = {
      driverName: driverName.trim(),
    };

    const matchMarker = carMarkers.find((marker) => marker.driverName === requestParams.driverName);

    if (matchMarker && mapRef.current) {
      mapRef.current.flyTo({
        center: [matchMarker.lng, matchMarker.lat],
        zoom: 14,
        essential: true,
        duration: 1200,
      });
    }
  };

  const findDriverById = (driver) => {
    AwsRoute(driver, pickupLngLat)
      .then((res) => {
        setDistancePickup(res.Summary.Distance);
        setWaitingDuration(res.Summary.DurationSeconds);
      })
      .catch(() => {});
    ApiHandler({
      url: Api.driver,
      method: HTTP_METHOD.GET,
      specificId: driver.driverId,
    })
      .then((driver) => {
        setSelectedDriver(driver);
      })
      .catch(() => {});
  };

  // const onRegionChange = (value) => {
  // mapRef.current.flyTo({
  //   center: value === '2' ? [96.1588, 16.7945] : value === '1' ? [96.0891, 21.9588] : '',
  //   zoom: 12,
  //   essential: true,
  //   duration: 1500,
  // });
  // setRegion(value);
  // };

  const fetchRegions = () => {
    ApiHandler({
      url: Api.region_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    }).then((res) => {
      setRegions(res?.content);
      res?.content && setRegion(res?.content[0]?.id);
      connectSocket();
    });
  };

  const fetchAllDriversForSchedule = (date) => {
    ApiHandler({
      url: Api.driver_get_assigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    }).then((res) => {
      setAllDriversForSchedule(res);
    });
  };

  useEffectOnce(() => {
    fetchRegions();
    getAllDrivers();
  });

  const fetchPaymentMethods = () => {
    ApiHandler({
      url: Api.payment_methods,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((res) => {
        setPaymentMethods(res);
      })
      .catch(() => {});
  };

  const createTripAssign = (formData) => {
    if (!formData.isScheduled && !selectedDriver) {
      message.error('Please select driver for trip assign');
      return;
    }
    setLoading(true);
    const requestData = {
      ...formData,
      passengerId: selectedPassenger ? selectedPassenger.id : undefined,
      driverId: selectedDriver?.id,
      tripPickUpLocation: { latitude: pickupLngLat.lat, longitude: pickupLngLat.lng, address: pickupAddress.label },
      tripDropOffLocation: { latitude: dropoffLngLat.lat, longitude: dropoffLngLat.lng, address: dropoffAddress.label },
      estimatedDistance: distance,
      estimatedTime: duration,
      // waitingDuration,
      distancePickup,
      baseFees: fees,
      passengerDTO: {
        gender: formData.gender,
        dateOfBirth: formData.dateOfBirth,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
      },
      scheduled: formData.isScheduled,
    };
    ApiHandler({
      url: Api.trip_create_assign,
      method: HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData,
    })
      .then((res) => {
        navigate(CustomPath.trip_dispatcher);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchPassengers = (phoneNumber) => {
    PhoneNumberValidator(null, phoneNumber)
      .then(() => {
        const requestParams = {
          size: 20,
          page: 0,
          phoneNumber,
        };
        ApiHandler({
          url: Api.passenger_filter,
          method: HTTP_METHOD.GET,
          MediaType: MEDIA_TYPE.JSON,
          requestParams,
        })
          .then((response) => {
            setSelectedPassenger(response?.content[0]);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const convertToEstimatedLabel = (distance) => {
    return `${(Math.round(distance * 100) / 100).toFixed(2)} Kilometers`;
  };

  useEffect(() => {
    form.setFieldValue('scheduleTime', dayjs());
    fetchPaymentMethods();
    mapboxgl.accessToken = mapBoxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [96.14402811407751, 16.88561065060059],
      zoom: 15,
    });
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  const driverLabel = [
    {
      key: 1,
      label: 'Driver Name: ',
      children: selectedDriver?.fullName ?? '-',
    },
    {
      key: 2,
      label: 'Phone Number: ',
      children: selectedDriver?.phoneNumber ?? '-',
    },
    {
      key: 3,
      label: 'Vehicle Plate Number: ',
      children: selectedDriver?.vehiclePlateNumber ?? '-',
    },
  ];

  const tripRouteLabel = [
    {
      key: 1,
      label: 'Estimated Distance: ',
      children: convertToEstimatedLabel(distance),
    },
    {
      key: 2,
      label: 'Estimated Time: ',
      children: formatTime(duration),
    },
    {
      key: 3,
      label: 'Fees: ',
      children: `${`${fees}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Kyat`,
    },
    {
      key: 4,
      label: 'Distance Pick-Up',
      children: convertToEstimatedLabel(distancePickup),
    },
    {
      key: 5,
      label: 'Duration Pick-Up',
      children: formatTime(waitingDuration),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Breadcrumb
          items={[
            {
              title: 'Home',
            },
            {
              title: <Link to={CustomPath.trip_dispatcher}>Dispatcher</Link>,
            },
            {
              title: 'Trip Assign',
            },
          ]}
        />
      </Col>
      <Col span={14} style={{ textAlign: 'end' }}>
        <Link to={CustomPath.trip_dispatcher}>
          <Button type="primary">Back</Button>
        </Link>
      </Col>
      <Col span={24}>
        <Card
          style={{
            backgroundColor: Theme.colors.card_bg_color,
            boxShadow: 'none',
          }}
          bordered={false}
          title={'Trip Assignment'}
        >
          <Form layout="vertical" form={form} onFinish={createTripAssign}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label={'Passenger Phone Number'}
                  rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]} //
                >
                  <NJVInput placeholder={'0979xxxxxxxx'} onChange={onChangePhoneNumber} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="paymentMethod"
                  label={'Payment Method'}
                  rules={[{ required: true, message: '*(requried)' }]} //
                >
                  <NJVSelect placeholder="Select Payment Method">
                    {paymentMethods.map((paymentMethod) => (
                      <Select.Option key={paymentMethod.key} value={paymentMethod.key}>
                        {paymentMethod.value}
                      </Select.Option>
                    ))}
                  </NJVSelect>
                </Form.Item>
              </Col>
              {!selectedPassenger && (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="firstName"
                      label={'First Name'}
                      rules={[{ required: true, message: '*(requried)' }]}
                    >
                      <NJVInput placeholder={'Enter First Name'} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="lastName" label={'Last Name'}>
                      <NJVInput placeholder={'Enter Last Name'} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="gender" label={'Gender'}>
                      <Radio.Group>
                        <Radio value={'Male'}>Male</Radio>
                        <Radio value={'Female'}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="dateOfBirth" label={'Date Of Birth'}>
                      <NJVDatePicker placeholder={'Select Date Of Birth'} style={{ width: '100%' }}></NJVDatePicker>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={8}>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => {
                    return prevValues.isScheduled !== currentValues.isScheduled;
                  }}
                >
                  {({ getFieldValue }) => (
                    <Form.Item
                      label="Pick-Up Date Time"
                      name="scheduleTime"
                      rules={[{ required: true, message: '*(requried)' }]}
                    >
                      <DatePicker
                        disabledDate={getFieldValue('isScheduled') ? disableDateForSchedule : disableDateForAssign}
                        disabledTime={
                          getFieldValue('isScheduled') ? disabledDateTimeForSchedule : disabledDateTimeForAssign
                        }
                        size="large"
                        showTime
                        onChange={(date) => {
                          fetchAllDriversForSchedule(date.format('YYYY-MM-DD'));
                        }}
                        format="YYYY-MM-DD HH:mm"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="isScheduled" valuePropName="checked" label="Schedule For Later?">
                  <Checkbox
                    onChange={(e) => {
                      form.setFieldValue('pickupDateTime', e.target.checked ? '' : dayjs());
                    }}
                  >
                    Is Schedule
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {selectedPassenger && (
              <div style={{ ...cardStyle, marginBottom: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label={'Passenger Name'}>
                      <Typography.Text>{`${selectedPassenger?.firstName} ${selectedPassenger?.lastName}`}</Typography.Text>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'Gender'}>
                      <Typography.Text>{selectedPassenger?.gender ?? '-'}</Typography.Text>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'Date Of Birth'}>
                      <Typography.Text>{selectedPassenger?.dateOfBirth ?? '-'}</Typography.Text>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
            <div style={{ ...cardStyle, marginBottom: 20 }}>
              <div style={cardStyle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SvgMarkerFilled width={25} height={25} color={'#52c41a'} />
                  <div style={{ marginLeft: 15, width: '100%' }}>
                    <div style={{ ...labelStyle }}>Pick-Up Address</div>
                    <div style={{}}>{pickupAddress?.label ?? 'Please select a pick-up address.'}</div>
                  </div>
                </div>
              </div>
              <div style={{ height: 30, borderLeft: '4px dotted gray', marginLeft: 50 }} />
              <div style={cardStyle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SvgMarkerFilled width={25} height={25} color={'#f5222d'} />
                  <div style={{ marginLeft: 15, width: '100%' }}>
                    <div style={{ ...labelStyle }}>Drop-Off Address</div>
                    <div style={{}}>{dropoffAddress?.label ?? 'Please select a drop-off address.'}</div>
                  </div>
                </div>
              </div>
              {(!pickupAddress || !dropoffAddress) && (
                <Button
                  icon={<EyeFilled />}
                  type="primary"
                  onClick={() => {
                    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
                  }}
                  style={{ marginTop: 20 }}
                >
                  Select On Map
                </Button>
              )}
            </div>
            <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
              <Col span={12}>
                <DetailDescription
                  label={isScheduled ? tripRouteLabel.filter(({ key }) => key !== 4 && key !== 5) : tripRouteLabel}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => {
                    return prevValues.isScheduled !== currentValues.isScheduled;
                  }}
                >
                  {({ getFieldValue }) => {
                    return !getFieldValue('isScheduled') ? <DetailDescription label={driverLabel} /> : null;
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <NJVButton type="primary" htmlType="submit" loading={loading} disabled={!isConfirmedRoute}>
                Assign Trip
              </NJVButton>
            </Form.Item>
            <Row>
              <Col span={24} ref={scrollRef}>
                <Form.Item
                  style={{
                    height: 700,
                  }}
                >
                  <Card
                    ref={mapContainerRef}
                    style={{
                      height: 700,
                    }}
                  />

                  <Form.Item
                    style={{ width: '50%' }}
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues.isScheduled !== currentValues.isScheduled;
                    }}
                  >
                    {({ getFieldValue }) => (
                      <Row gutter={[20, 20]} style={{ marginTop: 10, marginLeft: 10 }}>
                        <Col span={12} style={{ height: 10 }}>
                          <Form layout="horizontal">
                            <Form.Item name="driverName">
                              <AutoComplete
                                disabled={getFieldValue('isScheduled')}
                                options={options}
                                onSearch={handleSearch}
                                onSelect={handleSelect}
                                style={{ width: '100%' }}
                              >
                                <Input
                                  style={{
                                    height: 40,
                                    boxShadow: 'none',
                                    borderColor: 'white',
                                  }}
                                  suffix={<SearchOutlined style={{ color: '#656566' }} />}
                                  placeholder="Search by DriverName or VehicleNumber"
                                />
                              </AutoComplete>
                            </Form.Item>
                          </Form>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="region">
                            <Select
                              disabled={getFieldValue('isScheduled')}
                              size="large"
                              style={{ width: '100%' }}
                              placeholder="Select Region"
                              onChange={(v) => {
                                setRegion(v);
                                connectSocket();
                              }}
                              showSearch
                            >
                              {regions.map((reg) => (
                                <Select.Option value={reg.id} key={reg.id}>
                                  {reg.regionName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                  <Card style={{ width: '25%', marginLeft: 15, marginTop: 10 }}>
                    <Timeline
                      className="custom-timeline"
                      items={[
                        {
                          color: '#52c41a',
                          dot: <SvgMarkerFilled width={25} height={25} color={'#52c41a'} />,
                          children: (
                            <>
                              <div style={{ ...labelStyle, width: '100%' }}>Pick-Up Location</div>
                              <Select
                                labelInValue
                                filterOption={false}
                                showSearch
                                value={pickupAddress}
                                style={{ width: '100%' }}
                                onSearch={setSearchValue}
                                size="large"
                                onSelect={onSelectPickupAddress}
                                notFoundContent={<Spin size="small" />}
                                placeholder="Search pick-up address"
                              >
                                {addressSuggestion.map((address) => (
                                  <Select.Option key={address.PlaceId}>{address.Text}</Select.Option>
                                ))}
                              </Select>
                            </>
                          ),
                        },
                        {
                          color: '#f5222d',
                          dot: <SvgMarkerFilled width={25} height={25} color={'#f5222d'} />,
                          children: (
                            <>
                              <div style={{ ...labelStyle, width: '100%' }}>Drop-Off Location</div>
                              <Select
                                labelInValue
                                value={dropoffAddress}
                                filterOption={false}
                                showSearch
                                style={{ width: '100%' }}
                                onSearch={setSearchValue}
                                size="large"
                                onSelect={onSelectDropoffAddress}
                                notFoundContent={<Spin size="small" />}
                                placeholder="Search drop-off address"
                              >
                                {addressSuggestion.map((address) => (
                                  <Select.Option key={address.PlaceId}>{address.Text}</Select.Option>
                                ))}
                              </Select>
                            </>
                          ),
                        },
                      ]}
                    />
                    {pickupAddress && dropoffAddress && (
                      <>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                          <Button
                            icon={<EyeFilled />}
                            type="primary"
                            loading={isConfirmRouteLoading}
                            onClick={() => {
                              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
                              confirmRoute();
                            }}
                          >
                            Confirm Route
                          </Button>
                        </div>
                      </>
                    )}
                  </Card>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Modal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} closable={true} width={300}>
        <div style={{ textAlign: 'center' }}>
          <Avatar
            src={driverData.driverPhotoUrl}
            size={80}
            icon={!driverData.driverPhotoUrl ? <UserOutlined /> : undefined}
          />

          <Title level={4}>{driverData.driverName}</Title>
          <Typography.Text strong>License Plate Number: {driverData.vehiclePlateNumber}</Typography.Text>
          <div style={{ marginTop: '8px' }}>
            <Typography.Text strong>
              Driver Status:{' '}
              {driverData?.driverStatus === 'Available' ? (
                <Tag color="green">{driverData.driverStatus}</Tag>
              ) : (
                driverData?.driverStatus
              )}
            </Typography.Text>
          </div>
          {!form.getFieldValue('isScheduled') && (
            <NJVButton
              type="primary"
              style={{ width: '50%', marginTop: 10 }}
              onClick={() => {
                findDriverById(driverData);
                setIsModalOpen(false);
              }}
            >
              Assign
            </NJVButton>
          )}
        </div>
      </Modal>
      <Modal
        open={isModalAddressOpen.open}
        footer={false}
        onCancel={() => setModalAddressOpen({ open: false, label: null })}
        closable={true}
        width={400}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={5}>
            {' '}
            <SvgMarkerFilled width={15} height={15} color={'rgb(100, 181, 246)'} /> Address
          </Typography.Title>
          <Typography.Text strong> {isModalAddressOpen?.label}</Typography.Text>
        </div>
      </Modal>
    </Row>
  );
};

export default TripAssignment;
