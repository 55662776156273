import { Button, Col, InputNumber, message, Row, Select, Skeleton, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { NJVAddButton, NJVCard, NJVTable, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';

const incentiveOptions = [
  {
    key: 1,
    label: '1%',
    value: 1,
  },
  {
    key: 2,
    label: '2%',
    value: 2,
  },
  {
    key: 3,
    label: '3%',
    value: 3,
  },
  {
    key: 4,
    label: '4%',
    value: 4,
  },
  {
    key: 5,
    label: '5%',
    value: 5,
  },
];

const IncentiveDefination = () => {
  const type = 'INCENTIVE_RULE';
  const [page, setPage] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [isCreateNew, toggleCreateNew] = useToggle(false);
  const FARE_TYPE = 'INCENTIVE';

  const [list, setList] = useState([]);

  const fetchData = useCallback((pageNumber) => {
    const requestParams = {
      fareType: FARE_TYPE,
    };
    ApiHandler({
      url: Api.fare_rule_by_fare_type,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setData(response?.map((c) => ({ ...c, key: c.id })));
      })
      .catch(() => message.error('Failed to load data'))
      .finally(() => setDataFetching(false));
  }, []);

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, [fetchData]);

  const handleCreate = () => {
    toggleCreateNew(true);
    setList([
      {
        index: 1,
        fromRange: null,
        toRange: null,
        farePrice: 1,
        isLast: false,
        fareType: FARE_TYPE,
      },
    ]);
  };
  const cancelCreate = () => {
    toggleCreateNew(false);
  };

  const save = async () => {
    toggleSubmitting();
    ApiHandler({
      url: Api.fare_rule_create_all,
      method: HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: list,
    })
      .then(() => {
        setList([]);
        toggleCreateNew(false);
        fetchData();
      })
      .catch(() => {})
      .finally(() => {
        toggleSubmitting();
      });
  };

  const defaultColumns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'From Income Amount (MMK)',
      dataIndex: 'fromRange',
      key: 'fromRange',
    },
    {
      title: 'To Income Amount (MMK)',
      dataIndex: 'toRange',
      key: 'toRange',
    },
    {
      title: 'Incentive (%)',
      dataIndex: 'farePrice',
      key: 'farePrice',
      editable: true,
      render: (charge) => (
        <>
          {charge}
          <span style={{ marginLeft: '4px' }}>%</span>
        </>
      ),
    },
  ];
  const onChangeInputValue = (index, value, key) => {
    const temp = list.map((record) => {
      if (record.index === index) {
        record[key] = value;
      }
      return record;
    });
    setList(temp);
  };

  const addNewItemToList = () => {
    const obj = list[list.length - 1];
    if (!obj?.fromRange || obj?.fromRange === 0) {
      message.error('Please enter from range');
      return;
    }
    if (!obj?.toRange || obj?.toRange === 0) {
      message.error('Please enter to range');
      return;
    }
    if (obj?.fromRange > obj.toRange) {
      message.error('From range should be less than To range.');
      return;
    }
    if (!obj?.farePrice || obj?.farePrice === 0) {
      message.error('Please define incentive percentage');
      return;
    }
    if (obj?.fromRange === obj.toRange) {
      setList((prevList) => {
        const updatedList = [...prevList];
        if (updatedList.length > 0) {
          obj.isLast = true;
          updatedList[updatedList.length - 1] = obj;
        }
        return updatedList;
      });
    } else {
      const newItem = {
        index: list.length + 1,
        fromRange: list[list.length - 1]?.toRange + 1,
        toRange: null,
        farePrice: 1,
        isLast: false,
        fareType: FARE_TYPE,
      };
      setList([...list, newItem]);
    }
  };

  const removeItemFromList = () => {
    setList(list.slice(0, -1));
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Incentive Rule'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {isCreateNew ? (
            <Space>
              <Button type="primary" style={{ backgroundColor: Theme.colors.red }} onClick={cancelCreate}>
                Cancel
              </Button>
            </Space>
          ) : (
            <NJVAddButton type={type} label="Create New Rule" handleClick={handleCreate} />
          )}
        </Col>
        <Col span={24}>
          {isCreateNew && (
            <NJVCard>
              <Row gutter={[16, 16]}>
                <Col span={6}>From Income Amount (MMK)</Col>
                <Col span={6}>To Income Amount (MMK)</Col>
                <Col span={6}>Incentive(%)</Col>
                <Col span={6}>Action</Col>
                {list?.map((item) => (
                  <>
                    <Col span={6}>
                      <InputNumber
                        size="large"
                        style={{ width: '100%' }}
                        value={item.fromRange}
                        onChange={(event) => onChangeInputValue(item.index, event, 'fromRange')}
                        disabled={list.length === 1 ? false : true}
                      />
                    </Col>
                    <Col span={6}>
                      <InputNumber
                        size="large"
                        style={{ width: '100%' }}
                        onChange={(event) => onChangeInputValue(item.index, event, 'toRange')}
                        value={item.toRange}
                        disabled={item.index === list.length ? false : true}
                      />
                    </Col>
                    <Col span={6}>
                      <Select
                        size="large"
                        style={{ width: '100%' }}
                        options={incentiveOptions}
                        value={item.farePrice}
                        disabled={item.index === list.length ? false : true}
                        onChange={(event) => onChangeInputValue(item.index, event, 'farePrice')}
                      />
                    </Col>
                    <Col span={6} style={{ width: '100%' }}>
                      {item.index === list.length && (
                        <>
                          {item.fromRange && item.toRange && item.fromRange === item.toRange ? (
                            <>
                              <Button type="primary" onClick={() => save()} loading={submitting}>
                                Save
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button type="primary" style={{ marginRight: 5 }} onClick={() => addNewItemToList()}>
                                Add
                              </Button>
                              <Space />
                              {list.length !== 1 && (
                                <Button type="primary" onClick={() => removeItemFromList()}>
                                  Remove
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </>
                ))}
              </Row>
            </NJVCard>
          )}
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              bordered
              dataSource={data}
              columns={defaultColumns}
              rowClassName="editable-row"
              pagination={false}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
export default IncentiveDefination;
