import dayjs from 'dayjs';

export const PhoneNumberValidator = (_, value) => {
  const numberPattern = /^09[0-9]\d{6,10}$/;
  if (!value || value.length < 6 || value.length > 12 || !numberPattern.test(value)) {
    return Promise.reject('Phone number must start with 09 and be 10 digits long');
  }
  return Promise.resolve();
};

export const NumberOnlyValidator = (_, value) => {
  const numberPattern = /^[0-9]+$/;
  if (!value || !numberPattern.test(value)) {
    return Promise.reject('Please enter number only');
  }
  return Promise.resolve();
};

export const NumberOnlyForOptionalValidator = (_, value) => {
  const numberPattern = /^[0-9]+$/;
  let isValueExist = false;
  // if (value && (value.length < 6 || value.length > 16)) {
  //   return Promise.reject('Invalid input');
  // }
  if (value && value.length != 0) {
    isValueExist = true;
  }
  if (isValueExist && !numberPattern.test(value)) {
    return Promise.reject('Invalid input');
  }
  return Promise.resolve();
};

export const ParcelAmountValidator = (_, value) => {
  const numberPattern = /^[0-9]+$/;
  let isValueExist = false;
  if (value && (value.length < 3 || value.length > 7)) {
    return Promise.reject('Invalid input');
  }
  if (value && value.length != 0) {
    isValueExist = true;
  }
  if (isValueExist && !numberPattern.test(value)) {
    return Promise.reject('Invalid input');
  }
  return Promise.resolve();
};

export const PasswordValidator = (_, value) => {
  const minLength = 8;
  const hasLetters = /[A-Za-z]/.test(value);
  const hasNumbers = /[0-9]/.test(value);
  const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(value);

  if (value && value.length < minLength) {
    return Promise.reject(new Error('Password must be at least 8 characters long!'));
  }

  if (value && (!hasLetters || !hasNumbers || !hasSymbols)) {
    return Promise.reject(new Error('Password must contain letters, numbers, and symbols!'));
  }

  return Promise.resolve();
};

export const emailAddressValidator = (_, value) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (value && !emailPattern.test(value)) {
    return Promise.reject(new Error('Invalid email address'));
  }
  return Promise.resolve();
};

export function compareTimeOnly(fromDate, toDate, inputDate) {
  const from = new Date();
  const to = new Date();
  const input = new Date();
  const hour1 = fromDate.getHours();
  const minute1 = fromDate.getMinutes();
  from.setHours(hour1);
  from.setMinutes(minute1);

  const hour2 = inputDate.getHours();
  const minute2 = inputDate.getMinutes();
  input.setHours(hour2);
  input.setMinutes(minute2);

  const hour3 = toDate.getHours();
  const minute3 = toDate.getMinutes();
  to.setHours(hour3);
  to.setMinutes(minute3);
  return from <= input && to >= input;
}

export const ValidateFromTimeToTime =
  (form, existingData = [], key) =>
  (o, _) => {
    const fromTime = form.getFieldValue('fromTime');
    const toTime = form.getFieldValue('toTime');
    const filterExistingData = existingData
      .filter((d) => d.key !== key && d.fromTime && d.toTime)
      .map((d) => ({ ...d, fromTime: new Date(d.fromTime), toTime: new Date(d.toTime) }));
    if (o.field === 'fromTime' && fromTime && toTime && fromTime > toTime) {
      return Promise.reject(new Error('From Time must be before To Time'));
    } else if (
      o.field === 'fromTime' &&
      fromTime &&
      filterExistingData.filter((d) => compareTimeOnly(d.fromTime, d.toTime, new Date(fromTime))).length > 0
    ) {
      return Promise.reject(new Error('From Time already exist in other peak hour rule.'));
    } else if (o.field === 'toTime' && toTime && fromTime && toTime < fromTime) {
      return Promise.reject(new Error('To Time must be after From Time'));
    } else if (
      o.field === 'toTime' &&
      toTime &&
      filterExistingData.filter((d) => compareTimeOnly(d.fromTime, d.toTime, new Date(toTime))).length > 0
    ) {
      return Promise.reject(new Error('To Time already exist in other peak hour rule.'));
    }
    return Promise.resolve();
  };

export const ValidateFromRangeToRange =
  (form, existingData = [], key) =>
  (o, _) => {
    const fromRange = form.getFieldValue('fromRange');
    const toRange = form.getFieldValue('toRange');
    const filterExistingData = existingData.filter((d) => d.key !== key);
    if (o.field === 'fromRange' && toRange && fromRange > toRange) {
      return Promise.reject(new Error('From Range must be less then To Range'));
    } else if (
      o.field === 'fromRange' &&
      filterExistingData.filter((d) => d.fromRange <= fromRange && d.toRange >= fromRange).length > 0
    ) {
      return Promise.reject(new Error('From Range already exist in other fare rule.'));
    } else if (o.field === 'toRange' && fromRange && toRange < fromRange) {
      return Promise.reject(new Error('To Range must be less then From Range'));
    } else if (
      o.field === 'toRange' &&
      filterExistingData.filter((d) => d.fromRange <= toRange && d.toRange >= toRange).length > 0
    ) {
      return Promise.reject(new Error('To Range already exist in other fare rule.'));
    }
    return Promise.resolve();
  };

export const ValidateFromDateToDate = (form) => (o, _) => {
  const fromDate = form.getFieldValue('fromDate');
  const toDate = form.getFieldValue('toDate');
  if (o.field === 'fromDate' && fromDate && toDate && fromDate.isAfter(toDate)) {
    return Promise.reject(new Error('From date must be before To date'));
  } else if (o.field === 'toDate' && fromDate && toDate && toDate.isBefore(fromDate)) {
    return Promise.reject(new Error('To date must be after From date'));
  }
  return Promise.resolve();
};

export const NrcFormatValidator = (_, value) => {
  const regx_eng = /^\d{1,2}\/[A-Za-z]+?\([A-Za-z]\)\d{6}$/;
  // const regx_mm = /([၀-၉]{1,2})\/([ကခဂဃငစဆဇဈညဎဏတထဒဓနပဖဗဘမယရလဝသဟဠဥဧ]{3})(\([နိုင်,ဧည့်,ပြု,စ,သာ,သီ]{1,6}\))([၀-၉]{6})$/;
  if (!value) {
    return Promise.reject(new Error('*(requried)'));
  }
  if (!regx_eng.test(value)) {
    return Promise.reject(new Error('Invalid NRC Format'));
  }

  return Promise.resolve();
};

export const LicenseNumberValidator = (_, value) => {
  const privateVehicleRegex = /^[A-Z0-9]{2}-\d{4}$/;
  if (!privateVehicleRegex.test(value)) {
    return Promise.reject(new Error('Invalid License Number Format'));
  }
  return Promise.resolve();
};

export const DateFormat = ({ date, format = 'YYYY-MM-DD hh:mm A' }) => {
  return <span>{date ? dayjs(date).format(format) : '-'}</span>;
};
