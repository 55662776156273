import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Breadcrumb, Button, Col, List, message, Row } from 'antd';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AwsPlaceDetail, AwsPlaceFinder } from '../../awsservice/aws-network-service';
import { NJVButton, NJVInput } from '../../component/core-component';
import { mapBoxAccessToken } from '../../core/constant';
import CustomPath from '../../route/custom-path';
import './map.css';
import { useGeolocation } from 'react-use';

const el = document.createElement('div');
el.className = 'custom-marker';
el.style.backgroundImage = `url(/images/station_marker.png)`;
el.style.width = '50px';
el.style.height = '50px';
el.style.marginTop = '-30px';
el.style.backgroundSize = '100%';

function ChargingStationMap() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const mapContainerRef = useRef();
  const markerRef = useRef();
  const mapRef = useRef();
  const geoRef = useRef({ longitude: 0, latitude: 0 });
  const [placeDataList, setPlaceDataList] = useState([]);
  const [selectedCoordinate, setSelectedCoordinate] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [data] = useState(state?.data);
  // const geoLocation = useGeolocation();

  useEffect(() => {
    // if (!geoLocation.latitude || !geoLocation.longitude) return;
    // if (geoRef.current.latitude === geoLocation.latitude && geoRef.current.longitude === geoLocation.longitude) return;
    // geoRef.current = { latitude: geoLocation.latitude, longitude: geoLocation.longitude };
    mapboxgl.accessToken = mapBoxAccessToken;
    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current, // Map container reference
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [96.14402811407751, 16.88561065060059], // Replace with your desired coordinates
      zoom: 12, // Replace with your desired zoom level
    });
    mapRef.current = newMap;

    // Ensure the map resizes correctly on zoom change
    mapRef.current.on('zoom', () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    });
    addMarker([96.14402811407751, 16.88561065060059]);
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  const searchAwsPlace = async (placeName) => {
    if (placeName) {
      await AwsPlaceFinder(placeName)
        .then((response) => {
          setPlaceDataList(response?.Results);
        })
        .catch(() => {});
    } else {
      setPlaceDataList([]);
    }
  };

  const searchAwsPlaceDetail = async (placeId) => {
    if (placeId) {
      await AwsPlaceDetail(placeId)
        .then((response) => {
          setSelectedAddress(response?.Place?.Label);
          addMarker(response?.Place?.Geometry?.Point);
        })
        .catch(() => {});
    } else {
      message.error('Unenable to find target place');
    }
  };

  const addMarker = (coordinate) => {
    if (markerRef.current) {
      markerRef.current.remove();
    }
    markerRef.current = new mapboxgl.Marker({ element: el, anchor: 'center', draggable: true })
      .setLngLat(coordinate)
      .addTo(mapRef.current);
    mapRef.current.flyTo({ center: coordinate, essential: true, zoom: 14 });
    markerRef.current.on('dragend', onDragEnd);
    // Prevent unnecessary state updates
    if (selectedCoordinate[0] !== coordinate[0] || selectedCoordinate[1] !== coordinate[1]) {
      setSelectedCoordinate(coordinate);
    }
  };

  const onDragEnd = () => {
    const lngLat = markerRef.current.getLngLat();
    setSelectedCoordinate([lngLat?.lng, lngLat?.lat]);
    setSelectedAddress(null);
  };

  const onMapClick = (event) => {
    const { lng, lat } = event.lngLat;
    if (markerRef.current) {
      markerRef.current.remove();
    }
    markerRef.current = new mapboxgl.Marker({ element: el, anchor: 'center', draggable: true })
      .setLngLat([lng, lat])
      .addTo(mapRef.current);
    setSelectedCoordinate([lng, lat]);
  };

  const onConfirm = () => {
    const newData = data;
    if (selectedCoordinate?.length != 0 && selectedCoordinate?.length == 2) {
      newData['latitude'] = selectedCoordinate[1];
      newData['longitude'] = selectedCoordinate[0];
      // newData['address'] = selectedAddress;
    }
    if (data?.form_type === 'Update') {
      navigate(`${data?.parent_route}/${data?.id}`, { state: { previousData: newData } });
    } else {
      navigate(`${data?.parent_route}`, { state: { previousData: newData } });
    }
    setSelectedCoordinate([]);
  };

  const backToPrevious = () => {
    if (data?.form_type === 'Update') {
      navigate(`${data?.parent_route}/${data?.id}`, { state: { previousData: data } });
    } else {
      navigate(`${data?.parent_route}`, { state: { previousData: data } });
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.charging_station}>Charging Station</Link>,
              },
              {
                title: 'Map',
              },
            ]}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={backToPrevious}>
            Back
          </Button>
        </Col>
        <Col span={24}>
          <div className="map_box_container" style={{ position: 'relative', height: '100%', width: '100%' }}>
            <div
              ref={mapContainerRef}
              style={{
                position: 'relative',
                width: '100%',
                height: 'calc(100vh - 100px)', // Adjust the height based on your layout
                overflow: 'hidden', // Ensure no overflow issues
              }}
            ></div>
            <div style={{ position: 'absolute', top: 5, left: 5 }}>
              <NJVInput
                placeholder="Type and search location"
                style={{ width: 450 }}
                onChange={(e) => searchAwsPlace(e?.target?.value)}
              />
            </div>
            <div style={{ position: 'absolute', top: 5, right: 5 }}>
              <NJVButton type="primary" onClick={() => onConfirm()}>
                Confirm
              </NJVButton>
            </div>

            {placeDataList?.length !== 0 ? (
              <div style={{ position: 'absolute', overflow: 'auto', top: 55, bottom: 35, left: 5, width: 450 }}>
                <List
                  itemLayout="horizontal"
                  dataSource={placeDataList}
                  style={{ backgroundColor: 'white', padding: 10, borderRadius: 8, cursor: 'pointer' }}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item?.Text}
                        onClick={() => {
                          searchAwsPlaceDetail(item?.PlaceId);
                        }}
                      />
                    </List.Item>
                  )}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ChargingStationMap;
