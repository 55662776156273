import { Avatar, Button, Col, Divider, Drawer, List, Row } from 'antd';
import { NJVButton, NJVInput } from '../../component/core-component';
import { useEffect, useMemo, useState } from 'react';
import { Constant } from '../../core/constant';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';

const DriverAssignDrawer = ({ isOpenDrawer, onClose, bookingId }) => {
  const pageSize = Constant.pageSize;

  const [driverList, setDriverLists] = useState([]);
  const [driverPage, setDriverPage] = useState(0);
  const [isFetchingDriver, setIsFetchingDriver] = useState(false);
  const [driverPagination, setDriverPagination] = useState(0);
  const [filterData, setFilterData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => fetchDriver(), []);

  const fetchDriver = (pageNumber) => {
    setIsFetchingDriver(true);
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : driverPage,
      fullName: filterData?.searchDriverName?.trim() ? filterData?.searchDriverName?.trim() : '',
    };

    ApiHandler({
      url: Api.driver_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setDriverPage(pageNumber ? pageNumber - 1 : driverPage);
        setDriverPagination(response?.totalElements);
        setDriverLists(response?.content);
      })
      .catch(() => {})
      .finally(setIsFetchingDriver(false));
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    fetchDriver({});
  };

  const assignDriver = async (value) => {
    setLoading(true);
    const requestData = {
      driverId: value,
    };
    await ApiHandler({
      url: Api.assign_driver_to_schedule_trip,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData,
      specificId: bookingId,
    })
      .then((response) => {
        onClose();
      })
      .catch((error) => setLoading(false));
    setLoading(false);
  };

  return (
    <Drawer
      title="Assign Driver"
      placement="right"
      open={isOpenDrawer}
      onClose={onClose}
      width={800}
      pagination={{
        onChange: (page) => {},
        pageSize: 3,
      }}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Row gutter={16}>
        <Col span={9}>
          <NJVInput
            onChange={(e) => onChangeFilter('searchDriverName', e.target.value)}
            bgcolor="#f1f1f1"
            style={{ width: '250px' }}
            placeholder="Search by DriverName"
          />
        </Col>
        <Col span={4}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
      </Row>
      <h2 style={{ marginBottom: 20 }}>Driver Name</h2>
      <Row>
        <Col span={24}>
          {/* {isFetchingDriver ? (
            <Skeleton active />
          ) : ( */}
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={driverList}
            itemLayout="horizontal"
            renderItem={(driver, index) => (
              <>
                <List.Item
                  actions={[
                    <>
                      <Button
                        loading={loading}
                        onClick={() => assignDriver(driver.id)}
                        type="primary"
                        style={{ marginTop: 10 }}
                      >
                        Assign
                      </Button>
                    </>,
                  ]}
                >
                  <Avatar size="large" style={{ marginRight: 8, color: '#111222', marginTop: 2 }} />
                  {driver.fullName}
                </List.Item>
                <Divider style={{ margin: '4px 0' }} />
              </>
            )}
          />
          {/* )} */}
        </Col>
      </Row>
    </Drawer>
  );
};

export default DriverAssignDrawer;
