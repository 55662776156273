import { Button, Col, Form, message, Modal, Row, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { NJVAddButton, NJVTable, TitleLevel3 } from '../../component/core-component';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import { useToggle } from 'react-use';
import { ValidateFromRangeToRange } from '../../core/validator';
import { EditableCell } from './distance-fare';

const TrafficCharges = () => {
  const type = '';
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [count, setCount] = useState(0);
  const [newRowKey, setNewRowKey] = useState(null);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const FARE_TYPE = 'TRAFFIC_CHARGES';

  const fetchData = useCallback((pageNumber) => {
    const requestParams = {
      fareType: FARE_TYPE,
    };
    ApiHandler({
      url: Api.fare_rule_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setTotalPagination(response?.totalElements);
        setPage(pageNumber ? pageNumber - 1 : page);
        setData(response?.map((c) => ({ ...c, key: c.id })));
      })
      .catch()
      .finally(() => setDataFetching(false));
  }, []);

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, [fetchData]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
    setNewRowKey(false);
  };

  const cancel = (key) => {
    if (newRowKey) {
      const newData = data.filter((item) => item.key !== key);
      setData(newData);
      setEditingKey('');
    } else {
      setEditingKey('');
    }
  };

  const handleCreate = () => {
    const newKey = count.toString();
    const newRow = {
      key: newKey,
    };
    form.setFieldsValue({ key: newRow.key, fromRange: '', toRange: '', farePrice: '' });
    setData([...data, newRow]);
    setCount(count + 1);
    setEditingKey(newKey);
    setNewRowKey(true);
  };

  const EditSave = (row) => {};

  const save = async (formData) => {
    const lastData = data.at(-2);
    const lastFromRange = lastData?.toRange ?? 0;

    const requestData = { ...form.getFieldsValue(), fareType: FARE_TYPE };
    if (requestData.fromRange !== lastFromRange + 1) {
      message.error(`From range should be ${lastFromRange + 1}.`);
      return;
    }
    toggleSubmitting();
    ApiHandler({
      url: Api.fare_rule,
      method: formData.id ? HTTP_METHOD.PUT : HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: { ...formData, ...requestData },
      disableShowMessage: true,
    })
      .then(() => {
        fetchData();
        setEditingKey('');
        form.setFieldsValue({ key: formData.key, fromRange: '', toRange: '', farePrice: '' });
      })
      .catch(() => {})
      .finally(toggleSubmitting);
  };

  const defaultColumns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'From (Min)',
      dataIndex: 'fromRange',
      key: 'fromRange',
      width: '10%',
      editable: true,
      render: (range) => (
        <>
          {range}
          <span style={{ marginLeft: '4px' }}>Min</span>
        </>
      ),
    },
    {
      title: 'To (Min)',
      dataIndex: 'toRange',
      key: 'toRange',
      editable: true,
      render: (range) => (
        <>
          {range}
          <span style={{ marginLeft: '4px' }}>Min</span>
        </>
      ),
    },
    {
      title: 'Charges',
      dataIndex: 'farePrice',
      key: 'farePrice',
      editable: true,
      render: (charge) => (
        <>
          {charge}
          <span style={{ marginLeft: '4px' }}>MMK</span>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="primary" style={{ marginRight: 8 }} loading={submitting} onClick={() => save(record)}>
              Save
            </Button>
            <Button title="Sure to cancel?" onClick={() => cancel(record.key)}>
              Cancel
            </Button>
          </span>
        ) : (
          <span>
            <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }}>
              Edit
            </Button>
            <Button
              size={'middle'}
              type="primary"
              danger
              onClick={() => {
                setSelectedId(record.id);
                toggleModelOpen();
              }}
            >
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const deleteRow = (id) => {
    ApiHandler({
      url: Api.fare_rule_delete,
      method: HTTP_METHOD.POST,
      requestData: {
        id,
      },
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        rules: [{ validator: ValidateFromRangeToRange(form, data, record.key) }],
        editing: isEditing(record),
        EditSave,
      }),
    };
  });

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Traffic Fare'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NJVAddButton type={type} handleClick={handleCreate} />
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <Form form={form} component={false}>
              <NJVTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={false}
              />
            </Form>
          )}
        </Col>
      </Row>
      <Modal
        title="Delete Traffice Charges Rule"
        open={isModelOpen}
        onOk={() => deleteRow(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};
export default TrafficCharges;
