import { Image } from 'antd';
import React from 'react';
const logo = require('../../assets/zeimm_logo.png');

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Image width={300} src={logo} preview={false} style={{ marginBottom: 10 }} />
    </div>
  );
};

export default Dashboard;
