import { Breadcrumb, Button, Card, Checkbox, Col, Form, Image, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NJVButton, NJVInput, NJVSelect } from '../../../component/core-component';
import Theme from '../../../component/theme';
import CustomPath from '../../../route/custom-path';
import { ApiHandler } from '../../../network/network-manager';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { PasswordValidator, PhoneNumberValidator } from '../../../core/validator';
import { getBase64 } from '../../../core/utils';
import ProfileUploadButton from '../../../component/profile-upload-button';

function AdminForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [role, setRole] = useState([]);

  const fetchRole = (pageNumber) => {
    ApiHandler({
      url: Api.role_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setRole(response?.content);
      })
      .catch(() => {})
      .finally();
  };

  useEffect(() => {
    fetchRole();
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const createAdmin = (formData) => {
    setLoading(true);

    formData = {
      ...formData,
      position: formData.position ? formData.position : undefined,
      status: formData.active ? 'Active' : 'Inactive',
    };
    const requestData = new FormData();
    Object.keys(formData).forEach((key) => {
      formData[key] && requestData.append(key, formData[key]);
    });
    uploadedFile && requestData.append('file', uploadedFile);

    ApiHandler({
      url: Api.admin,
      method: HTTP_METHOD.POST,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.admin);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.admin}>Admin</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Admin Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" initialValues={{ active: true }} onFinish={createAdmin}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item>
                    <Upload
                      accept="image/png, image/jpeg, image/jpg"
                      beforeUpload={(file) => {
                        setUploadedFile(file);
                        return false;
                      }}
                      onRemove={() => {
                        setUploadedFile(undefined);
                      }}
                      listType="picture-circle"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length === 1 ? null : <ProfileUploadButton />}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="fullName" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter name'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label={'Phone Number'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]} //
                  >
                    <NJVInput placeholder={'0979xxxxxxxx'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="emailAddress"
                    label={'Email Address'}
                    rules={[{ required: true, message: '*(requried)' }, { type: 'email' }]} //
                  >
                    <NJVInput placeholder={'Enter email address'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="password"
                    label={'Password'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: PasswordValidator }]}
                    style={{ cursor: 'pointer' }}
                  >
                    <NJVInput ispasswordinput={true} placeholder="Enter password" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="position" label={'Position'}>
                    <NJVInput placeholder={'Enter position'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="roleId" label={'Role'}>
                    <NJVSelect size="large" placeholder="Select Role" style={{ width: '100%' }}>
                      {role?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="active" valuePropName="checked" label="Active">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={loading}>
                  Create
                </NJVButton>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}

export default AdminForm;
