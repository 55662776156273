import { PlusOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, Divider, Modal, Row, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-resizable/css/styles.css';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { NJVButton, NJVInput, NJVSelect } from '../../component/core-component';
import { SvgCalendarOutlined } from '../../component/custom-svg-icon';
import Theme from '../../component/theme';
import { Link } from 'react-router-dom';
import CustomPath from '../../route/custom-path';
import Api from '../../network/api';
import { ApiHandler } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';

const weekDaysMap = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const AssignType = {
  assign: 'ASSIGN',
  unassign: 'UNASSIGN',
  reassign: 'REASSIGN',
};

const convertDateToDayJs = (targetDate) => {
  if (targetDate) {
    const date = dayjs(new Date(targetDate));
    return date.format('YYYY-MM-DD HH:mm');
  }
  return null;
};

const convertStringDateToDate = (strDate) => {
  if (strDate) {
    const dayjsDate = dayjs(strDate, 'YYYY-MM-DD HH:mm');
    return dayjsDate.toDate();
  }
  return null;
};

const defaultColor = '#1677ff';

const containerStyle = {
  textAlign: 'center',
  backgroundColor: '#EDEDED',
  paddingTop: '12px',
  paddingBottom: '12px',
  color: 'black',
  fontWeight: 'bold',
  borderRadius: 10,
};

const currentDateStyle = {
  marginTop: 5,
  padding: 10,
  backgroundColor: defaultColor,
  display: 'inline-block',
  borderRadius: 50,
  color: 'white',
  fontSize: 17,
};

const dateStyle = {
  marginTop: 5,
  padding: 10,
  display: 'inline-block',
  fontSize: 17,
};

const containerStyle2 = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px',
  borderRadius: 10,
  color: 'white',
  fontWeight: 'bold',
  border: '1px dashed lightgrey',
};

const vehicleContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px',
  borderRadius: 10,
  color: 'black',
  fontWeight: 'bold',
  backgroundColor: '#EDEDED',
};

let stompClient = null;

const TestSocket = () => {
  const selectedDate = dayjs();
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [assignDate, setAssignDate] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [assignSlotMap] = useState(new Map());
  const [currentDateMap] = useState(new Map());
  const [vehicleMonthYear, setVehicleMonthYear] = useState(null);
  const [unAssignId, setUnAssignId] = useState(null);
  const [selectedDriverAssignment, setSelectedDriverAssignment] = useState(null);
  const [unavailableAssignDates, setUnavailableAssignDates] = useState([]);
  const [currentWeekDay, setCurrentWeekDay] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [regions, setRegions] = useState([]);

  const [socketConnected, setSocketConnected] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openReAssignModal, setOpenReAssignModal] = useState(false);
  const [openUnavailableAssignDateModal, setOpenUnavailableAssignDateModal] = useState(false);
  const [openUnassignConfirmationModal, setOpenUnassignConfirmationModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, []);

  const connectSocket = () => {
    try {
      const Sock = new SockJS(Api.driver_location_host + '/ws');
      stompClient = over(Sock);
      stompClient.connect(
        {},
        function () {
          setSocketConnected(true);
          stompClient.subscribe('/user/37f5cadc-f29a-47b6-aa2a-d8c3562f927b/driver', onReceiveDate);
        },
        function () {
          setSocketConnected(false);
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onReceiveDate = (payload) => {
    console.log('ReceiveData');
    const payloadData = JSON.parse(payload?.body);
    console.log(payloadData);
  };

  const sendData = () => {
    stompClient.send(
      '/app/saveDriverLocation',
      {},
      JSON.stringify({
        driverId: 26,
        driverUniqueId: '37f5cadc-f29a-47b6-aa2a-d8c3562f927b',
        lat: 12.00343,
        lng: 18.32323,
        direction: 0.0,
        coordinateList: [{ coordinates: [19.344343, 12.343434] }, { coordinates: [19.3498934, 12.343434] }],
      }),
    );
  };
  return (
    <Row>
      <Col span={24}>
        <Button onClick={sendData}>Send data</Button>
      </Col>
    </Row>
  );
};
export default TestSocket;
