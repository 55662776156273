import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { AutoComplete, Avatar, Button, Col, Form, Input, Modal, Row, Select, Tag, Typography } from 'antd';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import '../../App.css';
import { mapBoxAccessToken } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const { Title, Text } = Typography;

const DriverLocationMap = () => {
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const [socketConnected, setSocketConnected] = useState(false);
  let stompClient = null;
  const [markers, setMarkers] = useState([]);
  const [markerCenter, setMarkerCenter] = useState([96.1588, 16.7945]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [region, setRegion] = useState(2);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [zIndex, setZIndex] = useState(1);

  const selectedStatusRef = useRef(selectedStatus);

  useEffect(() => {
    selectedStatusRef.current = selectedStatus;
  }, [selectedStatus]);

  const handleSelect = (selectedValue) => {
    const requestParams = {
      selectedValue: selectedValue.trim(),
    };

    const matchMarker = markers.find(
      (marker) =>
        marker.driverName === requestParams.selectedValue || marker.vehicleNumber === requestParams.selectedValue,
    );

    if (matchMarker && mapRef.current) {
      mapRef.current.flyTo({
        center: [matchMarker.lng, matchMarker.lat],
        zoom: 14,
        essential: true,
        duration: 1200,
      });
    }
  };

  const handleSearch = (value) => {
    const filteredOptions = markers
      ?.filter(
        (marker) =>
          (marker.driverName && marker.driverName.toLowerCase().includes(value.toLowerCase())) ||
          (marker.vehiclePlateNumber && marker.vehiclePlateNumber.toLowerCase().includes(value.toLowerCase())),
      )
      .map((marker) => ({
        value: marker.driverName || marker.vehiclePlateNumber,
        label: `${marker.driverName} (${marker.vehiclePlateNumber ? marker.vehiclePlateNumber : 'No connected vehicle'})`,
      }));

    setOptions(filteredOptions);
  };

  const backToHome = () => navigate(CustomPath.active_driver_list);

  const connectSocket = () => {
    try {
      const Sock = new SockJS(Api.driver_location_host + '/ws');
      stompClient = over(Sock);

      stompClient.connect(
        {},
        () => {
          setSocketConnected(true);
          console.log('Connected to WebSocket');

          stompClient.subscribe(
            `/driver/${region}/locations`,
            (payload) => {
              onReceiveDriverLocation(payload);
            },
            (error) => {
              console.error('Subscription error:', error);
            },
          );
        },
        (error) => {
          setSocketConnected(false);
          console.error('WebSocket connection error:', error);
        },
      );
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  useEffect(() => {
    mapboxgl.accessToken = mapBoxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: markerCenter,
      zoom: 12,
    });

    const nav = new mapboxgl.NavigationControl();
    mapRef.current.addControl(nav, 'bottom-right');

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [markerCenter]);

  const MarkerComponent = (marker) => {
    const el = document.createElement('div');
    el.style.width = '30px';
    el.style.height = '45px';
    el.style.backgroundSize = 'contain';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.transformOrigin = 'center';

    if (marker.tripStatus === 'Active') {
      el.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
    } else if (marker.driverStatus === 'Inactive' || marker.driverStatus === 'Offline') {
      el.style.backgroundImage = 'url(/images/marker/gray-car.png)';
    } else if (marker.driverStatus === 'Idle') {
      el.style.backgroundImage = 'url(/images/marker/purple-car.png)';
    } else if (marker.driverStatus === 'Available') {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    } else if (marker.driverStatus === 'Busy') {
      el.style.backgroundImage = 'url(/images/marker/blue-car.png)';
    } else {
      el.style.backgroundImage = 'url(/images/marker/green-car.png)';
    }

    const popupNode = document.createElement('div');
    popupNode.innerHTML = `
      <div style="display: flex; align-items: center; height: 17px; cursor: pointer; margin: 0; padding: 0">
        <img src="${marker.driverPhotoUrl ? marker.driverPhotoUrl : '/images/default_profile.png'}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
        <div style="display: flex; flex-direction: column;">
          <span style="font-weight: bold; line-height: 2;">${marker.driverName}</span>
          <span style="font-size: 12px; line-height: 1;">${marker.vehiclePlateNumber ? marker.vehiclePlateNumber : ''}</span>
        </div>
      </div>`;

    const initialPopup = new mapboxgl.Popup({
      offset: 25,
      closeOnClick: false,
      closeButton: false,
      openOnClick: false,
    }).setDOMContent(popupNode);

    const offsetPosition = (lat, lng) => {
      const offset = 0.0005;
      const randomLat = lat + (Math.random() - 0.5) * offset;
      const randomLng = lng + (Math.random() - 0.5) * offset;
      return [randomLng, randomLat];
    };

    const newMarker = new mapboxgl.Marker({ element: el })
      .setLngLat(offsetPosition(marker.lat, marker.lng))
      .addTo(mapRef.current)
      .setPopup(initialPopup);

    newMarker.setRotation(marker.direction);
    initialPopup.addTo(mapRef.current);

    newMarker.getElement().onclick = (e) => e.stopPropagation();

    newMarker.getElement().addEventListener('click', () => {
      setIsModalOpen(true);
      setModalData(marker);
    });

    popupNode.addEventListener('click', () => {
      setIsModalOpen(true);
      setModalData(marker);
    });

    initialPopup
      .getElement()
      .querySelector('.mapboxgl-popup-content')
      .addEventListener('click', function () {
        setIsModalOpen(true);
        setModalData(marker);
      });

    const popupContent = initialPopup.getElement().querySelector('.mapboxgl-popup-content');
    popupContent.style.cursor = 'pointer';

    popupContent.addEventListener('click', function () {
      setZIndex((prev) => prev + 1);
      initialPopup.getElement().style.zIndex = zIndex;
    });

    return newMarker;
  };

  const getAllDrivers = useCallback(() => {
    const requestParams = { regionId: region };
    ApiHandler({
      url: Api.driver_location,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    }).then((response) => {
      setDriverData(response);
    });
  }, [region]);

  useEffect(() => {
    markers?.forEach((marker) => {
      marker.markerInstance.remove();
    });

    const filteredMarkers = Array.isArray(driverData)
      ? selectedStatus
        ? driverData.filter((data) => data.driverStatus === selectedStatus)
        : driverData
      : [];

    const updatedMarkers = markers.map((marker) => {
      const matchedMarkerData = filteredMarkers.find((data) => data.driverId === marker.driverId);

      if (matchedMarkerData) {
        marker.markerInstance.remove();
        const newMarkerInstance = MarkerComponent(matchedMarkerData);
        return {
          ...marker,
          lat: matchedMarkerData.lat,
          lng: matchedMarkerData.lng,
          driverStatus: matchedMarkerData.driverStatus,
          markerInstance: newMarkerInstance,
        };
      }
      return marker;
    });

    const newMarkers = filteredMarkers
      .filter((data) => !markers.some((marker) => marker.driverId === data.driverId))
      .map((newMarkerData) => ({
        driverId: newMarkerData.driverId,
        lat: newMarkerData.lat,
        lng: newMarkerData.lng,
        markerInstance: MarkerComponent(newMarkerData),
        driverPhotoUrl: newMarkerData.driverPhotoUrl,
        driverStatus: newMarkerData.driverStatus,
        direction: newMarkerData.direction,
        driverName: newMarkerData.driverName,
        vehiclePlateNumber: newMarkerData.vehiclePlateNumber,
      }));

    setMarkers([...updatedMarkers, ...newMarkers]);
  }, [driverData, selectedStatus, region]);

  useEffect(() => {
    getAllDrivers();
  }, []);

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, [socketConnected, region]);

  const onReceiveDriverLocation = (payload) => {
    if (!payload) return;

    try {
      const markerData = JSON.parse(payload.body);

      if (!mapRef.current) return;

      setMarkers((prevMarkers) => {
        const existingMarkerIndex = prevMarkers?.findIndex((marker) => marker.driverId === markerData.driverId);

        if (existingMarkerIndex !== -1) {
          const existingMarker = prevMarkers[existingMarkerIndex];

          const updatedMarkers = [...prevMarkers];
          updatedMarkers[existingMarkerIndex] = { ...existingMarker, ...markerData };

          if (existingMarker.lat !== markerData.lat || existingMarker.lng !== markerData.lng) {
            if (!selectedStatusRef.current || selectedStatusRef.current === markerData.status) {
              updatedMarkers[existingMarkerIndex] = { ...existingMarker, ...markerData };
            }

            const startCoordinates = [existingMarker.lng, existingMarker.lat];
            const endCoordinates = [markerData.lng, markerData.lat];

            animateMarker(existingMarker.markerInstance, startCoordinates, endCoordinates);
          }

          existingMarker.markerInstance.setRotation(markerData.direction);

          const popupContent = `
              <div style="display: flex; align-items: center; height: 17px">
                <img src="${existingMarker.driverPhotoUrl ? existingMarker.driverPhotoUrl : '/images/default_profile.png'}" style="width: 25px; height: 25px; border-radius: 50%; margin-right: 8px;" />
                <div style="display: flex; flex-direction: column;">
                  <span style="font-weight: bold;">${markerData.driverName}</span>
                  <span style="font-size: small;">${markerData.vehiclePlateNumber}</span>
                </div>
              </div>`;
          existingMarker.markerInstance.getPopup().setHTML(popupContent);

          const markerElement = existingMarker.markerInstance.getElement();
          if (markerData.tripStatus === 'Active') {
            markerElement.style.backgroundImage = 'url(/images/marker/yellow-car.png)';
          } else if (markerData.driverStatus === 'Inactive' || markerData.driverStatus === 'Offline') {
            markerElement.style.backgroundImage = 'url(/images/marker/gray-car.png)';
          } else if (markerData.driverStatus === 'Idle') {
            markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
          } else if (markerData.driverStatus === 'Available') {
            markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
          } else if (markerData.driverStatus === 'Busy') {
            markerElement.style.backgroundImage = 'url(/images/marker/purple-car.png)';
          } else {
            markerElement.style.backgroundImage = 'url(/images/marker/green-car.png)';
          }

          markerElement.addEventListener('click', () => {
            setIsModalOpen(true);
            setModalData(markerData);
          });

          updatedMarkers[existingMarkerIndex] = {
            ...existingMarker,
            lat: markerData.lat,
            lng: markerData.lng,
            direction: markerData.direction,
            driverName: markerData.driverName,
            driverStatus: markerData.driverStatus,
            vehiclePlateNumber: markerData.vehiclePlateNumber,
          };
          setDriverData((prev) => {
            if (!Array.isArray(prev)) {
              console.error('Unexpected state format. Initializing to an empty array.');
              prev = [];
            }

            const existingIndex = prev.findIndex((driver) => driver.driverId === markerData.driverId);
            if (existingIndex !== -1) {
              prev[existingIndex] = { ...prev[existingIndex], ...markerData };
              return [...prev];
            }
            return [...prev, markerData];
          });

          return updatedMarkers;
        } else {
          if (!selectedStatusRef.current || selectedStatusRef.current === markerData.driverStatus) {
            const markerExists = prevMarkers.some((marker) => marker.driverId === markerData.driverId);

            if (markerExists) {
              return prevMarkers.map((marker) => {
                if (marker.driverId === markerData.driverId) {
                  marker.markerInstance.setLatLng([markerData.lat, markerData.lng]);

                  return {
                    ...marker,
                    lat: markerData.lat,
                    lng: markerData.lng,
                  };
                }
                return marker;
              });
            } else {
              const newMarker = MarkerComponent(markerData);

              return [
                ...prevMarkers,
                {
                  driverId: markerData.driverId,
                  lat: markerData.lat,
                  lng: markerData.lng,
                  markerInstance: newMarker,
                  direction: markerData.direction,
                  driverName: markerData.driverName,
                  vehiclePlateNumber: markerData.vehiclePlateNumber,
                },
              ];
            }
          }
          setDriverData((prev) => {
            if (!Array.isArray(prev)) {
              prev = [];
            }

            const existingIndex = prev.findIndex((driver) => driver.driverId === markerData.driverId);
            if (existingIndex !== -1) {
              prev[existingIndex] = { ...prev[existingIndex], ...markerData };
              return [...prev];
            }
            return [...prev, markerData];
          });

          return markers;
        }
      });
    } catch (error) {
      console.error('Error parsing WebSocket data:', error);
    }
  };

  const animateMarker = (markerInstance, startCoordinates, endCoordinates) => {
    const startTime = performance.now();
    const duration = 1000;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const newLng = startCoordinates[0] + (endCoordinates[0] - startCoordinates[0]) * progress;
      const newLat = startCoordinates[1] + (endCoordinates[1] - startCoordinates[1]) * progress;

      if (!isNaN(newLng) && !isNaN(newLat)) {
        markerInstance.setLngLat([newLng, newLat]);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const onRegionChange = (value) => {
    markers?.forEach((marker) => {
      marker.markerInstance.remove();
    });
    mapRef.current.flyTo({
      center: value === '2' ? [96.1588, 16.7945] : value === '1' ? [96.0891, 21.9588] : '',
      zoom: 12,
      essential: true,
      duration: 1500,
    });

    setSocketConnected(value !== region && false);
    setRegion(value);
  };

  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <Modal open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)} closable={true} width={300}>
        <div style={{ textAlign: 'center' }}>
          <Avatar src={modalData.driverPhotoUrl} size={80} />

          <Title level={4}>{modalData.driverName}</Title>
          <Text strong>License Plate Number: {modalData.vehiclePlateNumber ? modalData.vehiclePlateNumber : '-'}</Text>
          <div style={{ marginTop: '8px' }}>
            <Text strong>
              Driver Status:{' '}
              {modalData?.driverStatus === 'Available' ? (
                <Tag color="green">{modalData.driverStatus}</Tag>
              ) : (
                modalData?.driverStatus
              )}
            </Text>
          </div>
        </div>
      </Modal>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100vh', borderRadius: 5 }}></div>
      <Row gutter={12} style={{ margin: '10px 10px 0 10px' }}>
        <Col span={1}>
          <Button onClick={backToHome} type="primary" style={{ height: '40px' }}>
            Back
          </Button>
        </Col>
        <Col span={11} style={{ display: 'flex' }}>
          <Button
            onClick={onRefresh}
            type="primary"
            style={{ height: '40px', backgroundColor: '#28a8fa', marginLeft: 10 }}
          >
            Refresh
            <ReloadOutlined />
          </Button>
          <Form layout="horizontal" style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Col span={9}>
                <Form.Item name="driverName">
                  <AutoComplete
                    options={options}
                    onSearch={handleSearch}
                    onSelect={handleSelect}
                    style={{ width: '100%', marginLeft: 12 }}
                  >
                    <Input
                      style={{
                        height: 40,
                        boxShadow: 'none',
                        borderColor: 'white',
                      }}
                      suffix={<SearchOutlined style={{ color: '#656566' }} />}
                      placeholder="Search by DriverName or VehicleNumber"
                    />
                  </AutoComplete>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={6} />
        <Col span={3}>
          <Form.Item name="region">
            <Select
              style={{
                height: 40,
              }}
              placeholder="Select Region"
              onChange={onRegionChange}
              // allowClear
              defaultValue="Yangon"
            >
              <Select.Option value="2">Yangon</Select.Option>
              <Select.Option value="1">Mandalay</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="status">
            <Select
              style={{
                height: 40,
              }}
              placeholder="Select Status"
              allowClear
              onChange={(value) => {
                setSelectedStatus(value);
              }}
            >
              <Select.Option value="Available">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
              <Select.Option value="Idle">Idle</Select.Option>
              <Select.Option value="Busy">Busy</Select.Option>
              <Select.Option value="Offline">Offline</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DriverLocationMap;
