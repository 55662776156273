import { Card, Col, message, Row, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getRowClassName, NJVButton, NJVCard, NJVInput, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant } from '../../core/constant';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';

const CardView = () => {
  const pageSize = Constant.pageSize;

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = useCallback(
    (pageNumber) => {
      const requestParams = {
        size: pageSize,
        page: pageNumber ? pageNumber - 1 : page,
      };
      ApiHandler({
        url: Api.filter,
        method: HTTP_METHOD.GET,
        MediaType: MEDIA_TYPE.JSON,
        requestParams,
      })
        .then((response) => {
          setPage(pageNumber ? pageNumber - 1 : page);
          setTotalPagination(response?.totalElements);
          setData(response?.content);
        })
        .catch(() => message.error('Failed to load data'))
        .finally(() => setDataFetching(false));
    },
    [page, pageSize],
  );

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, [fetchData]);

  const cardData = [
    { title: 'Card 1', content: 'Content for card 1' },
    { title: 'Card 2', content: 'Content for card 2' },
    { title: 'Card 3', content: 'Content for card 3' },
    { title: 'Card 4', content: 'Content for card 4' },
    { title: 'Card 5', content: 'Content for card 5' },
    { title: 'Card 6', content: 'Content for card 6' },
    { title: 'Card 7', content: 'Content for card 7' },
    { title: 'Card 8', content: 'Content for card 8' },
    { title: 'Card 9', content: 'Content for card 9' },
    { title: 'Card 10', content: 'Content for card 10' },
    { title: 'Card 11', content: 'Content for card 11' },
    { title: 'Card 12', content: 'Content for card 12' },
  ];

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      key: 'fullName',
      dataIndex: 'fullName',
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Card Sample'} />
        </Col>
        {cardData.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
            <NJVCard title={card.title} bordered={false}>
              {card.content}
            </NJVCard>
          </Col>
        ))}
        <Col span={6}>
          <NJVInput bgcolor="#f1f1f1" placeholder="Search by name" />
        </Col>
        <Col span={6}>
          <NJVInput bgcolor="#f1f1f1" placeholder="Search by phone" />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CardView;
