import axios from 'axios';
import AwsApi from '../network/aws-api';
import { awsPlaceKey, awsRouteKey } from '../core/constant';
import { MEDIA_TYPE } from '../network/mediaType';

const axiosInstance = axios.create({
  timeout: 120000,
});

export const AwsPlaceFinder = async (placeName) => {
  let responseData;
  const requestBody = {
    FilterCountries: ['MMR'],
    Language: 'MMR',
    MaxResults: 15,
    Text: placeName,
  };
  const requestParams = { key: awsPlaceKey };
  await axiosInstance
    .post(AwsApi.aws_place, requestBody, { params: requestParams }, { headers: { 'Content-Type': MEDIA_TYPE.JSON } })
    .then((response) => {
      responseData = response?.data;
    })
    .catch((error) => {
      throw error;
    });
  return responseData;
};

export const AwsPlaceDetail = async (placeId) => {
  const finalUrl = AwsApi.aws_place_detail + '/' + placeId;
  let responseData;
  const requestParams = { key: awsPlaceKey };
  await axiosInstance
    .get(finalUrl, { params: requestParams })
    .then((response) => {
      responseData = response?.data;
    })
    .catch((error) => {
      throw error;
    });
  return responseData;
};

export const AwsRoute = async (pickupPoints, dropoffPoints) => {
  const requestBody = {
    DeparturePosition: [pickupPoints.lng, pickupPoints.lat],
    DestinationPosition: [dropoffPoints.lng, dropoffPoints.lat],
    WaypointPositions: [],
    TravelMode: 'Car',
    IncludeLegGeometry: true,
    DistanceUnit: 'Kilometers',
    DepartNow: false,
    CarModeOptions: {
      AvoidFerries: false,
      AvoidTolls: false,
    },
  };
  const requestParams = { key: awsRouteKey };
  const response = await axiosInstance.post(
    AwsApi.aws_route,
    requestBody,
    { params: requestParams },
    { headers: { 'Content-Type': MEDIA_TYPE.JSON } },
  );
  return response.data;
};

export const AwsPositionFinder = async (Position) => {
  const requestBody = {
    Language: 'MMR',
    MaxResults: 3,
    Position,
  };
  const requestParams = { key: awsPlaceKey };
  const response = await axiosInstance.post(
    AwsApi.aws_position,
    requestBody,
    { params: requestParams },
    { headers: { 'Content-Type': MEDIA_TYPE.JSON } },
  );
  return response.data;
};
