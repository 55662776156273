import { Breadcrumb, Button, Card, Checkbox, Col, Form, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Theme from '../../../component/theme';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { ApiHandler } from '../../../network/network-manager';
import CustomPath from '../../../route/custom-path';
import { NJVButton, NJVInput } from '../../../component/core-component';

function RoleAndPermissionForm() {
  const [form] = Form.useForm();
  const formRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [menuList, setMenuList] = useState([]);

  const [menuData, setMenuData] = useState([]);

  const fetchMenuList = () => {
    ApiHandler({
      url: Api.role_menus,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((response) => {
        setMenuList(response);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchMenuList();
  }, []);

  useEffect(() => {
    const updatedMenuData = menuList.map((item) => ({
      menu: item.key,
      read: false,
      write: false,
      delete: false,
    }));
    setMenuData(updatedMenuData);
  }, [menuList]);

  const createRole = (values) => {
    setLoading(true);
    const roleMenus = menuList
      .map((item, index) => ({
        menu: item.key,
        read: values.roleMenus?.[index]?.read || false,
        write: values.roleMenus?.[index]?.write || false,
        delete: values.roleMenus?.[index]?.delete || false,
      }))
      .filter((roleMenu) => roleMenu.read || roleMenu.write || roleMenu.delete);

    const formData = {
      name: values.name,
      description: values.description,
      roleMenus,
    };

    ApiHandler({
      url: Api.role,
      method: HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: formData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.role);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.role}>RoleAndPermissionForm</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Role Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form ref={formRef} form={form} layout="vertical" initialValues={{ active: true }} onFinish={createRole}>
              <Row gutter={[10]}>
                <Col span={24}>
                  <Form.Item name="name" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="description" label={'Description'}>
                    <NJVInput placeholder="Description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Card style={{ marginBottom: 20 }}>
                    <Row>
                      <Col span={12} style={{ fontWeight: 'bold' }}>
                        Menu
                      </Col>
                      <Col span={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Read
                      </Col>
                      <Col span={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Write/Edit
                      </Col>
                      <Col span={4} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Delete
                      </Col>
                    </Row>
                  </Card>
                </Col>

                {menuList.map((item, index) => (
                  <Col span={24} key={item.key} style={{ marginBottom: 10 }}>
                    <Row align="middle" style={{ marginBottom: 10 }}>
                      <Col span={12} style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
                        {item.value}
                      </Col>

                      <Col span={3} style={{ textAlign: 'center' }}>
                        <Form.Item name={['roleMenus', index, 'read']} valuePropName="checked" initialValue={false}>
                          <Checkbox />
                        </Form.Item>
                      </Col>

                      <Col span={4} style={{ textAlign: 'center', marginLeft: 15 }}>
                        <Form.Item name={['roleMenus', index, 'write']} valuePropName="checked" initialValue={false}>
                          <Checkbox />
                        </Form.Item>
                      </Col>

                      <Col span={4} style={{ textAlign: 'center' }}>
                        <Form.Item name={['roleMenus', index, 'delete']} valuePropName="checked" initialValue={false}>
                          <Checkbox />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>

              <Row justify="end" style={{ marginTop: 20 }}>
                <Col>
                  <NJVButton type="primary" htmlType="submit" loading={loading}>
                    Create
                  </NJVButton>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RoleAndPermissionForm;
