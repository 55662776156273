import { Breadcrumb, Button, Card, Checkbox, Col, Form, Image, Row, Select, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../../component/core-component';
import Theme from '../../../component/theme';
import CustomPath from '../../../route/custom-path';
import { ApiHandler } from '../../../network/network-manager';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { NrcFormatValidator, PhoneNumberValidator, ValidateFromDateToDate } from '../../../core/validator';
import NRCInput from '../../../component/nrc-input';
import ProfileUploadButton from '../../../component/profile-upload-button';
import { getBase64 } from '../../../core/utils';
import { Constant } from '../../../core/constant';

function DriverUpdate() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams();

  const formRef = useRef();
  const nrcCodeForm = Form.useWatch('nrcode', form);
  const nrcTypeForm = Form.useWatch('nrcType', form);
  const nrcTownshipForm = Form.useWatch('placen', form);
  const nrcNoForm = Form.useWatch('nrcNo', form);
  const [regions, setRegions] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedFile, setUploadedFile] = useState();

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  useEffect(() => {
    findDriver(id);
    fetchData();
  }, []);

  const fetchData = async () => {
    await ApiHandler({
      url: Api.regions_all,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.FORM_DATA,
    })
      .then((response) => {
        setRegions(response);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const findDriver = async () => {
    await ApiHandler({
      url: Api.driver,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        try {
          const nrcMatch = response.nrc?.match(Constant.nrcValueRegex) ?? '';
          const nrcode = nrcMatch[1];
          const placen = nrcMatch[2];
          const nrcType = nrcMatch[3];
          const nrcNo = nrcMatch[4];
          if (response.status && response.status === 'Active') {
            response['active'] = true;
          } else {
            response['active'] = false;
          }
          response = {
            ...response,
            fromDate: dayjs(response.licenseFromDate, 'YYYY-MM-DD'),
            toDate: dayjs(response.licenseToDate, 'YYYY-MM-DD'),
            joinedDate: dayjs(response.joinedDate, 'YYYY-MM-DD'),
            nrcode,
            placen,
            nrcNo,
            nrcType,
            assignedHub: response.regionId ? response.regionId : null,
          };
          setData(response);
          setFileList(response.profileUrl ? [{ url: response.profileUrl }] : []);
          formRef.current.setFieldsValue(response);
        } catch (error) {
          console.error(error);
        }
      })
      .catch(() => setLoading(false));
  };

  const updateDriver = async (formData) => {
    setLoading(true);
    formData = {
      ...formData,
      id: data.id,
      licenseFromDate: formData.fromDate.format('YYYY-MM-DD'),
      licenseToDate: formData.toDate.format('YYYY-MM-DD'),
      joinedDate: formData.joinedDate.format('YYYY-MM-DD'),
      status: formData.active ? 'Active' : 'Inactive',
      note: formData.note ? formData.note : undefined,
      regionId: formData.assignedHub ? formData.assignedHub : undefined,
    };

    const requestData = new FormData();
    Object.keys(formData).forEach((key) => {
      formData[key] && requestData.append(key, formData[key]);
    });
    uploadedFile && requestData.append('file', uploadedFile);

    await ApiHandler({
      url: Api.driver,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  useEffect(() => {
    if (!nrcCodeForm || !nrcTownshipForm || !nrcTypeForm || !nrcNoForm) return;
    form.setFieldValue('nrc', `${nrcCodeForm}/${nrcTownshipForm}(${nrcTypeForm})${nrcNoForm}`);
  }, [form, nrcCodeForm, nrcNoForm, nrcTownshipForm, nrcTypeForm]);

  const backToHome = () => navigate(CustomPath.driver);

  const disableFutureDates = (current) => {
    return current && current < dayjs().endOf('day');
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.driver}>Driver</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Driver Update'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form form={form} layout="vertical" initialValues={{ active: true }} onFinish={updateDriver} ref={formRef}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item>
                    <Upload
                      accept="image/png, image/jpeg, image/jpg"
                      beforeUpload={(file) => {
                        setUploadedFile(file);
                        return false;
                      }}
                      onRemove={() => {
                        setUploadedFile(undefined);
                      }}
                      listType="picture-circle"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length === 1 ? null : <ProfileUploadButton />}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="fullName" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter name'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNumber"
                    label={'Phone Number'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]}
                  >
                    <NJVInput placeholder={'0979xxxxxxxx'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="NRC"
                    name="nrc"
                    rules={[{ required: true, message: '' }, { validator: NrcFormatValidator }]}
                  >
                    <NRCInput language="en" form={form} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="employeeId"
                    label={'Employee ID'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter Employee ID'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="driverNo" label={'Driver No'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput placeholder={'Enter Driver No'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="joinedDate"
                    label={'Joined Date'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select joined date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="driverLicenseInfo"
                    label={'License Info'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput placeholder={'Enter License Info'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="fromDate"
                    label={'License From Date'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: ValidateFromDateToDate(form) }]}
                  >
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select license from date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="toDate"
                    label={'License To Date'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: ValidateFromDateToDate(form) }]}
                  >
                    <NJVDatePicker size="large" style={{ width: '100%' }} placeholder={'Select license to date'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="address"
                    label={'Permanent Address'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput
                      istextareainput={true}
                      autosize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Permananent Address'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="note" label={'Note'}>
                    <NJVInput istextareainput={true} autosize={{ minRows: 3, maxRows: 5 }} placeholder={'Enter Note'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="assignedHub" label={'Assigned Hub'}>
                    <NJVSelect size="large" placeholder="Select Assigned Hub" style={{ width: '100%' }}>
                      {regions?.map((region) => (
                        <Select.Option key={region.id} value={region.id}>
                          {region.regionName}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="active" valuePropName="checked">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={loading}>
                  Update
                </NJVButton>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
}

export default DriverUpdate;
