import { Col, Input, Row, Select, Skeleton, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Constant } from '../../core/constant';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { NJVButton, NJVInput, NJVSelect, NJVTable, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';

const NotificationHistory = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  const [data, setData] = useState([]);
  const [dataFetching, setDataFetching] = useState(false);
  const [filterData, setFilterData] = useState({});

  const fetchData = (pageNumber) => {
    setDataFetching(true);
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      channel: filterData?.searchChannel?.trim() ? filterData?.searchChannel?.trim() : '',
      title: filterData?.searchTitle?.trim() ? filterData?.searchTitle?.trim() : '',
      userType: filterData?.searchUserType,
    };
    ApiHandler({
      url: Api.notification_history_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setData(response?.content);
        setTotalPagination(response?.totalElements);
        setPage(pageNumber ? pageNumber - 1 : page);
      })
      .catch(() => {})
      .finally(() => {
        setDataFetching(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? 'blue-row' : 'black-row';
  };
  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Send Date',
      key: 'createdDate',
      render: (_, { createDate }) => <>{createDate ?? <span>{dayjs(createDate).format('YYYY-MM-DD HH:MM A')}</span>}</>,
    },
    {
      title: 'Sender Name',
      dataIndex: 'senderName',
      key: 'senderName',
    },
  ];
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Notification History'} />
        </Col>
        <Col span={12} />
        <Col span={6}>
          <NJVInput
            onChange={(e) => onChangeFilter('searchChannel', e.target.value)}
            placeholder="Search by Channel"
            bgcolor={Theme.colors.light_gray}
            name="channel"
          />
        </Col>
        <Col span={6}>
          <NJVInput
            onChange={(e) => onChangeFilter('searchTitle', e.target.value)}
            bgcolor={Theme.colors.light_gray}
            placeholder="Search by Title"
            name="title"
          />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor={Theme.colors.light_gray}
            onChange={(value) => onChangeFilter('searchUserType', value)}
            style={{
              width: '100%',
            }}
            options={userTypes}
          />
        </Col>
        <Col span={6} className="search">
          <NJVButton onClick={fetchData} type="primary" style={{ width: '100%' }}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default NotificationHistory;
