import { Col, Row, Table } from 'antd';
import React, { useCallback, useState } from 'react';
import { getRowClassName, NJVDatePicker, NJVSelect, NJVTable, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';

const DriverVehicleAssignPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [assignedDrivers, setAssignedDrivers] = useState([]);
  const [unAssignedDrivers, setUnAssignedDrivers] = useState([]);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [unAssignedVehicles, setUnassignedVehicles] = useState([]);
  const [selectedType, setSelectedType] = useState('Driver');

  const fetchAssignedDrivers = useCallback(async (date) => {
    await ApiHandler({
      url: Api.driver_get_assigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setAssignedDrivers(response);
      })
      .catch(() => {});
  }, []);

  const fetchUnAssignedDrivers = useCallback(async (date) => {
    await ApiHandler({
      url: Api.driver_get_unassigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setUnAssignedDrivers(response);
      })
      .catch(() => {});
  }, []);

  const fetchAssignedVehicles = useCallback(async (date) => {
    await ApiHandler({
      url: Api.vehicle_get_assigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setAssignedVehicles(response);
      })
      .catch(() => {});
  }, []);

  const fetchUnAssignedVehicles = useCallback(async (date) => {
    await ApiHandler({
      url: Api.vehicle_get_unassigned_list_by_date,
      method: HTTP_METHOD.GET,
      requestParams: { date },
    })
      .then((response) => {
        setUnassignedVehicles(response);
      })
      .catch(() => {});
  }, []);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'Driver',
      dataIndex: 'fullName',
    },
    {
      title: 'Driver No',
      dataIndex: 'driverNo',
    },
  ];

  const columnsVehicle = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'Vehicle License Number',
      dataIndex: 'licenseNumber',
    },
  ];

  const onSelectDate = (date) => {
    if (date) {
      setSelectedDate(date);
      if (date && selectedType === 'Driver') {
        fetchAssignedDrivers(date.format('YYYY-MM-DD'));
        fetchUnAssignedDrivers(date.format('YYYY-MM-DD'));
      } else if (date && selectedType === 'Vehicle') {
        fetchAssignedVehicles(date.format('YYYY-MM-DD'));
        fetchUnAssignedVehicles(date.format('YYYY-MM-DD'));
      }
    } else {
      setSelectedDate(null);
      resetAll();
    }
  };

  const resetAll = () => {
    setAssignedDrivers([]);
    setUnAssignedDrivers([]);
    setUnassignedVehicles([]);
    setAssignedVehicles([]);
  };

  return (
    <Row gutter={[20, 20]}>
      <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
        <TitleLevel3 label={'Driver and vehicle assignment'} />
      </Col>
      <Col span={12} />
      <Col span={6}>
        <NJVSelect
          bgcolor={Theme.colors.light_gray}
          style={{ width: '100%' }}
          onChange={(value) => {
            setSelectedType(value);
            resetAll();
            setSelectedDate(null);
          }}
          defaultValue={selectedType}
          options={[
            { label: 'Driver', value: 'Driver' },
            { label: 'Vehicle', value: 'Vehicle' },
          ]}
        />
      </Col>
      <Col span={6}>
        <NJVDatePicker
          value={selectedDate}
          style={{ width: '100%' }}
          bgcolor={Theme.colors.light_gray}
          onChange={onSelectDate}
        />
      </Col>
      <Col span={12} />
      {selectedType === 'Driver' ? (
        <>
          <Col span={12}>Unassigned Driver</Col>
          <Col span={12}>Assigned Driver</Col>
          <Col span={12}>
            <NJVTable
              rowKey="driverId"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={unAssignedDrivers}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
          <Col span={12}>
            <NJVTable
              rowKey="driverId"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={assignedDrivers}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
        </>
      ) : (
        <>
          <Col span={12}>Unassigned Vehicle</Col>
          <Col span={12}>Assigned Vehicle</Col>
          <Col span={12}>
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columnsVehicle}
              dataSource={assignedVehicles}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
          <Col span={12}>
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columnsVehicle}
              dataSource={unAssignedVehicles}
              pagination={false}
              rowClassName={getRowClassName}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default DriverVehicleAssignPage;
