import { Col, Descriptions, Row, Typography } from 'antd';
import React, { useState } from 'react';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';

const { Text } = Typography;

const PromotionHistoryDetail = () => {
  const [data, setData] = useState({});

  const findTripHistory = () => {
    ApiHandler({
      url: Api.trip_history,
      method: HTTP_METHOD.GET,
      //   specificId: id,
    })
      .then((response) => {
        if (response.status && response.status === 'Active') {
          response['active'] = true;
        } else {
          response['active'] = false;
        }
        response = { ...response };
        setData(response);
      })
      .catch(() => {});
  };

  const items = [
    {
      key: '1',
      label: 'Trip ID ',
      Children: data.tripId,
    },
    {
      key: '2',
      label: 'Driver Name',
      Children: data.dirverName,
    },
    {
      key: '3',
      label: 'Vehicle License Plate Number',
      Children: data.vehicleLicensePlateNumber,
    },
    {
      key: '4',
      label: 'Passenger Name',
      Children: data.passengerName,
    },
    {
      key: '5',
      label: 'Passenger PhoneNumber',
      Children: data.passengerPhoneNumber,
    },
    {
      key: '6',
      label: 'Trip Fees',
      Children: data.tripFees,
    },
    {
      key: '7',
      label: 'Discount Code',
      Children: data.discountCode,
    },
  ];

  return (
    <Descriptions
      colon={false}
      items={items}
      column={2}
      size="large"
      labelStyle={{ fontSize: '15px', color: '#1e1e1e', minWidth: '300px', marginRight: '20px' }}
      contentStyle={{ fontSize: '15px', marginLeft: '20px' }}
      layout="vertical"
    />
  );
};

export default PromotionHistoryDetail;
