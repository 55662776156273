import React, { useEffect, useState } from 'react';
import { nrcdata } from '../core/nrcdata';
import { NJVInput, NJVSelect } from './core-component';
import { Col, Form, Row, Select } from 'antd';

const NRCInput = ({ language = 'en', form }) => {
  const nrcodeForm = Form.useWatch('nrcode', form);
  const nrcode = [
    {
      en: '1',
      mm: '၁',
    },
    { en: '2', mm: '၂' },
    { en: '3', mm: '၃' },
    { en: '4', mm: '၄' },
    { en: '5', mm: '၅' },
    { en: '6', mm: '၆' },
    { en: '7', mm: '၇' },
    { en: '8', mm: '၈' },
    { en: '9', mm: '၉' },
    { en: '10', mm: '၁၀' },
    { en: '11', mm: '၁၁' },
    { en: '12', mm: '၁၂' },
    { en: '13', mm: '၁၃' },
    { en: '14', mm: '၁၄' },
  ];
  const nrcType = [
    { en: 'N', mm: 'နိုင်' },
    { en: 'E', mm: 'ဧည့်' },
    { en: 'P', mm: 'ပြု' },
    { en: 'T', mm: 'သာသနာ' },
    { en: 'R', mm: 'ယာယီ' },
    { en: 'S', mm: 'စ' },
  ];

  const [placen, setPlacen] = useState([]);

  useEffect(() => {
    const data = nrcdata;
    if (!data.length) return [];
    setPlacen(data.filter((item) => item.nrc_code === nrcodeForm));
  }, [nrcodeForm]);
  return (
    <Row gutter={2}>
      <Col span={4}>
        <Form.Item name="nrcode" noStyle rules={[{ required: true, message: '' }]}>
          <NJVSelect showSearch size="large" style={{ minWidth: 50 }}>
            {nrcode.map((item, index) => (
              <Select.Option key={`${item.en}_${index}`} value={item.en}>
                {language === 'mm' ? item.mm : language === 'en' ? item.en : item.en + ' - ' + item.mm}
              </Select.Option>
            ))}
          </NJVSelect>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="placen" noStyle rules={[{ required: true, message: '' }]}>
          <NJVSelect showSearch size="large" style={{ minWidth: 100 }}>
            {placen.map((item, index) => (
              <Select.Option key={`${item.name_en}_${index}`} value={item.name_en}>
                {language === 'mm'
                  ? item.name_mm
                  : language === 'en'
                    ? item.name_en
                    : item.name_en + ' - ' + item.name_mm}
              </Select.Option>
            ))}
          </NJVSelect>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item name="nrcType" noStyle rules={[{ required: true, message: '' }]}>
          <NJVSelect showSearch size="large" style={{ minWidth: 50 }}>
            {nrcType.map((item, index) => (
              <Select.Option key={`${item.en}_${index}`} value={item.en}>
                {language === 'mm' ? item.mm : language === 'en' ? item.en : item.en + ' - ' + item.mm}
              </Select.Option>
            ))}
          </NJVSelect>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          name="nrcNo"
          noStyle
          rules={[
            { required: true, message: '' },
            { len: 6, message: '' },
          ]}
        >
          <NJVInput type="number" min style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NRCInput;
