const prefix = '/v1';
const CustomPath = {
  app: '/',

  login: prefix + '/login',

  dashboard: prefix + '/dashboard',

  role_and_permission: prefix + '/roleandpermission',
  role_and_permission_form: prefix + 'roleandpermissionform',

  admin: prefix + '/admin',
  admin_form: prefix + '/admin/form',
  admin_update: prefix + '/admin/update',
  admin_update_param: prefix + '/admin/update/:id',

  role: prefix + '/role',

  role_form: prefix + '/role/form',
  role_update: prefix + '/role/update',
  role_update_param: prefix + '/role/update/:id',

  driver: prefix + '/driver',
  driver_form: prefix + '/driver/form',
  driver_update: prefix + '/driver/update',
  driver_update_param: prefix + '/driver/update/:id',
  active_driver_list: prefix + '/active-driver-list',

  driver_map: prefix + '/driver-location-map',
  driver_location_sender: prefix + '/driver-location-sender',

  passenger: prefix + '/passenger',
  passenger_form: prefix + '/passenger/form',
  passenger_update: prefix + '/passenger/update',
  passenger_update_param: prefix + '/passenger/update/:id',

  vehicle: prefix + '/vehicle',
  vehicle_form: prefix + '/vehicle/form',
  vehicle_update: prefix + '/vehicle/update',
  vehicle_update_param: prefix + '/vehicle/update/:id',

  live_map: prefix + '/livemap',

  notification_channel: prefix + '/notification/channel',
  notification_channel_form: prefix + '/notification/channel/form',
  notification_channel_update: prefix + '/notification/channel/update',
  notification_channel_update_param: prefix + '/notification/channel/update/:id',
  notification_push: prefix + '/notification/send',
  notification_history: prefix + '/notification/history',

  region: prefix + '/region',
  zone: prefix + '/zone',
  zone_pricing: prefix + '/zone-pricing',
  zone_set_up: prefix + '/zone-set-up',
  zone_set_up_param: prefix + '/zone-set-up/:id',

  driver_vehicle_assign: prefix + '/assign',
  driver_vehicle_availability_check: prefix + '/driver-verhicle-availability-check',

  faq: `${prefix}/faq`,
  faq_form: `${prefix}/faq/form`,
  faq_update: prefix + '/faq/update',
  faq_update_param: prefix + '/faq/update/:id',

  promotion: `${prefix}/promotion`,
  promotion_form: `${prefix}/promotion/form`,
  promotion_update: prefix + '/promotion/update',
  promotion_history: prefix + '/promotion/history',
  promotion_update_param: prefix + '/promotion/update/:id',

  promotion_usage_history: prefix + '/promotion-usage-history',

  sos: `${prefix}/sos`,
  sos_form: `${prefix}/sos/form`,
  sos_update: prefix + '/sos/update',
  sos_update_param: prefix + '/sos/update/:id',
  sos_map: prefix + '/sos/map',
  sos_map_param: prefix + '/sos/map/:id',

  charging_station: `${prefix}/charging-station`,
  charging_station_form: `${prefix}/charging-station/form`,
  charging_station_update: `${prefix}/charging-station/update`,
  charging_station_update_param: `${prefix}/charging-station/update/:id`,
  charging_station_map: `${prefix}/charging-station/map`,

  driver_points: `${prefix}/driver-points`,

  trip_history: `${prefix}/trip-history`,
  trip_history_detail: `${prefix}/trip-history/detail`,
  trip_history_detail_param: `${prefix}/trip-history/detail/:id`,
  trip: `${prefix}/trip`,
  trip_param: `${prefix}/trip/:id`,

  card_view: `${prefix}/card-view`,

  base_fare: `${prefix}/base-fare`,
  pickup_fare: `${prefix}/pickup-fare`,
  waiting_charges: `${prefix}/waiting-charges`,
  distance_fare: `${prefix}/distance-fare`,
  traffic_charges: `${prefix}/traffic-fare`,
  weather_condition: `${prefix}/weather-condition`,
  time_fare: `${prefix}/time-fare`,
  peak_hour: `${prefix}/peak-hour`,
  incentive_defination: `${prefix}/incentive-defination`,

  booking: `${prefix}/booking`,
  driverLocationSender: `${prefix}/driver-locations-sender`,
  driver_location_map: `${prefix}/driver-location-map`,
  trip_dispatcher: `${prefix}/trip/dispatcher`,
  trip_assignment: `${prefix}/trip/admin/assign-trip`,

  trip_complaint_list: `${prefix}/trip-complaint-list`,

  incentive_history: `${prefix}/incentive-history`,

  core_config: `${prefix}/core-config`,
  core_config_form: `${prefix}/core-config-form`,
  core_config_update: `${prefix}/core-config-update`,
  core_config_update_param: `${prefix}/core-config-update/:id`,
};
export default CustomPath;
