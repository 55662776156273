import { CheckCircleOutlined, EyeFilled } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Modal, Popover, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { NJVButton, NJVDatePicker, NJVInput, TitleLevel3 } from '../../component/core-component';
import CoreTableComponent from '../../component/core-table-component';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { NumberOnlyValidator } from '../../core/validator';
import { ApiHandler } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';

const cashTransactionStatusMap = {
  PENDING: 'Pending',
  COLLECTED_BY_ADMIN: 'Collected',
};

function IncentiveHistory() {
  const [filterData, setFilterData] = useState({ incentiveDate: dayjs().format('YYYY-MM-DD') });
  const [inputData, setInputData] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(false);

  const handleSearch = () => {
    const requestParams = {
      driverName: inputData?.searchDriverName?.trim() ? inputData?.searchDriverName?.trim() : '',
      phoneNumber: inputData?.searchPhoneNumber?.trim() ? inputData?.searchPhoneNumber?.trim() : '',
      incentiveDate: inputData?.searchDate ? dayjs(inputData.searchDate).format('YYYY-MM-DD') : '',
    };
    setFilterData(requestParams);
  };

  const onChangeFilter = (key, value) => {
    setInputData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onCollectCash = async (data) => {
    let existingCash = 0;
    selectedData?.driverDailyIncomeTransactions?.forEach((item) => {
      if (item.paymentMethod === 'Cash') {
        existingCash = item.totalIncomeAmount;
        return;
      }
    });
    message.destroy();
    if (existingCash === 0) {
      message.error('Undefined existing cash');
      return;
    }
    if (data.amount != existingCash) {
      message.error('Existing income amount does not match');
      return;
    }

    await ApiHandler({
      url: Api.daily_income_collect,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.JSON,
      requestData: { totalIncomeAmount: data.amount },
      specificId: selectedData?.id,
    })
      .then(() => {
        setRefreshKey(true);
        setOpenConfirmModal(false);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const columns = [
    {
      title: 'Driver Name',
      dataIndex: 'driver',
      render: (driver) => (
        <>
          <div>{driver.fullName}</div>
          <div>{driver.phoneNumber}</div>
        </>
      ),
    },
    {
      title: 'Total Income Amount',
      dataIndex: 'totalIncomeAmount',
    },
    {
      title: 'Target Progress',
      dataIndex: 'incentiveAmount',
      render: (incentiveAmount) => (
        <>
          {incentiveAmount === 0 ? <Tag color={Theme.colors.orange}>Processing</Tag> : <span>{incentiveAmount} %</span>}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      render: (v) => <span>{dayjs(v).format('MMM DD, YYYY')}</span>,
    },
    {
      title: 'Payment Details',
      dataIndex: 'driverDailyIncomeTransactions',
      render: (driverDailyIncomeTransactions) => (
        <>
          <Popover
            content={
              <>
                {driverDailyIncomeTransactions?.map((transaction, index) => (
                  <>
                    <div style={{ color: Theme.colors.default, fontWeight: '600' }}>{transaction.paymentMethod}</div>
                    <div style={{ color: 'gray', fontSize: 14, fontWeight: '600' }}>
                      {transaction.totalIncomeAmount}
                    </div>
                    {index !== driverDailyIncomeTransactions.length - 1 && (
                      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                    )}
                  </>
                ))}
              </>
            }
            title="Payment Details"
            trigger="hover"
          >
            <Button type="text" icon={<EyeFilled />}>
              View
            </Button>
          </Popover>
        </>
      ),
    },
    {
      title: 'Status',
      render: (item) => (
        <>
          {item.cashTransactionStatus === 'DRIVER_DELIVERED' ? (
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              onClick={() => {
                setOpenConfirmModal(true);
                setSelectedData(item);
              }}
            >
              Collect
            </Button>
          ) : (
            <Tag color={item.itemcashTransactionStatus === 'PENDING' ? Theme.colors.default : Theme.colors.green}>
              {cashTransactionStatusMap[item.cashTransactionStatus]}
            </Tag>
          )}
        </>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Modal
        title={'Confirmation'}
        onCancel={() => {
          setOpenConfirmModal(false);
          setSelectedData(null);
        }}
        open={openConfirmModal}
        footer={null}
      >
        <Form layout="vertical" onFinish={onCollectCash}>
          <Form.Item
            name="amount"
            label="Total cash collected from driver"
            rules={[{ required: true, message: '*(requried)' }, { validator: NumberOnlyValidator }]}
          >
            <NJVInput placeholder="Please enter collected cash amount" bgcolor={Theme.colors.light_gray} />
          </Form.Item>
          <Form.Item>
            <NJVButton type="primary" htmlType="submit">
              Collect
            </NJVButton>
          </Form.Item>
        </Form>
      </Modal>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Incentive History'} />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search By DriverName"
            onChange={(e) => onChangeFilter('searchDriverName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search By PhoneNumber"
            onChange={(e) => onChangeFilter('searchPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVDatePicker
            onChange={(e) => onChangeFilter('searchDate', e)}
            bgcolor="#f1f1f1"
            style={{ width: '100%' }}
            // defaultValue={filterData?.incentiveDate}
            defaultValue={dayjs(filterData?.incentiveDate, 'YYYY-MM-DD')}
          />
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          <CoreTableComponent
            componentColumns={columns}
            url={Api.daily_income_history}
            filterData={filterData}
            refreshKey={refreshKey}
          />
        </Col>
      </Row>
    </>
  );
}
export default IncentiveHistory;
