import { EditOutlined, EyeOutlined, PlusCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Skeleton, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  getRowClassName,
  NJVButton,
  NJVInput,
  NJVRangePicker,
  NJVSelect,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import Theme from '../../component/theme';
import { Constant, TripState, TripType } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, downloadExcelFile } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
const TRIP_TYPE_MAP = {
  FIXED_TRIP: 'Fixed Trip',
  KILO_TRIP: 'Kilo Trip',
};
export const convertSecondsToTime = (seconds) => {
  if (seconds) {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let formattedStr = '';

    if (hours) {
      formattedStr += `${hours} hr `;
    }
    if (remainingMinutes) {
      formattedStr += `${remainingMinutes} min `;
    }
    if (remainingSeconds || (!hours && !remainingMinutes)) {
      formattedStr += `${remainingSeconds} sec`;
    }

    return formattedStr.trim();
  } else {
    return '00:00:00';
  }
};

const TripHistory = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [tripId, setTripId] = useState();
  const [filterData, setFilterData] = useState();
  const [excelReqParam, setExcelReqParam] = useState();

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Trip Number',
      dataIndex: 'tripNumber',
      width: 150,
    },
    {
      title: 'Driver Name',
      dataIndex: ['driverDTO', 'fullName'],
      key: 'driverName',
    },
    {
      title: 'Passenger Name',
      dataIndex: ['passengerDTO', 'fullName'],
      key: 'passengerName',
    },
    {
      title: 'Distance Travelled',
      dataIndex: 'distanceTravelled',
      render: (distance) => (
        <>
          {distance ? distance : 0}
          <span style={{ marginLeft: 5 }}>km</span>
        </>
      ),
    },
    {
      title: 'Trip Duration',
      key: 'tripDuration',
      render: (trip) => <>{trip?.tripDuration && <>{convertSecondsToTime(trip?.tripDuration)} </>}</>,
    },
    {
      title: 'Fare',
      key: 'finalFees',
      render: ({ finalFees }) => (
        <>
          {finalFees ? finalFees : 0}
          <span style={{ marginLeft: 5 }}>MMK</span>
        </>
      ),
    },
    {
      title: 'Created By',
      render: (row) => <>{row.createdByAdmin ? 'Admin' : 'Passenger'}</>,
    },
    {
      title: 'Date',
      dataIndex: 'createdDate',
      render: (_, { createdDate }) => (
        <>{createdDate && <span>{dayjs(createdDate).format('YYYY-MM-DD hh:mm A')}</span>}</>
      ),
      width: 180,
    },
    {
      title: 'Dropoff',
      key: 'earlyDropOff',
      render: ({ earlyDropOff }) => <>{earlyDropOff && <Tag color={Theme.colors.green}>Early Dropoff</Tag>}</>,
    },
    {
      title: 'Trip Type',
      key: 'tripType',
      render: (trip) => <>{TRIP_TYPE_MAP[trip?.tripType]}</>,
    },
    {
      title: 'Trip Status',
      dataIndex: 'tripStatus',
      render: (status) => (
        <>
          {status === 'Completed' ? (
            <Tag color="#87d068">{status}</Tag>
          ) : status === 'Cancelled' ? (
            <Tag color="#cd2011">{status}</Tag>
          ) : status === 'Started' ? (
            <Tag color={Theme.colors.light_blue}>{status}</Tag>
          ) : status === 'DriverArrived' ? (
            <Tag color={Theme.colors.purple}>{status}</Tag>
          ) : (
            <Tag color={Theme.colors.orange}>{status}</Tag>
          )}
        </>
      ),
    },

    {
      title: 'Details',
      width: 100,
      render: (history) => (
        <>
          <Link to={`${CustomPath.trip_history_detail}/${history.id}`}>
            <Button type="primary" shape="circle" icon={<EyeOutlined />} />
          </Link>
        </>
      ),
      fixed: 'right',
    },
  ];

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      tripNumber: filterData?.searchTripNumber?.trim() ? filterData?.searchTripNumber?.trim() : '',
      tripState: filterData?.searchTripStatus?.trim() ? filterData?.searchTripStatus?.trim() : '',
      tripType: filterData?.searchTripType?.trim() ? filterData?.searchTripType?.trim() : '',
      driverName: filterData?.searchDriverName?.trim() ? filterData?.searchDriverName?.trim() : '',
      fromDate: filterData?.fromDate?.trim() ? filterData?.fromDate?.trim() : '',
      toDate: filterData?.toDate?.trim() ? filterData?.toDate?.trim() : '',
      employeeId: '',
    };

    const excelParams = {
      tripNumber: filterData?.searchTripNumber?.trim() ? filterData?.searchTripNumber?.trim() : '',
      tripState: filterData?.searchTripStatus?.trim() ? filterData?.searchTripStatus?.trim() : '',
      tripType: filterData?.searchTripType?.trim() ? filterData?.searchTripType?.trim() : '',
      driverName: filterData?.searchDriverName?.trim() ? filterData?.searchDriverName?.trim() : '',
      fromDate: filterData?.fromDate?.trim() ? filterData?.fromDate?.trim() : '',
      toDate: filterData?.toDate?.trim() ? filterData?.toDate?.trim() : '',
      employeeId: '',
    };

    Object.keys(excelParams).forEach((key) => {
      if (!excelParams[key]) {
        delete excelParams[key];
      }
    });

    setExcelReqParam(excelParams);

    Object.keys(requestParams).forEach((key) => {
      if (requestParams[key] === null || requestParams[key] === undefined || requestParams[key] === '') {
        delete requestParams[key];
      }
    });

    ApiHandler({
      url: Api.trip_history_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  const handleSearch = () => {
    setPage(0);
    fetchData(1);
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeStatus = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      searchTripStatus: key,
    }));
  };

  const onChangeType = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      searchTripType: key,
    }));
  };

  const onChangeDate = (value) => {
    if (value && value.length === 2) {
      const startDate = dayjs(value[0])?.format('YYYY-MM-DD');
      const endDate = dayjs(value[1])?.format('YYYY-MM-DD');
      setFilterData((prev) => ({
        ...prev,
        fromDate: startDate,
        toDate: endDate,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        fromDate: '',
        toDate: '',
      }));
    }
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={21} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Trip History'} />
        </Col>
        <Col span={2} style={{ textAlign: 'end' }}>
          <Button
            icon={<VerticalAlignBottomOutlined style={{ fontSize: '17px', fontWeight: 'bold' }} />}
            type="primary"
            onClick={() =>
              downloadExcelFile({
                url: Api.trip_excel_export,
                requestParams: excelReqParam,
                name: 'trip-history-excel-export',
              })
            }
          >
            Export Excel
          </Button>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Trip Number"
            onChange={(e) => onChangeFilter('searchTripNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by DriverName"
            onChange={(e) => onChangeFilter('searchDriverName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVRangePicker bgcolor="#f1f1f1" onChange={onChangeDate} allowClear="true" style={{ width: '100%' }} />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor={Theme.colors.light_gray}
            size="large"
            placeholder="Search by status"
            onChange={onChangeStatus}
            style={{ width: '100%' }}
          >
            {Object.entries(TripState).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor={Theme.colors.light_gray}
            size="large"
            placeholder="Search by Trip Type"
            onChange={onChangeType}
            style={{ width: '100%' }}
          >
            {Object.entries(TripType).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={12} />
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
              scroll={{
                x: 1600,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TripHistory;
