import { Breadcrumb, Button, Col, Row, Select, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import { Constant, PromotionType } from '../../core/constant';
import { Link, useParams } from 'react-router-dom';
import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVInput,
  NJVRangePicker,
  NJVSelect,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import CustomPath from '../../route/custom-path';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MEDIA_TYPE } from '../../network/mediaType';

const PromotionUsageHistory = () => {
  const pageSize = Constant.pageSize;
  const [histories, setHistories] = useState([]);
  const [historyPage, setHistoryPage] = useState(0);
  const [historyTotalPagination, setHistoryTotalPagination] = useState(0);
  const [isFetchingHistories, toggleFetchingHistories] = useState(false);
  const [filterData, setFilterData] = useState();

  const pointsHistoryColumns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{historyPage * pageSize + index + 1}</span>,
      width: 100,
      fixed: 'left',
    },
    {
      title: 'Promotion Code',
      render: (row) => <Typography.Text>{row.promotionCode}</Typography.Text>,
    },
    {
      title: 'Trip Number',
      render: (row) => <Typography.Text>{row.tripNumber}</Typography.Text>,
    },
    {
      title: 'Driver Name',
      render: (row) => <Typography.Text>{row.driverName}</Typography.Text>,
    },
    {
      title: 'Driver ID',
      render: (row) => <Typography.Text>{row.driverNo}</Typography.Text>,
    },
    {
      title: 'Passenger Name',
      render: (row) => <Typography.Text>{row.passengerName}</Typography.Text>,
    },
    {
      title: 'Passenger Phone Number',
      render: (row) => <Typography.Text>{row.passengerPhoneNumber}</Typography.Text>,
    },
    {
      title: 'Passenger Device ID',
      render: (row) => <Typography.Text>{row.passengerDeviceId}</Typography.Text>,
    },
    {
      title: 'Original Amount',
      render: (row) => (
        <Typography.Text>
          {row.tripBaseFees ? `${row.tripBaseFees}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'Discount Amount',
      render: (row) => (
        <Typography.Text>
          {row.promotionAmount ? `${row.promotionAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
        </Typography.Text>
      ),
    },
    {
      title: 'Final Amount',
      render: (row) => (
        <Typography.Text>
          {row.tripFinalFees ? `${row.tripFinalFees}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
        </Typography.Text>
      ),
    },
    {
      title: '',
      render: (row) => (
        <Link to={`${CustomPath.trip_history_detail}/${row.tripId}`}>
          <Button size={'middle'} type="primary">
            <EyeOutlined /> Trip Details
          </Button>
        </Link>
      ),
      width: 150,
      fixed: 'right',
    },
  ];
  const fetchPointsHistories = (pageNumber) => {
    toggleFetchingHistories(true);
    ApiHandler({
      url: Api.promotion_usage_history,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams: {
        size: pageSize,
        page: pageNumber ? pageNumber - 1 : historyPage,
        promotionCode: filterData?.searchPromoCode?.trim() ? filterData?.searchPromoCode?.trim() : null,
        tripNumber: filterData?.searchTripNumber?.trim() ? filterData?.searchTripNumber?.trim() : null,
        driverName: filterData?.searchDriverName?.trim() ? filterData?.searchDriverName?.trim() : null,
        passengerName: filterData?.searchPassengerName?.trim() ? filterData?.searchPassengerName?.trim() : null,
        passengerPhoneNumber: filterData?.searchPassengerPhoneNumber?.trim()
          ? filterData?.searchPassengerPhoneNumber?.trim()
          : null,
        fromDateTime: filterData?.fromDateTime || null,
        toDateTime: filterData?.toDateTime || null,
      },
    })
      .then((response) => {
        setHistoryPage(pageNumber ? pageNumber - 1 : historyPage);
        setHistoryTotalPagination(response?.totalElements);
        setHistories(response?.content);
      })
      .catch(() => {})
      .finally(() => {
        toggleFetchingHistories();
      });
  };

  const onChangeDate = (value) => {
    if (value && value.length === 2) {
      const fromDateTime = dayjs(value[0])?.format('YYYY-MM-DD HH:mm');
      const toDateTime = dayjs(value[1])?.format('YYYY-MM-DD HH:mm');
      setFilterData((prev) => ({
        ...prev,
        fromDateTime,
        toDateTime,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        fromDate: '',
        toDate: '',
      }));
    }
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    fetchPointsHistories();
  }, []);

  const handleHistoryPaginationChange = (driverId, pageNumber) => {
    fetchPointsHistories(driverId, pageNumber);
  };
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Promotion Usage History'} />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Promotion Code"
            onChange={(e) => onChangeFilter('searchPromoCode', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Trip Number"
            onChange={(e) => onChangeFilter('searchTripNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Driver Name Or Driver ID"
            onChange={(e) => onChangeFilter('searchDriverName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Passenger Name"
            onChange={(e) => onChangeFilter('searchPassengerName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            allowClear
            placeholder="Passenger Phone Number"
            onChange={(e) => onChangeFilter('searchPassengerPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={12}>
          <NJVRangePicker
            bgcolor="#f1f1f1"
            onChange={onChangeDate}
            showTime={{
              format: 'HH:mm',
            }}
            format="YYYY-MM-DD HH:mm"
            allowClear="true"
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={() => fetchPointsHistories()}>
            Search
          </NJVButton>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          {isFetchingHistories ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={pointsHistoryColumns}
              dataSource={histories}
              pagination={{
                position: 'top' | 'bottom',
                total: historyTotalPagination,
                current: historyPage + 1,
                onChange: (pageNumber) => {
                  handleHistoryPaginationChange(pageNumber);
                },
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
              scroll={{
                x: 1600,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default PromotionUsageHistory;
