import { EyeFilled } from '@ant-design/icons';
import {
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Flex,
  List,
  message,
  Modal,
  Rate,
  Row,
  Skeleton,
  Space,
  Steps,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NJVButton } from '../../component/core-component';
import { SvgMarkerFilled } from '../../component/custom-svg-icon';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import { convertSecondsToTime } from './trip-history';
import RouteMap from './trip-history-route-map';

const { Text } = Typography;

const TRIP_TYPE_MAP = {
  FIXED_TRIP: 'Fixed Trip',
  KILO_TRIP: 'Kilo Trip',
};

export const cardStyle = {
  padding: '18px 18px 10px 18px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  backgroundColor: '#fafafa',
};

export const labelStyle = {
  fontSize: '15px',
  fontWeight: '500',
  width: '50%',
  display: 'flex',
  alignItems: 'flex-start',
};

export const DetailDescription = ({ label, layout, title }) => (
  <Descriptions
    title={title}
    colon={false}
    items={label}
    column={1}
    size="large"
    layout={layout}
    style={cardStyle}
    labelStyle={labelStyle}
    contentStyle={{
      fontSize: '16px',
      color: '#424242',
      width: '50%',
      fontWeight: '600',
      display: 'flex',
      justifyContent: 'flex-end',
    }}
  />
);

const StepTitileStyle = ({ data, date }) => {
  return (
    <>
      <div style={{ width: '100%', marginBottom: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
          <span style={{ fontWeight: '600' }}>{data?.tripStatus}</span>
          <div style={{ fontSize: 14, fontWeight: '500' }}>{date}</div>
        </div>
        {data?.description && <div style={{ color: 'gray', fontWeight: '600' }}>{data?.description}</div>}
        {data?.tripStatus && (data?.tripStatus === 'Refused' || data?.tripStatus === 'Cancelled') && (
          <div>{data?.userDTO?.fullName}</div>
        )}

        {data?.reason && <span style={{ color: 'gray', fontWeight: '500' }}>Reason : {data?.reason}</span>}
      </div>
    </>
  );
};

const CustomFeesStyle = ({ label, content, type }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: 15 }}>
      <div style={{ ...labelStyle, color: 'gray' }}>{label}</div>
      <div style={{ fontWeight: '600' }}>
        {type === '+' && <div style={{ color: 'green' }}>+ {content}</div>} {/* only for fees */}
        {type === '-' && <div style={{ color: 'red' }}>- {content}</div>} {/* only for fees */}
        {type === '=' && <div>{content}</div>}
      </div>
    </div>
  );
};

const TripHistoryDetail = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventStep, setEventStep] = useState([]);
  const [driver, setDriver] = useState([]);
  const [passenger, setPassenger] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [travelRouteCoordinates, setTravelRouteCoordinates] = useState([]);
  const [pickUpRouteCoordinates, setPickUpRouteCoordinates] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [complaints, setComplaints] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [passengerAvatar, setPassengerAvatar] = useState([]);
  const [driverAvatar, setDriverAvatar] = useState([]);

  useEffect(() => {
    Promise.all([findTripHistory(), findTripEvent(), findChatRoom(), findComplaints()]);
  }, []);

  const findTripHistory = async () => {
    setLoading(true);
    await ApiHandler({
      url: Api.trip_history,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setData(response);
        console.log('res:', response);
        setTravelRouteCoordinates(response?.travelCoordinateList);
        setPickUpRouteCoordinates(response?.coordinateList);
        setLoading(false);
        if (response?.passengerDTO) {
          setPassenger(response?.passengerDTO);
          setPassengerAvatar(
            response?.passengerDTO?.profileUrl ? (
              <Avatar src={response?.passengerDTO.profileUrl} />
            ) : (
              <Avatar>{response?.passengerDTO?.fullName[0]}</Avatar>
            ),
          );
        }
        if (response?.driverDTO) {
          setDriver(response?.driverDTO);
          setDriverAvatar(
            response?.driverDTO?.profileUrl ? (
              <Avatar src={response?.driverDTO.profileUrl} />
            ) : (
              <Avatar>{response?.driverDTO?.fullName[0]}</Avatar>
            ),
          );
        }
      })
      .catch(() => setLoading(false));
  };

  const findTripEvent = () => {
    ApiHandler({
      url: Api.trip_history_events,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        const temp = [];
        response?.forEach((event, index) => {
          let formatedEventTime = '';
          if (event.createdDate) {
            const eventTime = dayjs(event.createdDate, 'YYYY-MM-DD HH:mm:ss');
            if (eventTime) {
              formatedEventTime = eventTime.format('YYYY-MMM-DD hh:mm A');
            }
          }
          temp.push({
            key: index + 1,
            title: <StepTitileStyle data={event} date={formatedEventTime} />,
          });
        });
        setEventData(response);
        setEventStep(temp);
      })
      .catch(() => setLoading(false));
  };

  const findChatRoom = () => {
    ApiHandler({
      url: Api.chat_messages_by_trip_id,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setChatMessages(response);
      })
      .catch(() => setLoading(false));
  };

  const findComplaints = () => {
    ApiHandler({
      url: Api.trip_complaints_by_id,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        setComplaints(response);
      })
      .catch(() => setLoading(false));
  };

  const completeTrip = () => {
    ApiHandler({
      url: `/api/v1/trip/complete?tripId=${id}&reason=driverOffLine`,
      method: HTTP_METHOD.PUT,
    })
      .then(() => {
        findTripHistory();
      })
      .catch(() => setLoading(false));
  };

  const showDrawer = () => {
    setVisible(true);
    // item.profileUrl ? <Avatar src={item.profileUrl} /> : <Avatar>{item.sender[0]}</Avatar>;

    const tempChatData = chatMessages?.map((message) => {
      const messageObject = [];
      messageObject['message'] = message.message;
      //   messageObject['sentTime'] = message?.chatMessagePrimaryKey?.messageTime;
      const eventTime = dayjs(message?.chatMessagePrimaryKey?.messageTime, 'YYYY-MM-DD HH:mm:ss');
      if (eventTime) {
        messageObject['sentTime'] = eventTime.format('YYYY MMM,DD hh:mm A');
      }
      if (message.senderId === passenger?.id) {
        messageObject['sender'] = passenger?.fullName;
        messageObject['userType'] = 'Passenger';
        messageObject['profileUrl'] = passenger?.profileUrl;
      }
      if (message.senderId === driver?.id) {
        messageObject['sender'] = driver?.fullName;
        messageObject['userType'] = 'Driver';
        messageObject['profileUrl'] = driver?.profileUrl;
      }
      return messageObject;
    });

    setChatData(tempChatData);
  };

  const onClose = () => {
    setVisible(false);
  };
  const goBack = () => {
    if (location.state?.from === '/v1/trip-history') {
      navigate('/v1/trip-history');
    } else if (location.state?.from === '/v1/trip-complaint-list') {
      navigate('/v1/trip-complaint-list');
    } else {
      navigate(-1);
    }
  };

  const FareUI = () => {
    return (
      <div style={{ ...cardStyle }}>
        <CustomFeesStyle label="Base Fare" content={`${data?.baseFees} MMK`} type="=" />
        {data.waitingFees !== undefined && data.waitingFees !== 0.0 && (
          <CustomFeesStyle label="Waiting Fare" content={`${data?.waitingFees} MMK`} type="+" />
        )}
        {data.trafficFees !== undefined && data.trafficFees !== 0.0 && (
          <CustomFeesStyle label="Traffic Fare" content={`${data?.trafficFees} MMK`} type="+" />
        )}
        {data?.promotionAmount && (
          <CustomFeesStyle label="Promotion Amount" content={`${data?.promotionAmount} MMK`} type="-" />
        )}
        <Divider />
        <CustomFeesStyle label="Total Fare" content={`${data?.finalFees} MMK`} type="=" />
        <CustomFeesStyle label="Payment Method" content={data?.paymentMethod || 'Cash'} type="=" />
      </div>
    );
  };

  const tripLabel = [
    {
      key: '1',
      label: <span>Trip Number </span>,
      children: data?.tripNumber,
    },
    {
      key: '2',
      label: <span>Trip Type</span>,
      children: TRIP_TYPE_MAP[data?.tripType],
    },
    {
      key: '3',
      label: <span>Trip Status</span>,
      children: data?.tripStatus,
    },
    {
      key: '4',
      label: <span>Created Time</span>,
      children: data?.createdDate && <span>{dayjs(data?.createdDate).format('YYYY-MM-DD hh:mm A')}</span>,
    },
  ];
  const driverLabel = [
    {
      key: '1',
      label: <span>Driver Name</span>,
      children: data?.driverDTO?.fullName,
    },
    {
      key: '2',
      label: <span>Employee Id</span>,
      children: data?.driverDTO?.employeeId,
    },
    {
      key: '3',
      label: <span>Driver Phone Number</span>,
      children: data?.driverDTO?.phoneNumber,
    },
    {
      key: '2',
      label: <span>Vehicle License Plate Number</span>,
      children: data?.vehicleDTO?.licenseNumber,
    },
  ];

  const passengerLabel = [
    {
      key: '1',
      label: <span>Passenger Name</span>,
      children: data?.passengerDTO?.fullName,
    },
    {
      key: '2',
      label: <span>Passenger Phone Number</span>,
      children: data?.passengerDTO?.phoneNumber,
    },
  ];

  const LocationLabel = () => (
    <div style={cardStyle}>
      <div style={cardStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SvgMarkerFilled width={25} height={25} color={'#52c41a'} />
          <div style={{ marginLeft: 15, width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div style={{ ...labelStyle, color: Theme.colors.gray }}>Pick-Up Location</div>
              <div>{data?.tripPickUpLocation?.address}</div>
            </Space>
          </div>
        </div>
      </div>
      <div style={{ height: 30, borderLeft: '4px dotted gray', marginLeft: 50 }} />
      <div style={cardStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SvgMarkerFilled width={25} height={25} color={'#f5222d'} />
          <div style={{ marginLeft: 15, width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div style={{ ...labelStyle, color: Theme.colors.gray }}>Drop-Off Location</div>
              <div style={{}}>{data?.tripDropOffLocation?.address}</div>
            </Space>
          </div>
        </div>
      </div>
      <Button
        icon={<EyeFilled />}
        type="primary"
        onClick={() => {
          if (travelRouteCoordinates && travelRouteCoordinates.length > 1) {
            setShowModal(true);
          } else {
            message.error('There is no route to show');
          }
        }}
        style={{ marginTop: 20 }}
      >
        View On Map
      </Button>
    </div>
  );
  const DistanceLabel = () => {
    return (
      <div style={{ ...cardStyle }}>
        {data?.tripType === 'FIXED_TRIP' && (
          <>
            <CustomFeesStyle
              label="Estimated Duration"
              content={`${convertSecondsToTime(data?.estimatedTime)}`}
              type="="
            />
            <CustomFeesStyle label="Estimated Distance" content={`${data?.estimatedDistance} km`} type="=" />
            <Divider />
          </>
        )}
        {data?.waitingDuration != 0 && (
          <CustomFeesStyle
            label="Waiting Duration"
            content={`${convertSecondsToTime(data?.waitingDuration || 0)} `}
            type="="
          />
        )}
        <CustomFeesStyle label="Trip Duration" content={`${convertSecondsToTime(data?.tripDuration)}`} type="=" />
        <CustomFeesStyle label="Trip Distance" content={`${data?.distanceTravelled || 0} km`} type="=" />
      </div>
    );
  };
  const ratingLabel = [
    {
      key: '1',
      label: 'Rating',
      // children: data?.rating ? data?.rating : 'N/A',
      children: data?.rating ? (
        <Flex gap="middle" vertical>
          <Rate disabled value={data?.rating} />
        </Flex>
      ) : (
        'N/A'
      ),
    },
    {
      key: '2',
      label: 'Feedback',
      children: data?.feedback ? data?.feedback : 'N/A',
    },
    data?.tripType === 'FIXED_TRIP' && {
      key: '2',
      label: (
        <div>
          <Button icon={<EyeFilled />} type="primary" onClick={showDrawer}>
            View Chat History
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {travelRouteCoordinates?.length > 0 ? (
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          footer={null}
          centered
          width={800}
          styles={{
            body: { height: '700px', padding: 0, borderRadius: 0, overflow: 'hidden' },
            content: { padding: 0, borderRadius: 0, overflow: 'hidden' },
          }}
          modalStyle={{ borderRadius: '0' }}
        >
          <RouteMap travelRouteCoordinates={travelRouteCoordinates} pickUpRouteCoordinates={pickUpRouteCoordinates} />
        </Modal>
      ) : (
        <>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            footer={null}
          >
            <span style={{ color: Theme.colors.red }}>There is no travel Route</span>
          </Modal>
        </>
      )}
      <Row gutter={[20, 20]}>
        <Drawer title="Chat History" placement="right" onClose={onClose} open={visible} width={400}>
          <List
            itemLayout="horizontal"
            dataSource={chatData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.userType === 'Passenger' ? passengerAvatar : driverAvatar}
                  title={
                    <div style={{ display: 'flex' }}>
                      <Text strong>{item.sender}</Text>
                      <div style={{ display: 'flex', marginLeft: 10, alignItems: 'center' }}>
                        <div
                          style={{ width: 5, height: 5, borderRadius: 10, backgroundColor: 'gray', marginRight: 5 }}
                        ></div>
                        <span style={{ color: 'gray', fontSize: 12 }}>{item.userType}</span>
                      </div>
                    </div>
                  }
                  description={
                    <>
                      <Text>{item.message}</Text>
                      <br />
                      <Text type="secondary" style={{ fontSize: 13, fontWeight: '500' }}>
                        {item.sentTime}
                      </Text>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Drawer>
        <Col span={5}></Col>
        <Col span={10}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.trip_history}>Trip History</Link>,
              },
              {
                title: 'Detail',
              },
            ]}
          />
        </Col>
        <Col span={4} style={{ textAlign: 'end' }}>
          <Button type="primary" onClick={goBack} ontype="primary">
            Back
          </Button>
        </Col>

        <Col span={5}></Col>
        <Col span={5} />
        <Col span={14}>
          {loading ? (
            <Skeleton />
          ) : (
            <Row gutter={[20, 20]}>
              {data?.tripState !== 'Ongoing' ? (
                <Col span={24}>
                  {data?.tripState === 'Completed' && <Alert message={data?.tripState} type="success" />}
                  {data?.tripState === 'Cancelled' && <Alert message={data?.tripState} type="error" />}
                  {data?.tripState === 'Pending' && <Alert message={data?.tripState} type="info" />}
                </Col>
              ) : (
                <>
                  <Col span={12}>
                    {data?.tripState === 'Ongoing' && <Alert message={data?.tripState} type="info" />}
                  </Col>
                  <Col span={12} style={{ textAlign: 'end' }}>
                    <NJVButton
                      onClick={completeTrip}
                      type="primay"
                      style={{ backgroundColor: '#53db8e', color: '#fff' }}
                    >
                      Complete Trip
                    </NJVButton>
                  </Col>
                </>
              )}
              <Col span={24}>
                <DetailDescription label={tripLabel} />
              </Col>
              <Col span={24}>
                {data?.driverDTO ? (
                  <DetailDescription label={driverLabel} />
                ) : (
                  <div style={{ ...cardStyle, paddingBottom: 18, textAlign: 'center' }}>No Driver Found</div>
                )}
              </Col>

              <Col span={24}>
                <DetailDescription label={passengerLabel} />
              </Col>
              <Col span={24}>
                <LocationLabel />
              </Col>
              <Col span={24}>
                <DistanceLabel />
                {/* <DetailDescription label={distanceLabel} /> */}
              </Col>
              <Col span={24}>
                {/* <DetailDescription label={pricingLabel} /> */}
                <FareUI />
              </Col>
              <Col span={24}>
                <DetailDescription label={ratingLabel} />
              </Col>
              <Col span={24}>
                <div style={cardStyle}>
                  <div style={{ ...labelStyle, marginBottom: 20 }}>Trip Event</div>
                  <Steps
                    current={eventData?.length + 1}
                    progressDot
                    className="step-full-width-title"
                    size="middle"
                    direction="vertical"
                    items={eventStep}
                  />
                </div>
              </Col>
              <Col span={24}>
                {complaints.length > 0 ? (
                  <div title="Complaints" style={cardStyle}>
                    <div style={{ ...labelStyle, marginBottom: 20 }}>Trip Complaints</div>
                    <List
                      itemLayout="horizontal"
                      dataSource={complaints}
                      renderItem={(item, index) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Badge count={index + 1} color={Theme.colors.red} />}
                            description={
                              <>
                                <div style={{ color: '#111', fontSize: '16px' }}>{item.complaint}</div>
                                <div>
                                  <span style={{ fontWeight: [500] }}>Trip Category : </span>
                                  {item.tripComplaintType}
                                </div>
                              </>
                            }
                          />
                          <div style={{ fontSize: 14, fontWeight: '500' }}>
                            {dayjs(item.createdDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MMM-DD hh:mm A')}
                          </div>
                        </List.Item>
                      )}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          )}
        </Col>

        <Col span={5} />
      </Row>
    </>
  );
};

export default TripHistoryDetail;
