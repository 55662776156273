import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Button, Col, Row } from 'antd';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import '../../App.css';
import Api from '../../network/api';
import { mapBoxAccessToken } from '../../core/constant';

const PopupContent = ({ data }) => (
  <Row>
    <Col span={24}>
      <div style={{ marginTop: 10 }}>
        <span style={{ fontSize: 15, fontWeight: 'bold' }}>Aung Aung</span>
        <br />
        <span style={{ fontSize: 12, fontWeight: 'bold', color: 'gray' }}>4M-9034</span>
      </div>
    </Col>
  </Row>
);
const routeList = [
  [96.201387, 16.894444],
  [96.199714, 16.893586],
  [96.199462, 16.893473],
  [96.197794, 16.892612],
  [96.198209, 16.891843],
  [96.198744, 16.890868],
  [96.198942, 16.890508],
  [96.199436, 16.88961],
  [96.199697, 16.889135],
  [96.19994, 16.888693],
  [96.200738, 16.887241],
  [96.20122, 16.886364],
  [96.201705, 16.885482],
  [96.201963, 16.885007],
  [96.200617, 16.884323],
  [96.200472, 16.884256],
  [96.20075, 16.883754],
  [96.201254, 16.882853],
  [96.201321, 16.882732],
  [96.201942, 16.88161],
  [96.202571, 16.880479],
  [96.202678, 16.880284],
  [96.202956, 16.879768],
  [96.203218, 16.879289],
  [96.203655, 16.878508],
  [96.2037, 16.878429],
  [96.203947, 16.877992],
  [96.204141, 16.877649],
  [96.204326, 16.877317],
  [96.204326, 16.87721],
  [96.204313, 16.877164],
  [96.204295, 16.87713],
  [96.204274, 16.877094],
  [96.204239, 16.877045],
  [96.203738, 16.876832],
  [96.203441, 16.876704],
  [96.203067, 16.876535],
  [96.202706, 16.876346],
  [96.201861, 16.875886],
  [96.201263, 16.875568],
  [96.200485, 16.87515],
  [96.200392, 16.875099],
  [96.198962, 16.874335],
  [96.19845, 16.874062],
  [96.198021, 16.873837],
  [96.197533, 16.873575],
  [96.196693, 16.873124],
  [96.196168, 16.872847],
  [96.195585, 16.87254],
  [96.194892, 16.872166],
  [96.194844, 16.87214],
  [96.194421, 16.871922],
  [96.194366, 16.871892],
  [96.193935, 16.871663],
  [96.193846, 16.871616],
  [96.193737, 16.871558],
  [96.193369, 16.871363],
  [96.19314, 16.871242],
  [96.192867, 16.871098],
  [96.192238, 16.870787],
  [96.191905, 16.870612],
  [96.191446, 16.870365],
  [96.191039, 16.870171],
  [96.190064, 16.869681],
  [96.188626, 16.868966],
  [96.187814, 16.868561],
  [96.1873, 16.868305],
  [96.186757, 16.86803],
  [96.186191, 16.86772],
  [96.185784, 16.867499],
  [96.184377, 16.866746],
  [96.183513, 16.866339],
  [96.183369, 16.866279],
  [96.182635, 16.865978],
  [96.182244, 16.865901],
  [96.182059, 16.86584],
  [96.181871, 16.865783],
  [96.181714, 16.865715],
  [96.181604, 16.865662],
  [96.181406, 16.865492],
  [96.180916, 16.865027],
  [96.180291, 16.864441],
  [96.178924, 16.863123],
  [96.178534, 16.862736],
  [96.178058, 16.862206],
  [96.177572, 16.861656],
  [96.177397, 16.861332],
  [96.177222, 16.861038],
  [96.176952, 16.86061],
  [96.176715, 16.860253],
  [96.176326, 16.859733],
  [96.176168, 16.85953],
  [96.176034, 16.859378],
  [96.175402, 16.858731],
  [96.175367, 16.858696],
  [96.175249, 16.858597],
  [96.175151, 16.858514],
  [96.174261, 16.85777],
  [96.174113, 16.857646],
  [96.173223, 16.856956],
  [96.172125, 16.856207],
  [96.171031, 16.855567],
  [96.170583, 16.855317],
  [96.170286, 16.855151],
  [96.169432, 16.854675],
  [96.169032, 16.85447],
  [96.168872, 16.854378],
  [96.168286, 16.854051],
  [96.16767, 16.853726],
  [96.167287, 16.853516],
  [96.167024, 16.853357],
  [96.166844, 16.853262],
  [96.166741, 16.853204],
  [96.166568, 16.853108],
  [96.166454, 16.853044],
  [96.1662, 16.852893],
  [96.164329, 16.851823],
  [96.164012, 16.851655],
  [96.163566, 16.851468],
  [96.163066, 16.851227],
  [96.162745, 16.851085],
  [96.162121, 16.85085],
  [96.16129, 16.850585],
  [96.16041, 16.850343],
  [96.160142, 16.850299],
  [96.159936, 16.850265],
  [96.159549, 16.850226],
  [96.159007, 16.850188],
  [96.157948, 16.850129],
  [96.157672, 16.85012],
  [96.157553, 16.850112],
  [96.157513, 16.849909],
  [96.157488, 16.84976],
  [96.157458, 16.849558],
  [96.157417, 16.849315],
  [96.157179, 16.84763],
  [96.157079, 16.846901],
  [96.156992, 16.846231],
  [96.156896, 16.845552],
  [96.156871, 16.845373],
  [96.156493, 16.842686],
  [96.156477, 16.842568],
  [96.156285, 16.841377],
  [96.156064, 16.839806],
  [96.155889, 16.838567],
  [96.155834, 16.83818],
  [96.155823, 16.838107],
  [96.15571, 16.837282],
  [96.155617, 16.836628],
  [96.155595, 16.836479],
  [96.15548, 16.835752],
  [96.155422, 16.835335],
  [96.155306, 16.834552],
  [96.155057, 16.832988],
  [96.154978, 16.832441],
  [96.154872, 16.831728],
  [96.154859, 16.831641],
  [96.154821, 16.831383],
  [96.154786, 16.831234],
  [96.154714, 16.830676],
  [96.154553, 16.829281],
  [96.154535, 16.829131],
  [96.154518, 16.828992],
  [96.15449, 16.828778],
  [96.154328, 16.827594],
  [96.154153, 16.826386],
  [96.154182, 16.826286],
  [96.154144, 16.825971],
  [96.154115, 16.825745],
  [96.15391, 16.824374],
  [96.153867, 16.824018],
  [96.153866, 16.823608],
  [96.153888, 16.823401],
  [96.15393, 16.823185],
  [96.154103, 16.822648],
  [96.154246, 16.822325],
  [96.154366, 16.8221],
  [96.154397, 16.821994],
  [96.154625, 16.821625],
  [96.154982, 16.821034],
  [96.154587, 16.820935],
];

const DriverLocationMap = () => {
  const mapContainerRef = useRef();
  const markerRef = useRef();
  const mapRef = useRef();
  const allMarkerRef = useRef();
  const [socketConnected, setSocketConnected] = useState(false);
  let stompClient = null;

  const [markers, setMarkers] = useState([
    { id: 1, coordinates: [96.19168495161757, 16.916553739817513], markerInstance: null, state: 'IDEL' },
    { id: 2, coordinates: [96.201387, 16.894444], markerInstance: null, state: 'ON_PROGRESS' },
    { id: 3, coordinates: [96.15185951335859, 16.842634182435095], markerInstance: null, state: 'SEARCHING' },
  ]);
  const [index, setIndex] = useState(0);

  const connectSocket = () => {
    try {
      const Sock = new SockJS(Api.driver_location_host + '/ws');
      stompClient = over(Sock);

      stompClient.connect({}, () => {
        setSocketConnected(true);
        console.log('Connected to WebSocket');

        stompClient.subscribe('/driver/locations', (message) => {
          const data = JSON.parse(message.body);
          const newLocation = { lat: data.lat, lng: data.lng };

          const now = Date.now();
        });
      });
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
    }
  };

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, []);

  useEffect(() => {
    mapboxgl.accessToken = mapBoxAccessToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [96.19168495161757, 16.916553739817513],
      zoom: 14,
    });

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  const addAllMarkers = () => {
    if (mapRef.current) {
      const newMarkers = markers.map((markerData) => {
        if (markerData.markerInstance) {
          markerData.markerInstance.setLngLat(markerData.coordinates);
        } else {
          const el = document.createElement('div');
          el.style.width = '30px';
          el.style.height = '58px';

          el.style.backgroundSize = 'contain';
          el.style.backgroundRepeat = 'no-repeat';
          el.style.transformOrigin = 'center';
          if (markerData.state === 'ON_PROGRESS') {
            el.style.backgroundImage = `url(/images/marker/green-car.png)`;
          } else if (markerData.state === 'IDEL') {
            el.style.backgroundImage = `url(/images/marker/red-car.png)`;
          } else if (markerData.state === 'SEARCHING') {
            el.style.backgroundImage = `url(/images/marker/gray-car.png)`;
          }
          const popupNode = document.createElement('div');
          const root = ReactDOM.createRoot(popupNode);
          root.render(<PopupContent data={'Hello'} />);
          const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupNode);
          const markerInstance = new mapboxgl.Marker({ element: el })
            .setLngLat(markerData.coordinates)
            .setPopup(popup)
            .addTo(mapRef.current);
          markerInstance.setRotation(45);

          return { ...markerData, markerInstance };
        }
        return markerData;
      });
      setMarkers(newMarkers);
    }
  };
  const moveMarker = () => {
    routeList.forEach((item) => {
      updateMarker(item);
    });
  };
  //   useEffect(() => {
  //     if (index < routeList.length) {
  //       const timer = setTimeout(() => {
  //         console.log('Updating marker:', routeList[index]);
  //         moveMarker(routeList[index]);
  //         setIndex((prevIndex) => prevIndex + 1);
  //       }, 500); // 5-second delay

  //       // Cleanup the timer if the component unmounts
  //       return () => clearTimeout(timer);
  //     }
  //   }, [index]);

  const updateMarker = (item) => {
    const id = 2;
    let newMarker;
    markers.forEach((marker) => {
      if (marker.id == 2) {
        newMarker = marker;
      }
    });
    if (newMarker.markerInstance) {
      newMarker.markerInstance.setLngLat(item);
      newMarker['coordinates'] = item;
    }
    setMarkers((prevMarkers) => prevMarkers.map((marker) => (marker.id === 2 ? newMarker : marker)));
  };

  const moveMarkerWithAnimation = (item) => {
    const id = 2;
    const markerData = markers.find((marker) => marker.id === id);
    if (markerData && markerData.markerInstance) {
      animateMarker(markerData.markerInstance, markerData.coordinates, item);
      setMarkers((prevMarkers) =>
        prevMarkers.map((marker) => (marker.id === id ? { ...marker, coordinates: item } : marker)),
      );
    }
  };
  const animateMarker = (markerInstance, startCoordinates, endCoordinates) => {
    const startTime = performance.now();
    const duration = 1000;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress doesn't exceed 1

      const newLng = startCoordinates[0] + (endCoordinates[0] - startCoordinates[0]) * progress;
      const newLat = startCoordinates[1] + (endCoordinates[1] - startCoordinates[1]) * progress;

      markerInstance.setLngLat([newLng, newLat]);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  };

  const addMarker = (data, coordinate) => {
    if (markerRef.current) {
      markerRef.current.remove();
    }
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.width = '50px';
    el.style.height = '50px';
    el.style.backgroundSize = '100%';

    const popupNode = document.createElement('div');
    const root = ReactDOM.createRoot(popupNode);
    root.render(<PopupContent data={data} />);

    const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupNode);
    markerRef.current = new mapboxgl.Marker({ element: el, draggable: false })
      .setLngLat(coordinate)
      .setPopup(popup)
      .addTo(mapRef.current);
    popup.addTo(mapRef.current);
    mapRef.current.flyTo({ center: coordinate, essential: true, zoom: 14 });
  };
  return (
    <>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100vh', borderRadius: 5 }}></div>
      <div style={{ position: 'fixed', top: 20, left: 20 }}>
        <Button type="primary" onClick={() => addAllMarkers()}>
          Add All Mareker
        </Button>
        <Button type="primary" onClick={() => moveMarker()}>
          Move marker
        </Button>
      </div>
    </>
  );
};

export default DriverLocationMap;
