import { Button, Col, Form, message, Modal, Row, Select, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { NJVAddButton, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import { useToggle } from 'react-use';
import { ValidateFromRangeToRange } from '../../core/validator';
import { EditableCell } from './distance-fare';

const BaseFare = () => {
  const type = '';
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [count, setCount] = useState(0);
  const [newRowKey, setNewRowKey] = useState(null);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [selectedId, setSelectedId] = useState();
  const [zoneList, setZoneList] = useState();

  const fetchData = useCallback(() => {
    const requestParams = {};
    ApiHandler({
      url: Api.base_fare_rule_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setData(response?.map((c) => ({ ...c, key: c.id })));
      })
      .catch()
      .finally(() => setDataFetching(false));
  }, []);

  const fetchZoneData = useCallback(() => {
    const requestParams = {};
    ApiHandler({
      url: Api.zone_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setZoneList(response?.content?.map((c) => ({ ...c, key: c.id })));
      })
      .catch()
      .finally(() => setDataFetching(false));
  }, []);

  useEffect(() => {
    setDataFetching(true);
    fetchZoneData();
    fetchData();
  }, [fetchData, fetchZoneData]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.key);
    setNewRowKey(false);
  };

  const cancel = (key) => {
    if (newRowKey) {
      const newData = data.filter((item) => item.key !== key);
      setData(newData);
      setEditingKey('');
    } else {
      setEditingKey('');
    }
  };

  const handleCreate = () => {
    const newKey = count.toString();
    const newRow = {
      key: newKey,
    };
    form.setFieldsValue({ key: newRow.key, fromRange: '', toRange: '', farePrice: '' });
    setData([...data, newRow]);
    setCount(count + 1);
    setEditingKey(newKey);
    setNewRowKey(true);
  };

  const EditSave = (row) => {};

  const save = async (formData) => {
    const requestData = form.getFieldsValue();
    toggleSubmitting();
    ApiHandler({
      url: Api.base_fare_rule,
      method: formData.id ? HTTP_METHOD.PUT : HTTP_METHOD.POST,
      MediaType: MEDIA_TYPE.JSON,
      requestData: { ...formData, ...requestData },
      disableShowMessage: true,
    })
      .then(() => {
        fetchData();
        setEditingKey('');
        form.setFieldsValue({ key: formData.key, fromRange: '', toRange: '', farePrice: '' });
      })
      .catch(() => {})
      .finally(toggleSubmitting);
  };

  const defaultColumns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{index + 1}</span>,
      width: 5,
    },
    {
      title: 'Zone Name',
      dataIndex: 'zoneId',
      key: 'zoneId',
      width: '10%',
      editable: true,
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <td>
            <Form.Item
              name="zoneId"
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Zone is required',
                },
              ]}
            >
              <Select onChange={() => console.log()} style={{ width: 150 }}>
                {zoneList.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </td>
        ) : (
          <>
            <span style={{ marginRight: 10 }}>{record.zoneName}</span>
          </>
        );
      },
    },
    {
      title: 'Charges',
      dataIndex: 'farePrice',
      key: 'farePrice',
      editable: true,
      render: (charge) => (
        <>
          {charge}
          <span style={{ marginLeft: '4px' }}>MMK</span>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="primary" style={{ marginRight: 8 }} loading={submitting} onClick={() => save(record)}>
              Save
            </Button>
            <Button title="Sure to cancel?" onClick={() => cancel(record.key)}>
              Cancel
            </Button>
          </span>
        ) : (
          <span>
            <Button type="primary" disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }}>
              Edit
            </Button>
            <Button
              size={'middle'}
              type="primary"
              danger
              onClick={() => {
                setSelectedId(record.id);
                toggleModelOpen();
              }}
            >
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  const deleteRow = (id) => {
    ApiHandler({
      url: Api.base_fare_rule_delete,
      method: HTTP_METHOD.POST,
      requestData: {
        id,
      },
    }).finally(() => {
      toggleModelOpen(false);
      fetchData();
    });
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable || col.dataIndex !== 'farePrice') {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        EditSave,
      }),
    };
  });

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Base Fare'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NJVAddButton type={type} handleClick={handleCreate} />
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <Form form={form} component={false}>
              <NJVTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                pagination={false}
              />
            </Form>
          )}
        </Col>
      </Row>
      <Modal
        title="Delete Base Fare Rule"
        open={isModelOpen}
        onOk={() => deleteRow(selectedId)}
        okType="danger"
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to delete?</p>
      </Modal>
    </>
  );
};
export default BaseFare;
