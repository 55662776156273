import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NJVAddButton, NJVButton, NJVEditButton, NJVInput, TitleLevel3 } from '../../component/core-component';
import CoreTableComponent from '../../component/core-table-component';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const CORE_CONFIG_TYPE_MAP = {
  APP_FORCE_UPDATE: 'App Force Update',
  APP_FORCE_CREATE: 'App Force Create',
};

function CoreConfig() {
  const type = 'CORE_CONFIG';

  const [filterData, setFilterData] = useState();
  const [inputData, setInputData] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [configId, setConfigId] = useState();
  const [refreshKey, setRefreshKey] = useState(0);

  const [updateDataTemp, setUpdateDataTemp] = useState(null);

  const [openCreateFormDrawer, setOpenCreateFormDrawer] = useState(false);
  const [openUpdateFormDrawer, setOpenUpdateFormDrawer] = useState(false);

  const closeCreateFormDrawer = () => {
    setOpenCreateFormDrawer(false);
  };
  const closeUpdateFormDrawer = () => {
    setOpenUpdateFormDrawer(false);
  };

  const handleSearch = () => {
    const requestParams = {
      driverName: inputData?.searchDriverName?.trim() ? inputData?.searchDriverName?.trim() : '',
      phoneNumber: inputData?.searchPhoneNumber?.trim() ? inputData?.searchPhoneNumber?.trim() : '',
      incentiveDate: inputData?.searchDate ? dayjs(inputData.searchDate).format('YYYY-MM-DD') : '',
    };
    setFilterData(requestParams);
  };

  const onChangeFilter = (key, value) => {
    setInputData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // useEffect(() => {
  //   if (openCreateFormDrawer || openUpdateFormDrawer) return;
  //   fetchData();
  // }, [openCreateFormDrawer, openUpdateFormDrawer]);

  const deleteCoreConfig = (id) => {
    setConfigId(id);
    setOpenModal(true);
  };

  const deleteCoreConfigConfirm = () => {
    ApiHandler({
      url: Api.core_config,
      method: HTTP_METHOD.DELETE,
      specificId: configId,
    })
      .then((response) => {
        setOpenModal(false);
        setRefreshKey((prev) => prev + 1);
      })
      .catch();
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
    },
    {
      title: 'Content',
      dataIndex: 'content',
    },
    {
      title: 'Type',
      dataIndex: '',
      render: (core) => <>{CORE_CONFIG_TYPE_MAP[core?.coreConfigType]}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      textAlign: 'center',
      width: '10%',
      render: (core) => (
        <Space>
          <Link to={`${CustomPath.core_config_update}/${core.id}`}>
            <NJVEditButton type={type} />
          </Link>
          <Button onClick={() => deleteCoreConfig(core.id)} size={'middle'} type="primary" danger shape="circle">
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  // // eslint-disable-next-line react/display-name
  // const CreateFormDrawer = memo(() => {
  //   const [createLoading, setCreateLoading] = useState(false);
  //   const createRegion = (formData) => {
  //     setCreateLoading(true);
  //     const requestData = {
  //       regionName: formData.regionName,
  //     };
  //     ApiHandler({
  //       url: Api.region,
  //       method: HTTP_METHOD.POST,
  //       mediaType: MEDIA_TYPE.JSON,
  //       requestData,
  //     })
  //       .then((response) => {
  //         // fetchData();
  //       })
  //       .catch((error) => {})
  //       .finally(() => {
  //         setCreateLoading(false);
  //         closeCreateFormDrawer();
  //       });
  //   };
  //   return (
  //     <Drawer
  //       title="Create a new region"
  //       width={500}
  //       onClose={closeCreateFormDrawer}
  //       open={openCreateFormDrawer}
  //       styles={{
  //         body: {
  //           paddingBottom: 80,
  //         },
  //       }}
  //     >
  //       <Form layout="vertical" onFinish={createRegion}>
  //         <Row gutter={16}>
  //           <Col span={24}>
  //             <Form.Item name="regionName" label={'Region Name'} rules={[{ required: true, message: '*(requried)' }]}>
  //               <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter region name'} />
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item>
  //               <NJVButton type="primary" htmlType="submit" loading={createLoading}>
  //                 Create
  //               </NJVButton>
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //       </Form>
  //     </Drawer>
  //   );
  // }, []);

  // // eslint-disable-next-line react/display-name
  // const UpdateFormDrawer = memo(() => {
  //   const [updateLoading, setUpdateLoading] = useState(false);
  //   const updateRegion = (formData) => {
  //     setUpdateLoading(true);
  //     const requestData = {
  //       id: updateDataTemp.id,
  //       regionName: formData.regionName,
  //       startTime: formData.startTime.format('HH:mm'),
  //       endTime: formData.endTime.format('HH:mm'),
  //     };
  //     ApiHandler({
  //       url: Api.region,
  //       method: HTTP_METHOD.PUT,
  //       mediaType: MEDIA_TYPE.JSON,
  //       requestData,
  //     })
  //       .then((response) => {
  //         setUpdateDataTemp(null);
  //       })
  //       .catch((error) => {})
  //       .finally(() => {
  //         setUpdateLoading(false);
  //         closeUpdateFormDrawer();
  //       });
  //   };
  //   return (
  //     <Drawer
  //       title="Update region"
  //       width={500}
  //       onClose={closeUpdateFormDrawer}
  //       open={openUpdateFormDrawer}
  //       styles={{
  //         body: {
  //           paddingBottom: 80,
  //         },
  //       }}
  //     >
  //       <Form
  //         layout="vertical"
  //         onFinish={updateRegion}
  //         initialValues={{
  //           regionName: updateDataTemp?.regionName,
  //           startTime: updateDataTemp?.startTime,
  //           endTime: updateDataTemp?.endTime,
  //         }}
  //       >
  //         <Row gutter={16}>
  //           <Col span={24}>
  //             <Form.Item name="regionName" label={'Region Name'} rules={[{ required: true, message: '*(requried)' }]}>
  //               <NJVInput value={updateDataTemp?.regionName} bgcolor={'#f1f1f1'} placeholder={'Enter region name'} />
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item>
  //               <NJVButton type="primary" htmlType="submit" loading={updateLoading}>
  //                 Update
  //               </NJVButton>
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //       </Form>
  //     </Drawer>
  //   );
  // }, []);

  return (
    <>
      {/* {openCreateFormDrawer && <CreateFormDrawer />}
      {openUpdateFormDrawer && <UpdateFormDrawer />} */}
      <Modal
        title="Delete"
        open={openModal}
        onOk={() => deleteCoreConfigConfirm()}
        onCancel={() => setOpenModal(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure to delete?</p>
      </Modal>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Core Config'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.core_config_form}>
            <NJVAddButton type={type} handleClick={() => setOpenCreateFormDrawer(true)} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search By Core-Config Type"
            onChange={(e) => onChangeFilter('searchCoreConfigType', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          <CoreTableComponent
            componentColumns={columns}
            url={Api.core_config}
            filterData={filterData}
            refreshKey={refreshKey}
          />
        </Col>
      </Row>
    </>
  );
}
export default CoreConfig;
