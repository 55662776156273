import { Col, Row, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../../component/core-component';
import { Constant } from '../../../core/constant';
import Api from '../../../network/api';
import { HTTP_METHOD } from '../../../network/httpMethod';
import { MEDIA_TYPE } from '../../../network/mediaType';
import { ApiHandler } from '../../../network/network-manager';
import CustomPath from '../../../route/custom-path';

const RolePage = () => {
  const pageSize = Constant.pageSize;
  const type = 'ROLE';

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      name: filterData?.searchFullName?.trim() ? filterData?.searchFullName?.trim() : '',
    };
    ApiHandler({
      url: Api.role_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Name',
      key: 'fullName',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (driver) => (
        <Space>
          <Link to={`${CustomPath.role_update}/${driver.id}`}>
            <NJVEditButton type={type} />
          </Link>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Role'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Link to={CustomPath.role_and_permission_form}>
            <NJVAddButton type={type} />
          </Link>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Name"
            onChange={(e) => onChangeFilter('searchFullName', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default RolePage;

// const CreateFormDrawer = () => (
//   <Drawer
//     title="Create a New Role"
//     width={500}
//     onClose={closeCreateFormDrawer}
//     open={openCreateFormDrawer}
//     styles={{
//       body: {
//         paddingBottom: 80,
//       },
//     }}
//   >
//     <Form layout="vertical" onFinish={createRole}>
//       <Row gutter={16}>
//         <Col span={24}>
//           <Form.Item name="name" label={'Name'} rules={[{ required: true, message: '*(requried)' }]}>
//             <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter name'} />
//           </Form.Item>
//         </Col>
//         <Col span={24}>
//           <Form.Item name="description" label={'Description'} rules={[{ required: true, message: '*(requried)' }]}>
//             <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter description'} />
//           </Form.Item>
//         </Col>
//         <Col span={24}>
//           <Form.Item>
//             <NJVButton type="primary" htmlType="submit" loading={createLoading}>
//               Create
//             </NJVButton>
//           </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//   </Drawer>
// );

// const UpdateFormDrawer = () => (
//   <Drawer
//     title="Update Role"
//     width={500}
//     onClose={closeUpdateFormDrawer}
//     open={openUpdateFormDrawer}
//     styles={{
//       body: {
//         paddingBottom: 80,
//       },
//     }}
//   >
//     <Form layout="vertical" onFinish={updateRole}>
//       <Row gutter={16}>
//         <Col span={24}>
//           <Form.Item name="roleName" label={'Role Name'} rules={[{ required: true, message: '*(requried)' }]}>
//             <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter name'} />
//           </Form.Item>
//         </Col>
//         <Col span={24}>
//           <Form.Item name="description" label={'Description'} rules={[{ required: true, message: '*(requried)' }]}>
//             <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter description'} />
//           </Form.Item>
//         </Col>
//         <Col span={24}>
//           <Form.Item>
//             <NJVButton type="primary" htmlType="submit" loading={updateLoading}>
//               Update
//             </NJVButton>
//           </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//   </Drawer>
