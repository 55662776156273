import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Skeleton, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { getRowClassName, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant, sosStatus } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import { MEDIA_TYPE } from '../../network/mediaType';
import dayjs from 'dayjs';
import { useSosPendingCount } from '../../App';

const SosAlertPage = () => {
  const pageSize = Constant.pageSize;
  const [, setSosPendingCount] = useSosPendingCount();
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [updateData, setUpdateData] = useState({});

  const fetchData = useCallback(
    (pageNumber) => {
      const requestParams = {
        size: pageSize,
        page: pageNumber ? pageNumber - 1 : page,
      };
      ApiHandler({
        url: Api.sos_filter,
        method: HTTP_METHOD.GET,
        MediaType: MEDIA_TYPE.JSON,
        requestParams,
      })
        .then((response) => {
          const content = response.content.map((c) => ({
            ...c,
            assignHub: c.driver?.region?.name,
            createdDate: dayjs(c.createdDate).format('YYYY-MM-DD HH:mm:ss'),
            resolvedTime: c.resolvedTime ? dayjs(c.resolvedTime).format('YYYY-MM-DD HH:mm:ss') : '-',
          }));
          setPage(pageNumber ? pageNumber - 1 : page);
          setTotalPagination(response?.totalElements);
          setData(content);
        })
        .catch((error) => {
          message.error(error);
        })
        .finally(() => toggleDataFetching(false));
    },
    [page, pageSize, toggleDataFetching],
  );

  const closeSosAlert = () => {
    toggleSubmitting();
    ApiHandler({
      url: Api.sos,
      method: HTTP_METHOD.PUT,
      requestData: { id: updateData.id, sosStatus: sosStatus.Resolved },
    })
      .then(() => {
        fetchData();
      })
      .catch((error) => message.error(error))
      .finally(() => {
        toggleSubmitting();
        toggleModelOpen(false);
        countSosPending();
      });
  };

  const countSosPending = () => {
    ApiHandler({
      url: Api.sos_pending_count,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
    })
      .then((res) => {
        setSosPendingCount(res);
      })
      .catch(() => {});
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, [fetchData, toggleDataFetching]);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Driver Name',
      dataIndex: 'driverName',
    },
    {
      title: 'Driver No',
      dataIndex: 'driverNo',
    },
    {
      title: 'Vehicle License Plate Number',
      dataIndex: 'licenseNumber',
    },
    {
      title: 'Alert Date Time',
      dataIndex: 'createdDate',
    },
    {
      title: 'Resolved Date Time',
      dataIndex: 'resolvedTime',
    },
    {
      title: 'Status',
      key: 'sosStatus',
      render: (sos) => (
        <Tag key={sos.id} color={sos.sosStatus === sosStatus.Pending ? '#f50' : '#2db7f5'}>
          {sos.sosStatus}
        </Tag>
      ),
    },
    {
      title: 'Action',
      textAlign: 'center',
      width: 100,
      render: (row) => (
        <Space>
          <Link to={`${CustomPath.sos_map}/${row.id}`}>
            <Button size={'middle'} type="primary">
              <EyeOutlined /> Map
            </Button>
          </Link>
          {row.sosStatus !== 'Resolved' && (
            <Button
              size={'middle'}
              type="primary"
              onClick={() => {
                setUpdateData(row);
                toggleModelOpen();
              }}
            >
              <CloseOutlined /> Close
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <TitleLevel3 label={'SOS Alerts'} />
        </Col>
        <Col span={12} />
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Close SOS Alert"
        open={isModelOpen}
        onOk={() => closeSosAlert()}
        confirmLoading={isSubmitting}
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to close this SOS Alert?</p>
      </Modal>
    </>
  );
};

export default SosAlertPage;
