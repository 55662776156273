import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import Api from '../../network/api';
import { over } from 'stompjs';
import { Button, Form, Input, message, Typography } from 'antd';

const DriverLocationSender = () => {
  const [socketConnected, setSocketConnected] = useState(false);
  const [location, setLocation] = useState({});
  const [stompClient] = useState(over(new SockJS(Api.driver_location_host + '/ws')));

  const connectSocket = () => {
    try {
      stompClient.connect(
        {},
        (frame) => {
          setSocketConnected(true);
          console.log('Connected ', frame);
          message.success('Socket connected');
          stompClient.subscribe('/driver/1/locations', (msg) => {
            console.log('msg:');
            if (msg.body) {
              const newMessage = JSON.parse(msg.body);
              console.log('Received: ', newMessage);
              setLocation(newMessage);
            }
          });
        },
        function (errr) {
          console.log(errr);
          message.error('Socket disconnected');
          setSocketConnected(false);
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sendDriverLocation = (values) => {
    if (stompClient && socketConnected) {
      stompClient.send('/app/saveDriverLocation', {}, JSON.stringify(values));
      console.log('Data sent: ', values);
    } else {
      console.error('WebSocket connection is not established');
    }
  };

  const onReceiveDriverLocation = (payload) => {
    console.log('subscribe => ', payload);
    message.success(payload);
  };

  const sendCurrentLocation = () => {
    const DriverData = {
      driverId: 13,
      lat: 16.865147769618346,
      lng: 96.11969192040488,
      driverName: 'Aung Aung',
      vehicleId: 4,
      tripId: 3,
      tripState: 'Pending',
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          stompClient.send(
            '/app/saveDriverLocation',
            {},
            JSON.stringify({
              driverId: 13,
              lat: 16.86514776961888,
              lng: 96.11969192040488,
              driverName: 'Aung Aung',
              vehicleId: 4,
              tripId: 3,
              tripState: 'Pending',
            }),
          );
        },
        (err) => {
          message.error('Failed to get location info');
        },
      );
    } else {
      message.error('Failed to get location info');
    }
    console.log('Send current location');
  };

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
    // Function to be called every 20 seconds
    // const timerFunction = () => {
    //   sendCurrentLocation();
    // };

    // const intervalId = setInterval(timerFunction, 20000); // 20 seconds = 20000 ms

    // // Cleanup on component unmount or when the interval is no longer needed
    // return () => {
    //   clearInterval(intervalId);
    //   if (stompClient) {
    //     stompClient.disconnect();
    //   }
    // };
  }, []);
  return (
    <>
      <Form
        name="websocket-form"
        onFinish={sendDriverLocation}
        layout="vertical"
        style={{ maxWidth: 400, margin: '20px auto' }}
      >
        <Form.Item
          label="Driver ID"
          name="driverId"
          rules={[{ required: true, message: 'Please input the driver ID!' }]}
        >
          <Input placeholder="Enter Driver ID" />
        </Form.Item>

        <Form.Item
          label="Driver Name"
          name="driverName"
          rules={[{ required: true, message: 'Please input the driver Name!' }]}
        >
          <Input placeholder="Enter Driver Name" />
        </Form.Item>

        <Form.Item label="Latitude" name="lat" rules={[{ required: true, message: 'Please input the latitude!' }]}>
          <Input placeholder="Enter Latitude" />
        </Form.Item>

        <Form.Item label="Longitude" name="lng" rules={[{ required: true, message: 'Please input the longitude!' }]}>
          <Input placeholder="Enter Longitude" />
        </Form.Item>

        <Form.Item
          label="Trip State"
          name="tripStatus"
          rules={[{ required: true, message: 'Please input the trip state!' }]}
        >
          <Input placeholder="Enter Trip State (Pending, In Progress, Completed)" />
        </Form.Item>
        <Form.Item label="RegionId" name="regionId" rules={[{ required: true, message: 'Please input the region!' }]}>
          <Input placeholder="Enter Region" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Send Data
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DriverLocationSender;
