import { Button, Col, Row, Select, Skeleton, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getRowClassName, NJVButton, NJVInput, NJVSelect, NJVTable, TitleLevel3 } from '../../component/core-component';
import Theme from '../../component/theme';
import { AssignType, Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import DriverAssignDrawer from './driver-assign-drawer';

const { Text, Title } = Typography;

function BookingPage() {
  const pageSize = Constant.pageSize;

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [bookingId, setBookingId] = useState();

  const handleSearch = () => {
    fetchData();
  };

  const handleAssign = (bookingId) => {
    setIsOpenDrawer(true);
    setBookingId(bookingId);
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      passengerName: filterData?.searchPassengerName?.trim() ? filterData?.searchPassengerName?.trim() : '',
      phoneNumber: filterData?.searchPassengerPhoneNumber?.trim() ? filterData?.searchPassengerPhoneNumber?.trim() : '',
      assign: filterData?.searchType === 'Assign' ? true : filterData?.searchType === 'Unassign' ? false : '',
      scheduled: true,
    };

    ApiHandler({
      url: Api.trip_history_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onChangeType = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      searchType: key ? key : '',
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Passenger Name',
      key: 'fullName',
      dataIndex: ['passengerDTO', 'fullName'],
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Pickup Address',
      dataIndex: 'tripPickUpLocation',
      key: 'pickupAddress',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (booking) => (
        <Button onClick={() => handleAssign(booking.id)} size={'middle'} type="primary">
          Assign
        </Button>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  return (
    <>
      <DriverAssignDrawer isOpenDrawer={isOpenDrawer} onClose={() => setIsOpenDrawer(false)} bookingId={bookingId} />
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Booking List'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}></Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Passenger Name"
            onChange={(e) => onChangeFilter('searchPassengerName', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by PhoneNumber"
            onChange={(e) => onChangeFilter('searchPassengerPhoneNumber', e.target.value)}
          />
        </Col>
        <Col span={6}>
          <NJVSelect
            bgcolor={Theme.colors.light_gray}
            size="large"
            placeholder="Search by Type"
            onChange={onChangeType}
            style={{ width: '100%' }}
          >
            {Object.entries(AssignType).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </NJVSelect>
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default BookingPage;
