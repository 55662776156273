import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { mapBoxAccessToken } from '../../core/constant';
import Theme from '../../component/theme';
import { Button, Card, Col, Row } from 'antd';
import { EnvironmentFilled, LineOutlined, XFilled } from '@ant-design/icons';
import { SvgMarkerFilled, SvgMarkerRoute } from '../../component/custom-svg-icon';

mapboxgl.accessToken = mapBoxAccessToken;

const RouteMap = ({ travelRouteCoordinates, pickUpRouteCoordinates }) => {
  console.log('travel:', travelRouteCoordinates);
  console.log('pickup:', pickUpRouteCoordinates);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const travelCoordinates = travelRouteCoordinates?.map((route) => {
    return [route.coordinates[1], route.coordinates[0]];
  });

  const pickUpCoordinates = pickUpRouteCoordinates?.map((route) => route.coordinates);

  const centerCoordinate =
    travelCoordinates && travelCoordinates?.length > 1
      ? [
          (travelCoordinates[0][0] + travelCoordinates[travelCoordinates.length - 1][0]) / 2,
          (travelCoordinates[0][1] + travelCoordinates[travelCoordinates.length - 1][1]) / 2,
        ]
      : travelCoordinates[0];

  console.log('travel:', travelCoordinates);

  useEffect(() => {
    if (!travelCoordinates) return;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: centerCoordinate,
      zoom: 15,
    });

    new mapboxgl.Marker({ color: Theme.colors.green }).setLngLat(travelCoordinates[0]).addTo(mapRef.current);

    new mapboxgl.Marker({ color: 'red' })
      .setLngLat(travelCoordinates[travelCoordinates.length - 1])
      .addTo(mapRef.current);
    if (pickUpCoordinates.length > 1) {
      new mapboxgl.Marker({ color: Theme.colors.green }).setLngLat(pickUpCoordinates[0]).addTo(mapRef.current);

      new mapboxgl.Marker({ color: 'red' })
        .setLngLat(pickUpCoordinates[pickUpCoordinates.length - 1])
        .addTo(mapRef.current);
    }

    mapRef.current.on('load', () => {
      mapRef.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: travelCoordinates,
          },
        },
      });

      mapRef.current.addSource('pickup-route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: pickUpCoordinates,
          },
        },
      });

      mapRef.current.addLayer({
        id: 'route-line',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': Theme.colors.default,
          'line-width': 4,
          'icon-image': ['coalesce', ['image', 'myImage'], ['image', 'fallbackImage']],
        },
      });
      mapRef.current.addLayer({
        id: 'pickup-route-line',
        type: 'line',
        source: 'pickup-route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': Theme.colors.red,
          'line-width': 4,
        },
      });
    });

    return () => mapRef.current?.remove();
  }, []);
  return (
    <>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
      <div
        style={{
          width: 180,
          margin: '10px 0px 0px 10px',
          backgroundColor: 'white',
          position: 'fixed',
          padding: '8px',
          borderRadius: '10px',
        }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 4 }}>
                <SvgMarkerRoute width={20} height={20} color={'#52c41a'} />
              </span>
              PickUp Location
            </div>
          </Col>
          <Col span={24}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 4 }}>
                <SvgMarkerRoute width={20} height={20} color={Theme.colors.red} />
              </span>
              DropOff Location
            </div>
          </Col>

          <Col span={24} style={{ display: 'flex', padding: '1px 0 1px 6px' }}>
            <div
              style={{ backgroundColor: Theme.colors.default, width: 18, height: 10, marginTop: 6, marginRight: 5 }}
            ></div>
            Actual Travelled Route
          </Col>
          <Col span={24} style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: Theme.colors.red, width: 18, height: 10, marginTop: 6, marginRight: 5 }}
            ></div>
            PickUp Route
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RouteMap;
