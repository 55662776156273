import { Button, Card, Col, Drawer, InputNumber, Modal, Row, Skeleton, Space, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useEffect, useState } from 'react';
import { getRowClassName, NJVButton, NJVInput, NJVTable, TitleLevel3 } from '../../component/core-component';
import { Constant } from '../../core/constant';
import { EyeOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { HTTP_METHOD } from '../../network/httpMethod';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { useToggle } from 'react-use';
import { MEDIA_TYPE } from '../../network/mediaType';
import Theme from '../../component/theme';

const DriverPoints = () => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useState(false);
  const [isUpdating, toggleUpdating] = useToggle(false);
  const [isOpenDrawer, toggleDrawer] = useToggle(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [updateData, setUpdateData] = useState(undefined);
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [pointsUpdateConfirm, togglePointsUpdateConfirm] = useToggle(false);
  const [filterData, setFilterData] = useState();
  // driver points histories
  const [pointsHistories, setPointsHistories] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState({});

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      driverNo: filterData?.searchDriverNumber?.trim() ? filterData?.searchDriverNumber?.trim() : '',
      assignHub: filterData?.searchPhoneNumber?.trim() ? filterData?.searchPhoneNumber?.trim() : '',
    };

    ApiHandler({
      url: Api.drivers_all,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  // const fetchPointsHistories = (driverId, pageNumber) => {
  //   console.log(pageNumber);
  //   ApiHandler({
  //     url: Api.driver_points_all,
  //     method: HTTP_METHOD.GET,
  //     requestParams: {
  //       size: pageSize,
  //       page: pageNumber ? pageNumber - 1 : pointsHistoryPage,
  //       driverId,
  //     },
  //   })
  //     .then((response) => {
  //       setPointHistoryPage(pageNumber ? pageNumber - 1 : page);
  //       setPointsHistoryTotalPagination(response?.totalElements);
  //       setPointsHistories(response?.content);
  //     })
  //     .finally(() => {
  //       toggleFetchingPointsHistories();
  //       toggleDrawer(true);
  //     });
  // };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, []);

  const updatePoints = (formData) => {
    formData = formData ?? updateData;
    toggleSubmitting();
    ApiHandler({
      url: Api.update_driver_points,
      method: HTTP_METHOD.PUT,
      requestData: { ...formData, points: (formData.points ?? 0) + formData.newPoints },
    })
      .then(() => {
        fetchData();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        toggleSubmitting();
        toggleModelOpen(false);
        togglePointsUpdateConfirm(false);
      });
  };

  const handleMultiReset = () => {
    toggleUpdating();

    ApiHandler({
      url: Api.reset_multiple_points,
      method: HTTP_METHOD.POST,
      requestData: { ids: selectedIds },
    })
      .then(() => fetchData(), setSelectedIds([]))
      .finally(() => toggleUpdating(), toggleModelOpen());
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys) => {
      setSelectedIds(selectedRowKeys);
    },
  };

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Driver No',
      dataIndex: 'driverNo',
    },
    {
      title: 'Current Points',
      dataIndex: 'points',
      render: (v) => <span>{v ?? 0}</span>,
    },
    {
      title: 'Points',
      dataIndex: 'newPoints',
      render: (v, row) => (
        <InputNumber
          value={v ?? 0}
          onChange={(points) =>
            setData((arr) =>
              arr.map((v) => {
                if (v.id === row.id) {
                  return {
                    ...v,
                    newPoints: points,
                  };
                }
                return v;
              }),
            )
          }
        />
      ),
    },
    {
      title: 'Action',
      textAlign: 'center',
      render: (row) => (
        <Space>
          <Tooltip placement="top" title={'Save Changes'} arrow={false}>
            <Button
              size={'middle'}
              loading={isSubmitting}
              type="primary"
              onClick={() => {
                setUpdateData(row);
                togglePointsUpdateConfirm();
              }}
            >
              <SaveOutlined /> Save
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={'Reset Points'} arrow={false}>
            <Button
              size={'middle'}
              loading={isSubmitting}
              type="primary"
              onClick={() => {
                setUpdateData({ ...row, points: 0 });
                toggleModelOpen();
              }}
              danger
            >
              <ReloadOutlined /> Reset
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={'Driver Points Histories'} arrow={false}>
            <Button
              size={'middle'}
              type="primary"
              disabled={isOpenDrawer}
              onClick={() => {
                setSelectedDriver(row);
                // fetchPointsHistories(row.id);
                toggleDrawer(true);
              }}
            >
              <EyeOutlined /> Points Histories
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  useEffect(() => {}, [isOpenDrawer]);

  // eslint-disable-next-line react/display-name
  const DriverPointsHistoryDrawer = memo(({ isOpen, closeDrawer, size, driverId }) => {
    const [histories, setHistories] = useState([]);
    const [pointsHistoryPage, setPointHistoryPage] = useState(0);
    const [pointsHistoryTotalPagination, setPointsHistoryTotalPagination] = useState(0);
    const [isFetchingPointsHistories, toggleFetchingPointsHistories] = useState(false);

    const pointsHistoryColumns = [
      {
        title: 'No.',
        key: 'index',
        render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
        width: 5,
      },
      {
        title: 'Old Points',
        render: (row) => (
          <Typography.Text>
            {row.oldPoints ? `${row.oldPoints}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
          </Typography.Text>
        ),
      },
      {
        title: 'Current Points',
        render: (row) => (
          <Typography.Text>{`${row.currentPoints}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography.Text>
        ),
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        render: (v) => <span>{v}</span>,
      },
      {
        title: 'Date',
        dataIndex: 'createdDate',
        render: (v) => <span>{dayjs(v).format('YYYY-MM-DD HH:mm:ss')}</span>,
      },
    ];
    const fetchPointsHistories = (pageNumber) => {
      toggleFetchingPointsHistories(true);
      ApiHandler({
        url: Api.driver_points_all,
        method: HTTP_METHOD.GET,
        requestParams: {
          size: pageSize,
          page: pageNumber ? pageNumber - 1 : pointsHistoryPage,
          driverId,
        },
      })
        .then((response) => {
          setPointHistoryPage(pageNumber ? pageNumber - 1 : page);
          setPointsHistoryTotalPagination(response?.totalElements);
          setHistories(response?.content);
        })
        .finally(() => {
          toggleFetchingPointsHistories();
        });
    };
    useEffect(() => {
      fetchPointsHistories();
    }, []);

    const handlePointsHistoryPaginationChange = (driverId, pageNumber) => {
      fetchPointsHistories(driverId, pageNumber);
    };
    return (
      <Drawer
        title="Driver Points History"
        width={700}
        onClose={closeDrawer}
        open={isOpen}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Card style={{ marginBottom: 15, backgroundColor: Theme.colors.card_bg_color, width: '60%' }}>
          <Row>
            <Col span={24}>
              <Typography.Title>
                Total : {`${selectedDriver.points ?? 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography.Title>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24}>
            {isFetchingPointsHistories ? (
              <Skeleton active />
            ) : (
              <NJVTable
                rowKey="id"
                style={{ borderRadius: 10 }}
                columns={pointsHistoryColumns}
                dataSource={histories}
                pagination={{
                  position: 'top' | 'bottom',
                  total: pointsHistoryTotalPagination,
                  current: pointsHistoryPage + 1,
                  onChange: (pageNumber) => {
                    handlePointsHistoryPaginationChange(pageNumber);
                  },
                  defaultPageSize: size,
                  showSizeChanger: false,
                }}
                rowClassName={getRowClassName}
              />
            )}
          </Col>
        </Row>
      </Drawer>
    );
  });

  return (
    <>
      {isOpenDrawer && (
        <DriverPointsHistoryDrawer
          isOpen={isOpenDrawer}
          closeDrawer={toggleDrawer}
          histories={pointsHistories}
          size={pageSize}
          driverId={selectedDriver.id}
        />
      )}
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Driver Points'} />
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by Driver Number"
            onChange={(e) => onChangeFilter('searchDriverNumber', e.target.value)}
          />
        </Col>
        <Col span={3}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        {selectedIds.length > 0 && (
          <Col span={3}>
            <NJVButton loading={isUpdating} type="primary" onClick={toggleModelOpen}>
              <ReloadOutlined /> Reset
            </NJVButton>
          </Col>
        )}
        <Col span={24}>
          {isFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowSelection={rowSelection}
              rowKey="id"
              style={{ borderRadius: 10 }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Reset Points"
        open={isModelOpen}
        onOk={() => (selectedIds.length === 0 ? updatePoints() : handleMultiReset())}
        confirmLoading={isSubmitting || isUpdating}
        onCancel={toggleModelOpen}
      >
        <p>Are you sure want to reset the driver points?</p>
      </Modal>
      {updateData && (
        <Modal
          title="Update Points"
          open={pointsUpdateConfirm}
          onOk={() => updatePoints()}
          confirmLoading={isSubmitting || isUpdating}
          onCancel={togglePointsUpdateConfirm}
        >
          <p>Are you sure want to update the driver points?</p>
          <p>
            Points will change from {updateData.points || 0} to {(updateData.points ?? 0) + (updateData.newPoints || 0)}
          </p>
        </Modal>
      )}
    </>
  );
};

export default DriverPoints;
