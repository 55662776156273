import { PlusOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, Divider, Modal, Pagination, Row, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-resizable/css/styles.css';
import { Link } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { NJVButton, NJVInput, NJVSelect } from '../../component/core-component';
import { SvgCalendarOutlined } from '../../component/custom-svg-icon';
import Theme from '../../component/theme';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';
import { v4 as uuidv4 } from 'uuid';

const weekDaysMap = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const AssignType = {
  assign: 'ASSIGN',
  unassign: 'UNASSIGN',
  reassign: 'REASSIGN',
};

const convertDateToDayJs = (targetDate) => {
  if (targetDate) {
    const date = dayjs(new Date(targetDate));
    return date.format('YYYY-MM-DD HH:mm');
  }
  return null;
};

const convertStringDateToDate = (strDate) => {
  if (strDate) {
    const dayjsDate = dayjs(strDate, 'YYYY-MM-DD HH:mm');
    return dayjsDate.toDate();
  }
  return null;
};

const defaultColor = '#1677ff';

const containerStyle = {
  textAlign: 'center',
  backgroundColor: '#EDEDED',
  paddingTop: '12px',
  paddingBottom: '12px',
  color: 'black',
  fontWeight: 'bold',
  borderRadius: 10,
};

const currentDateStyle = {
  marginTop: 5,
  padding: 10,
  backgroundColor: defaultColor,
  display: 'inline-block',
  borderRadius: 50,
  color: 'white',
  fontSize: 17,
};

const dateStyle = {
  marginTop: 5,
  padding: 10,
  display: 'inline-block',
  fontSize: 17,
};

const containerStyle2 = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px',
  borderRadius: 10,
  color: 'white',
  fontWeight: 'bold',
  border: '1px dashed lightgrey',
};

const vehicleContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px',
  borderRadius: 10,
  color: 'black',
  fontWeight: 'bold',
  backgroundColor: '#EDEDED',
};

let stompClient = null;

const DriverVehicleAssignWithCalendarV2 = () => {
  const selectedDate = dayjs();
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [assignDate, setAssignDate] = useState(null);
  const [vehicles, setVehicles] = useState([]);

  const [assignSlotMap] = useState(new Map());
  const [currentDateMap] = useState(new Map());
  const [vehicleMonthYear, setVehicleMonthYear] = useState(null);
  const [unAssignId, setUnAssignId] = useState(null);
  const [selectedDriverAssignment, setSelectedDriverAssignment] = useState(null);
  const [unavailableAssignDates, setUnavailableAssignDates] = useState([]);
  const [currentWeekDay, setCurrentWeekDay] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [regions, setRegions] = useState([]);

  const [socketConnected, setSocketConnected] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openReAssignModal, setOpenReAssignModal] = useState(false);
  const [openUnavailableAssignDateModal, setOpenUnavailableAssignDateModal] = useState(false);
  const [openUnassignConfirmationModal, setOpenUnassignConfirmationModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);

  const [vehiclePageable, setVehiclePageable] = useState({ page: 0, size: 20, totalElements: 0 });

  const [searchQuery, setSearchQuery] = useState('');

  const [updateState, setUpdateState] = useState(uuidv4);

  useEffect(() => {
    getCurrentWeek(selectedDate);
    currentDateMap.set('current', selectedDate);
  }, []);

  useEffect(() => {
    if (!socketConnected) {
      connectSocket();
    }
  }, []);

  useEffect(() => {
    // fetchData();
  }, []);

  const fetchData = async () => {
    await ApiHandler({
      url: Api.regions_all,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.FORM_DATA,
    })
      .then((response) => {
        const regionTemp = response?.map((region) => {
          return {
            id: region.id,
            label: region.regionName,
            value: region.id,
          };
        });
        setRegions(regionTemp);
        if (regionTemp && regionTemp.length > 0) {
          const firstRegion = regionTemp[0];
          setSelectedRegionId(firstRegion?.id);
          const requestObject = {
            regionId: firstRegion?.id,
            page: vehiclePageable.page,
            size: vehiclePageable.size,
          };
          stompClient.send('/app/getVehicles', {}, JSON.stringify(requestObject));
        }
      })
      .catch((err) => {});
  };

  const getCurrentWeek = (currentDate) => {
    const today = dayjs();
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const firstDayOfWeek = currentDate.clone().startOf('week').add(i, 'day');
      let isCurrent = false;
      if (
        firstDayOfWeek.format('DD') === today.format('DD') &&
        firstDayOfWeek.month() === today.month() &&
        firstDayOfWeek.year() === today.year()
      ) {
        isCurrent = true;
      }
      weekDays.push({
        date: firstDayOfWeek,
        weekDay: weekDaysMap[firstDayOfWeek.day()],
        day: firstDayOfWeek.format('DD'),
        month: firstDayOfWeek.month() + 1,
        year: firstDayOfWeek.year(),
        current: isCurrent,
      });
    }
    setCurrentWeekDay(weekDays);
    return weekDays;
  };

  const getCurrentWeek2 = (currentDate) => {
    const today = dayjs();
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const firstDayOfWeek = currentDate.clone().startOf('week').add(i, 'day');
      let isCurrent = false;
      if (
        firstDayOfWeek.format('DD') === today.format('DD') &&
        firstDayOfWeek.month() === today.month() &&
        firstDayOfWeek.year() === today.year()
      ) {
        isCurrent = true;
      }
      weekDays.push({
        date: firstDayOfWeek,
        weekDay: weekDaysMap[firstDayOfWeek.day()],
        day: firstDayOfWeek.format('DD'),
        month: firstDayOfWeek.month() + 1,
        year: firstDayOfWeek.year(),
        current: isCurrent,
      });
    }
    return weekDays;
  };

  const connectSocket = () => {
    try {
      const Sock = new SockJS(Api.host + '/ws');
      stompClient = over(Sock);
      stompClient.connect(
        {},
        function () {
          setSocketConnected(true);
          stompClient.subscribe('/driver-vehicle-assign/vehicles', onReceiveVehicle);
          stompClient.subscribe('/driver-vehicle-assign/drivers', onReceiveDriver);
          stompClient.subscribe('/driver-vehicle-assign/assignedDriverToDate', onDriverAssign);
          stompClient.subscribe('/driver-vehicle-assign/reAssignDriver', onDriverReAssign);
          stompClient.subscribe('/driver-vehicle-assign/unAssignDriver', onDriverUnAssign);
          stompClient.subscribe('/driver-vehicle-assign/vehicleMonthYear', onReceiveVehicleMonthYear);

          fetchData();
        },
        function () {
          setSocketConnected(false);
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addToMap = (key, value) => {
    assignSlotMap.set(key, value);
  };
  const onReceiveVehicle = (payload) => {
    const payloadData = JSON.parse(payload?.body);

    setVehiclePageable((prevState) => ({
      ...prevState,
      totalElements: payloadData?.totalElements,
    }));
    const temp = payloadData?.content?.map((item) => {
      item['label'] = item.licenseNumber;
      item['value'] = item.id;
      item['data'] = item;
      fetchAssignDriver(item.id, selectedDate);
      return item;
    });
    setVehicles(temp);
  };

  const handleNavigate = (jumpDateType) => {
    let currentDate = currentDateMap.get('current');
    if (jumpDateType === 'NEXT') {
      currentDate = currentDate.add(7, 'day');
    } else if (jumpDateType === 'PREV') {
      currentDate = currentDate.subtract(7, 'day');
    } else if (jumpDateType === 'TODAY') {
      currentDate = dayjs();
    }
    currentDateMap.set('current', currentDate);
    getCurrentWeek(currentDate);
    vehicles?.forEach((vehicle) => {
      assignSlotMap.delete(vehicle.id);
      fetchAssignDriver(vehicle.id, currentDate);
    });
  };

  const fetchAssignDriver = (vehicleId, currentDate) => {
    const requestObject = {
      vehicleId,
      month: currentDate.month() + 1,
      year: currentDate.year(),
    };
    stompClient.send('/app/getVehicleMonthYear', {}, JSON.stringify(requestObject));
  };

  const onReceiveDriver = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    const temp = payloadData?.map((item) => {
      item['label'] = item.fullName + ' [' + item?.driverNo + ']';
      item['value'] = item.id;
      item['data'] = item;
      return item;
    });
    setDrivers(temp);
  };
  const onDriverAssign = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    if (payloadData) {
      const slotList = assignSlotMap.get(payloadData?.vehicleMonthYear?.vehicleId);
      const currentAssignDate = dayjs(new Date(payloadData?.startDateTime)).format('YYYY-MM-DD');
      const tempSlot = slotList.map((slot) => {
        const firstDayOfWeek = new Date(slot.date);
        const sloteDate = dayjs(new Date(slot.date)).format('YYYY-MM-DD');
        if (currentAssignDate === sloteDate) {
          const newSlot = {
            key: weekDaysMap[firstDayOfWeek.getDay()],
            status: 'ASSIGNED',
            id: payloadData?.id,
            driverName: payloadData?.driverDTO.fullName,
            driverNo: payloadData?.driverDTO?.driverNo,
            available: payloadData?.driverDTO?.available,
            date: convertStringDateToDate(payloadData?.startDateTime),
            driverId: payloadData?.driverId,
            month: payloadData?.vehicleMonthYear?.month,
            year: payloadData?.vehicleMonthYear?.year,
            monthYearId: payloadData?.vehicleMonthYear?.id,
          };
          return newSlot;
        } else {
          return slot;
        }
      });
      addToMap(payloadData?.vehicleMonthYear?.vehicleId, tempSlot);
      setVehicleMonthYear(null);
    }
  };

  const onDriverReAssign = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    if (payloadData) {
      const slotList = assignSlotMap.get(payloadData?.vehicleMonthYear?.vehicleId);
      const currentAssignDate = dayjs(new Date(payloadData?.startDateTime)).format('YYYY-MM-DD');
      const tempSlot = slotList.map((slot) => {
        const firstDayOfWeek = new Date(slot.date);
        const sloteDate = dayjs(new Date(slot.date)).format('YYYY-MM-DD');
        if (currentAssignDate === sloteDate) {
          const newSlot = {
            key: weekDaysMap[firstDayOfWeek.getDay()],
            status: 'ASSIGNED',
            id: payloadData?.id,
            driverName: payloadData?.driverDTO.fullName,
            driverNo: payloadData?.driverDTO?.driverNo,
            available: payloadData?.driverDTO?.available,
            date: convertStringDateToDate(payloadData?.startDateTime),
            driverId: payloadData?.driverId,
            month: payloadData?.vehicleMonthYear?.month,
            year: payloadData?.vehicleMonthYear?.year,
            monthYearId: payloadData?.vehicleMonthYear?.id,
          };
          return newSlot;
        } else {
          return slot;
        }
      });
      addToMap(payloadData?.vehicleMonthYear?.vehicleId, tempSlot);
      setUnAssignId(null);
      setSelectedVehicle(null);
      setAssignDate(null);
    }
  };
  const onDriverUnAssign = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    if (payloadData) {
      const currentAssignList = assignSlotMap.get(payloadData?.vehicleMonthYear?.vehicleId);
      const newAssignList = currentAssignList?.map((assignData) => {
        if (assignData.id === payloadData.id) {
          assignData['status'] = 'UNASSIGNED';
          assignData['driverId'] = null;
          assignData['driverName'] = null;
        }
        return assignData;
      });
      assignSlotMap.set(payloadData?.vehicleMonthYear?.vehicleId, newAssignList);
      setUnAssignId(null);
      setOpenUnassignConfirmationModal(false);
    }
  };
  const onReceiveVehicleMonthYear = (payload) => {
    const payloadData = JSON.parse(payload?.body);
    if (payloadData) {
      setVehicleMonthYear(payloadData?.id);
      setUpdateState(uuidv4);
      const dateMap = new Map();
      payloadData?.driverAssignmentDTOS?.forEach((driverAssignment) => {
        const data = {
          id: driverAssignment?.id,
          driverName: driverAssignment?.driverDTO.fullName,
          driverNo: driverAssignment?.driverDTO?.driverNo,
          available: driverAssignment?.driverDTO?.available,
          date: convertStringDateToDate(driverAssignment?.startDateTime),
          driverId: driverAssignment?.driverId,
        };
        const jsDate = dayjs(convertStringDateToDate(driverAssignment?.startDateTime));
        dateMap.set(jsDate.format('YYYY-MM-DD'), data);
      });

      const assignSlots = getCurrentWeek2(currentDateMap.get('current')).map((item) => {
        const currentDate = dayjs(item.date).format('YYYY-MM-DD');
        const assignData = dateMap.get(currentDate);
        if (assignData) {
          return {
            key: item.weekDay,
            date: item.date,
            status: 'ASSIGNED',
            ...assignData,
            month: payloadData?.month,
            year: payloadData?.year,
            monthYearId: payloadData?.id,
          };
        } else {
          return {
            key: item.weekDay,
            date: item.date,
            status: 'UNASSIGNED',
            month: payloadData?.month,
            year: payloadData?.year,
            monthYearId: payloadData?.id,
          };
        }
      });
      addToMap(payloadData?.vehicleId, assignSlots);
    }
  };

  const onSelectDriverToAssign = (assignType, driver) => {
    const date = new Date(assignDate);
    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59);

    if (assignType === AssignType.assign) {
      const requestObject = {
        driverId: driver.id,
        startDateTime: convertDateToDayJs(startDate),
        endDateTime: convertDateToDayJs(endDate),
        assignType: 'SINGLE_DATE',
        vehicleMonthYear: {
          id: vehicleMonthYear,
          vehicleId: selectedVehicle,
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        },
      };
      stompClient.send('/app/assignedDriverToDate', {}, JSON.stringify(requestObject));
      setSelectedDriver(null);
      setSelectedVehicle(null);
      setAssignDate(null);
      setOpenModal(false);
    } else if (assignType === AssignType.reassign) {
      const requestObject = {
        id: unAssignId,
        driverId: driver.id,
      };
      stompClient.send('/app/reAssignDriver', {}, JSON.stringify(requestObject));
      setOpenReAssignModal(false);
      setOpenUnassignConfirmationModal(false);
    }
  };

  const unAssignDriver = () => {
    const requestObject = {
      id: unAssignId,
    };
    stompClient.send('/app/unAssignDriver', {}, JSON.stringify(requestObject));
  };

  const assignDriver = (vehicleId, slotData) => {
    setOpenModal(true);
    setSelectedVehicle(vehicleId);
    setVehicleMonthYear(slotData.monthYearId);
    const date = dayjs(new Date(slotData.date));
    setAssignDate(slotData.date);

    stompClient.send(
      '/app/getDrivers',
      {},
      JSON.stringify({ date: date.format('YYYY-MM-DD'), regionId: selectedRegionId }),
    );
  };

  const fetchDriverForReassign = () => {
    const date = dayjs(assignDate);
    stompClient.send('/app/getDrivers', {}, JSON.stringify({ date: date.format('YYYY-MM-DD') }));
  };

  const onSelectCalendar = (selectedDate) => {
    currentDateMap.set('current', selectedDate);
    getCurrentWeek(selectedDate);
    vehicles?.forEach((vehicle) => {
      assignSlotMap.delete(vehicle.id);
      fetchAssignDriver(vehicle.id, selectedDate);
    });
    setOpenCalendarModal(false);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChangeRegion = (value) => {
    setSelectedRegionId(value);
    const requestObject = {
      regionId: value,
      page: 0,
      size: vehiclePageable.size,
    };
    stompClient.send('/app/getVehicles', {}, JSON.stringify(requestObject));
    setVehiclePageable((prev) => ({ ...prev, page: 0, totalElements: 0 }));
  };

  const filteredItems = vehicles?.filter((item) =>
    (item?.licenseNumber ?? '').toLowerCase().includes((searchQuery ?? '').toLowerCase()),
  );

  const onVehiclePaginationChange = (page) => {
    setVehiclePageable((prev) => ({ ...prev, page: page - 1 }));
    const requestObject = {
      regionId: selectedRegionId,
      page: page - 1,
      size: vehiclePageable.size,
    };
    stompClient.send('/app/getVehicles', {}, JSON.stringify(requestObject));
  };

  return (
    <div style={{ margin: 10 }}>
      <Modal
        onClose={() => setOpenCalendarModal(false)}
        onCancel={() => setOpenCalendarModal(false)}
        width={'100%'}
        style={{ top: 5, bottom: 5 }}
        footer={null}
        open={openCalendarModal}
      >
        <div style={{ marginTop: 10 }}>
          <Calendar onSelect={(date) => onSelectCalendar(date)} fullscreen={true} />
        </div>
      </Modal>
      <Modal footer={null} title="Select Driver" open={openModal} onCancel={() => setOpenModal(false)}>
        <NJVSelect
          showSearch
          size="large"
          placeholder="Select driver"
          onChange={(value, obj) => onSelectDriverToAssign(AssignType.assign, obj)}
          value={selectedDriver}
          bgcolor={Theme.colors.light_gray}
          style={{ width: '100%' }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={drivers}
        />
      </Modal>
      <Modal
        footer={null}
        title="Select replacement driver"
        open={openReAssignModal}
        onCancel={() => setOpenReAssignModal(false)}
      >
        <NJVSelect
          showSearch
          size="large"
          placeholder="Select replacement driver"
          onChange={(value, obj) => onSelectDriverToAssign(AssignType.reassign, obj)}
          value={selectedDriver}
          bgcolor={Theme.colors.light_gray}
          style={{ width: '100%' }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={drivers}
        />
      </Modal>
      <Modal
        footer={null}
        title="Unavailable Assign Date"
        open={openUnavailableAssignDateModal}
        onCancel={() => {
          setOpenUnavailableAssignDateModal(false);
          setUnavailableAssignDates([]);
        }}
      >
        <Space>
          {unavailableAssignDates?.map((item) => (
            <Tag key={item}>{item}</Tag>
          ))}
        </Space>
      </Modal>
      <Modal
        footer={null}
        title="Detail"
        open={openUnassignConfirmationModal}
        onCancel={() => {
          setOpenUnassignConfirmationModal(false);
          setUnAssignId(null);
        }}
      >
        <Row style={{ marginTop: 20 }}>
          <Col span={12}>
            <div style={{ fontSize: 18, fontWeight: 'bold' }}>{selectedDriverAssignment?.driverName}</div>
            <div style={{ fontSize: 14, fontWeight: 'bold', color: 'gray' }}>{selectedDriverAssignment?.driverNo}</div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Tag color={defaultColor}>{dayjs(new Date(selectedDriverAssignment?.date)).format('YYYY-MM-DD')}</Tag>
          </Col>
          <Divider />
          <Col span={12}>
            <NJVButton type="primary" bgcolor="red" onClick={() => unAssignDriver()}>
              Unassign Now
            </NJVButton>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <NJVButton
              type="primary"
              onClick={() => {
                setOpenReAssignModal(true);
                fetchDriverForReassign();
              }}
            >
              Reassign
            </NJVButton>
          </Col>
        </Row>
      </Modal>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <div style={{ position: 'fixed', padding: '16px 10px 0px', top: 0, left: 0, right: 0, background: 'white' }}>
          <Row gutter={[10, 1]}>
            <Col span={3}>
              <Link to={CustomPath.admin}>
                <Button type="primary">Back</Button>
              </Link>
            </Col>
            <Col span={3}>
              <NJVButton style={{ width: '100%' }} type="primary" onClick={() => handleNavigate('PREV')}>
                Previous
              </NJVButton>
            </Col>
            <Col span={2}>
              <Button size="large" style={{ width: '100%' }} onClick={() => handleNavigate('TODAY')}>
                Today
              </Button>
            </Col>
            <Col
              span={11}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 16, fontWeight: 600 }}
            >
              <span style={{ marginRight: 10 }}>From ({currentWeekDay[0]?.date?.format('MMM DD, YYYY')})</span>
              <span>To ({currentWeekDay[6]?.date?.format('MMM DD, YYYY')})</span>
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                size="large"
                onClick={() => setOpenCalendarModal(true)}
                icon={<SvgCalendarOutlined width={20} height={20} color="white" />}
              />
            </Col>
            <Col span={3}>
              <NJVButton size="middle" style={{ width: '100%' }} type="primary" onClick={() => handleNavigate('NEXT')}>
                Next
              </NJVButton>
            </Col>
          </Row>
          <Row gutter={[10, 1]} style={{ marginTop: 10, marginBottom: 10 }}>
            <Col span={3} style={{ display: 'flex', alignItems: 'end' }}>
              <div>
                <NJVSelect
                  bgcolor={Theme.colors.light_gray}
                  size="large"
                  placeholder="Search by hub"
                  style={{ width: '100%' }}
                  options={regions}
                  allowClear={false}
                  value={selectedRegionId}
                  onChange={handleChangeRegion}
                />
                <NJVInput bgcolor={Theme.colors.light_gray} placeholder="Search" onChange={handleInputChange} />
              </div>
            </Col>
            {currentWeekDay?.map((item) => {
              return (
                <Col span={3} key={item.day}>
                  <div style={containerStyle}>
                    <div style={{ textTransform: 'uppercase' }}>{item.weekDay}</div>
                    <div style={item.current ? currentDateStyle : dateStyle}>{item.day}</div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        {/* Bottom */}
        <div
          style={{
            position: 'fixed',
            height: 60,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#f5f5f5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pagination
            align="center"
            defaultPageSize={vehiclePageable.page + 1}
            current={vehiclePageable.page + 1}
            total={vehiclePageable.totalElements}
            pageSize={vehiclePageable.size}
            showSizeChanger={false}
            onChange={onVehiclePaginationChange}
          />
        </div>

        <Row
          gutter={[10, 8]}
          style={{ marginTop: 10, marginBottom: 200, position: 'relative', zIndex: -1, top: 160, bottom: 200 }}
        >
          {filteredItems?.map((vehicle) => {
            return (
              <>
                <Col span={3}>
                  <div style={vehicleContainerStyle}>{vehicle.licenseNumber}</div>
                </Col>
                {assignSlotMap.get(vehicle.id)?.map((assignSlot) => (
                  <>
                    <Col span={3} key={assignSlot.key}>
                      {assignSlot?.status === 'ASSIGNED' ? (
                        <div
                          style={{
                            ...containerStyle2,
                            color: 'white',
                            fontSize: 13,
                            backgroundColor: assignSlot?.available ? Theme.colors.green : Theme.colors.red,
                            border: 'none',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setOpenUnassignConfirmationModal(true);
                            setUnAssignId(assignSlot?.id);
                            setSelectedVehicle(vehicle.id);
                            setAssignDate(assignSlot?.date);
                            setSelectedDriverAssignment(assignSlot);
                          }}
                        >
                          <div>
                            <div>{assignSlot?.driverName}</div>
                            <div style={{ fontSize: 11, marginTop: 1 }}>{assignSlot?.driverNo}</div>
                          </div>
                        </div>
                      ) : (
                        <div style={containerStyle2}>
                          <Button
                            type="dashed"
                            shape="circle"
                            icon={<PlusOutlined />}
                            onClick={() => assignDriver(vehicle.id, assignSlot)}
                          />
                        </div>
                      )}
                      <>
                        {dayjs(assignSlot?.date)?.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? (
                          <div
                            style={{
                              textAlign: 'center',
                              marginLeft: 35,
                              marginRight: 35,
                              border: `2px solid ${defaultColor}`,
                              marginTop: 2,
                              borderRadius: 10,
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    </Col>
                  </>
                ))}
                <Col span={24}>
                  <Divider style={{ margin: 10 }} />
                </Col>
              </>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default DriverVehicleAssignWithCalendarV2;
