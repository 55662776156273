import CustomPath from '../route/custom-path';
import { getPermission, getUserInfo } from './auth';

const user = getUserInfo();

export const mapBoxAccessToken = process.env.REACT_APP_MAP_BOX_TOKEN;
export const awsPlaceKey =
  'v1.public.eyJqdGkiOiI4MWVhYWQxZi1kODJjLTQzYmMtODk5ZC1jODBiNzM4MDEyYzcifY6DFj0bSAOf_PmKuGq1SsnOWGu0UPE0t1BnPB0S-lnH0tQ8_mhA1W-057gLZ8FhRhAyT9wMDq61tBJSUekJqEPAuhTUHmaWLLVtXePxrQmrbR1WWszuTDU70FrsSXJYkxx9YZLeRVXfx5S4i9Oat6Gz4lzoFQruw3qIQgxyAf8KNJaMsw1l67l8Emx32UjXiEpx80oIEGt_s7xDFKrZ-novWYjrYAQuL4LvAMPCrC9x9kxqEdG7ksbAIVXmC4XeU6t8iYOVkWsYGywTtn1DoHr513PZHzOHgoHW0dQ59sL4wVr8bLiFKoQdNcFhCGTLPAi61UXsh8EPOP6114escpw.MzRjYzZmZGUtZmY3NC00NDZiLWJiMTktNTc4YjUxYTFlOGZi';
export const awsRouteKey =
  'v1.public.eyJqdGkiOiJlZjI4MTZmMy1iZWU5LTRlYjItODZjYy1mOGI5MWJhMjU0NTQifV9veDiShOjOhH8v4K57-h0VHAOdkIv7X9FSyUVFeEjooW7C68SAv0rWtT0N-PW1oX4MOwoHHWlLNVUrIvehAOfmjGo3nb3DxOFnCfsoJ6tfTOVhs0E5e6mzgHCxvvs2kIrfHuetVZt9ry7ovbDVN1zIBoej9K-sZU-Iy9wn3U3mb8mvSyguQmWykZ15Gmovhh1t7F92gMvm5FZAkbQQfLOxy6UJHRkc1dfzyPe1AOsIXvBZImetG-UIYLSo9ZptKfdJRJe1leD3ZZBdiHrm52pRd9cMOgOvNxizNx09ln3K22trg4TzMgOGdDNZfURaYPJ7pE5I6_C1MjiUL6u5W1w.MzRjYzZmZGUtZmY3NC00NDZiLWJiMTktNTc4YjUxYTFlOGZi';
export const Constant = {
  pageSize: 20,
  nrcValueRegex: /^(\d+)\/([A-Za-z]+)\((\w)\)(\d+)$/,
  paymentMethod: Object.freeze({
    CreditCard: 'Credit Card',
    Cash: 'Cash',
    PayPal: 'PayPal',
  }),
  userStatus: Object.freeze({
    Active: 'Active',
    Inactive: 'Inactive',
    // Pending: 'Pending',
    // Aprroved: 'Aprroved',
    // Reject: 'Reject',
  }),
  insuranceCompany: Object.freeze({
    GGI: 'GGI',
    GWI: 'GWI',
    ALifeInsurance: 'ALife Insurance',
    FNI: 'FNI',
    MyanmarInsurance: 'Myanmar Insurance',
  }),
  vehicleType: Object.freeze({
    Vans: 'Vans',
    BoxTrucks: 'Box Trucks',
  }),
};

export const PromotionType = Object.freeze({
  FlatAmount: 'Flat Amount',
  Percentage: 'Percentage',
});

export const PromotionEligibleUserType = Object.freeze({
  NEW_USER: 'New User',
  REFERRAL: 'Referral',
  LOYALTY: 'Loyalty',
  ALL: 'All',
});

export const FaqType = Object.freeze({
  GeneralInfo: 'General Info',
  Account: 'Account',
  Booking: 'Booking',
  Payment: 'Payment',
  // Others FAQ type here
});

export const UserType = Object.freeze({
  Driver: 'Driver',
  Passenger: 'Passenger',
});

export const PaymentMethod = Object.freeze({
  CreditCard: 'Credit Card',
  Cash: 'Cash',
  PayPal: 'PayPal',
});

export const UserStatus = Object.freeze({
  Active: 'Active',
  Inactive: 'Inactive',
  Deactivate: 'Deactivate',
});

export const AssignType = Object.freeze({
  Assign: 'Assign',
  Unassign: 'Unassign',
});

export const Gender = Object.freeze({
  Male: 'Male',
  Female: 'Female',
});

export const DayOfWeek = Object.freeze({
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
});

export const OpeningHoursStatus = Object.freeze({
  Open: 'Open',
  Closed: 'Closed',
});

export const InsuranceCompany = Object.freeze({
  GGI: 'GGI',
  GWI: 'GWI',
  ALifeInsurance: 'ALife Insurance',
  FNI: 'FNI',
  MyanmarInsurance: 'Myanmar Insurance',
});

export const VehicleType = Object.freeze({
  Sedan: 'Sedan',
  Hatchback: 'Hatchback',
  StationWagon: 'Station Wagon',
  MPV: 'MPV',
  SUV: 'SUV',
});

export const Regions = Object.freeze({
  YGN: 'Yangon',
  MDY: 'Mandalay',
  NPT: 'Naypyidaw',
  BGO: 'Bago',
  MGW: 'Magway',
  SGG: 'Sagaing',
  TNI: 'Tanintharyi',
  AYW: 'Ayeyarwady',
  KCN: 'Kachin',
  KYH: 'Kayah',
  KYN: 'Kayin',
  CHK: 'Chin',
  MNR: 'Mon',
  RKH: 'Rakhine',
  SHN: 'Shan',
});

export const sosStatus = Object.freeze({
  Pending: 'Pending',
  InProgress: 'In Progress',
  Resolved: 'Resolved',
});

export const userPermissions = getPermission();

export const MENU = {
  ADMIN: { route: CustomPath.admin, name: 'Admin', header: 'User Management' },
  ROLE: { route: CustomPath.role, name: 'Role & Permission', header: 'User Management' },
  PASSENGER: { route: CustomPath.passenger, name: 'Passenger', header: 'User Management' },
  DRIVER: { route: CustomPath.driver, name: 'Driver', header: 'Fleet Management' },
  ACTIVE_DRIVER: { route: CustomPath.active_driver_list, name: 'Active Driver List', header: 'Fleet Management' },
  VEHICLE: { route: CustomPath.vehicle, name: 'Vehicle', header: 'Fleet Management' },
  ASSIGN: { route: CustomPath.driver_vehicle_assign, name: 'Driver Roster', header: 'Fleet Management' },
  AVAILABILITY_CHECK: {
    route: CustomPath.driver_vehicle_availability_check,
    name: 'Availability Check',
    header: 'Fleet Management',
  },
  DRIVER_POINTS_MANAGEMENT: {
    route: CustomPath.driver_points,
    name: 'Driver Points Management',
    header: 'Fleet Management',
  },
  INCENTIVE: { route: CustomPath.incentive_defination, name: 'Incentive Rule', header: 'Incentive' },
  INCENTIVE_HISTORY: { route: CustomPath.incentive_history, name: 'Incentive Dashboard', header: 'Incentive' },
  TRIP_ASSIGNMENT: { route: CustomPath.trip_dispatcher, name: 'Dispatcher', header: 'Operations' },
  BOOKING: { route: CustomPath.booking, name: 'Booking', header: 'Operations' },
  TRIP_HISTORY: { route: CustomPath.trip_history, name: 'Trip History', header: 'Operations' },
  TRIP_COMPLAINTS: { route: CustomPath.trip_complaint_list, name: 'Trip Complaint', header: 'Operations' },
  LIVE_MAP: { route: CustomPath.driver_map, name: 'Live Map', header: 'Operations' },
  REGION: { route: CustomPath.region, name: 'Region', header: 'Region & Zone' },
  ZONE: { route: CustomPath.zone, name: 'Zone', header: 'Region & Zone' },
  NOTIFICATION_CHANNEL: {
    route: CustomPath.notification_channel,
    name: 'Create Notification',
    header: 'Notification Management',
  },
  SEND_NOTIFICATION: {
    route: CustomPath.notification_push,
    name: 'Send Notification',
    header: 'Notification Management',
  },
  NOTIFICATION_HISTORY: {
    route: CustomPath.notification_history,
    name: 'Notification History',
    header: 'Notification Management',
  },
  FAQ: { route: CustomPath.faq, name: 'FAQ', header: 'Support' },
  CHARGING_STATION: { route: CustomPath.charging_station, name: 'Charging Station', header: 'Support' },
  SOS_ALERTS: { route: CustomPath.sos, name: 'SOS Alerts', header: 'Alerts' },
  PROMOTION: { route: CustomPath.promotion, name: 'Promotion', header: 'Marketing' },
  PROMOTION_USAGE_HISTORY: {
    route: CustomPath.promotion_usage_history,
    name: 'Promotion Usage History',
    header: 'Marketing',
  },
  PICKUP_FARE: { route: CustomPath.pickup_fare, name: 'Pickup Fare', header: 'Fare Management' },
  WAITING_FARE: { route: CustomPath.waiting_charges, name: 'Waiting Fare', header: 'Fare Management' },
  DISTANCE_FARE: { route: CustomPath.distance_fare, name: 'Distance Fare', header: 'Fare Management' },
  TIME_FARE: { route: CustomPath.time_fare, name: 'Time Fare', header: 'Fare Management' },
  PEAK_HOUR_FARE: { route: CustomPath.peak_hour, name: 'Peak Hour Fare', header: 'Fare Management' },
  TRAFFIC_FARE: { route: CustomPath.traffic_charges, name: 'Traffic Fare', header: 'Fare Management' },
  WEATHER_FARE: { route: CustomPath.weather_condition, name: 'Weather Fare', header: 'Fare Management' },
};

export const colorConstant = [
  '#FF5733',
  '#3357FF',
  '#FF33A1',
  '#A133FF',
  '#FF8333',
  '#33FF83',
  '#8333FF',
  '#FF3383',
  '#33A1FF',
  '#57FF33',
  '#FF5733',
  '#5733FF',
  '#A1FF33',
  '#FF33A1',
  '#3383FF',
  '#83FF33',
  '#33FFA1',
  '#FFA133',
  '#33FF33',
  '#33FF57',
];

export const PricingStatus = Object.freeze({
  PickupFare: 'Pickup Fare',
  WaitingCharges: 'Waiting Charges',
  DistanceFare: 'Distance Fare',
  TimeFare: 'Time Fare',
});

export const WeatherStatus = Object.freeze({
  Thunderstorm: 'Thunderstorm',
  Drizzle: 'Drizzle',
  Rain: 'Rain',
  Clouds: 'Clouds',
  Clear: 'Sunny',
});

export const TripType = Object.freeze({
  FIXED_TRIP: 'Fixed Trip',
  KILO_TRIP: 'Kilo Trip',
});

export const TripState = Object.freeze({
  Pending: 'Pending',
  Ongoing: 'Ongoing',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
});

export const TripStatus = Object.freeze({
  Requested: 'Requested',
  Assigned: 'Assigned',
  Refused: 'Refused',
  SearchingDriver: 'SearchingDriver',
  DriverNotFound: 'DriverNotFound',
  Accepted: 'Accepted',
  DriverArrived: 'DriverArrived',
  Started: 'Started',
  DropOff: 'DropOff',
  Completed: 'Completed',
  EarlyDropOff: 'EarlyDropOff',
  Cancelled: 'Cancelled',
});

export const ComplaintsType = Object.freeze({
  DriverBehavior: 'DriverBehavior',
  VehicleCondition: 'VehicleCondition',
});

export const CoreConfigType = Object.freeze({
  APP_FORCE_CREATE: 'App Force Create',
  APP_FORCE_UPDATE: 'App Force Update',
});
