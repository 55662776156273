import { Col, Drawer, Form, Row, Skeleton, Space, TimePicker } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';

import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVInput,
  NJVTable,
  NJVTimePicker,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import dayjs from 'dayjs';

function RegionPage() {
  const pageSize = Constant.pageSize;
  const type = 'ZONE';

  const updateFormRef = useRef();

  const [page, setPage] = useState(1);
  const [totalPagination, setPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [updateDataTemp, setUpdateDataTemp] = useState(null);

  const [openCreateFormDrawer, setOpenCreateFormDrawer] = useState(false);
  const [openUpdateFormDrawer, setOpenUpdateFormDrawer] = useState(false);

  useEffect(() => {
    if (openCreateFormDrawer || openUpdateFormDrawer) return;
    fetchData();
  }, [openCreateFormDrawer, openUpdateFormDrawer]);

  const fetchData = () => {
    setDataFetching(true);
    ApiHandler({
      url: Api.region_filter,
      method: HTTP_METHOD.GET,
      mediaType: MEDIA_TYPE.FORM_DATA,
    })
      .then((response) => {
        setData(response?.content);
      })
      .catch((error) => {})
      .finally(() => setDataFetching(false));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'Region Name',
      key: 'regionName',
      dataIndex: 'regionName',
    },
    {
      title: 'Start Time',
      key: 'startTime',
      dataIndex: 'startTime',
    },
    {
      title: 'End Time',
      key: 'endTime',
      dataIndex: 'endTime',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: (region) => (
        <Space>
          <NJVEditButton
            size={'middle'}
            shape="circle"
            handleClick={() => {
              const currentDate = dayjs();
              const fromDateTime = region.startTime?.split(':');
              const toDateTime = region.endTime?.split(':');
              setUpdateDataTemp({
                ...region,
                startTime: fromDateTime ? currentDate.hour(fromDateTime[0]).minute(fromDateTime[1]) : '',
                endTime: toDateTime ? currentDate.hour(toDateTime[0]).minute(toDateTime[1]) : '',
              });
              setOpenUpdateFormDrawer(true);
            }}
            type={type}
          />
        </Space>
      ),
    },
  ];

  const handlePaginationChange = () => {};

  const closeCreateFormDrawer = () => {
    setOpenCreateFormDrawer(false);
  };
  const closeUpdateFormDrawer = () => {
    setOpenUpdateFormDrawer(false);
  };

  // eslint-disable-next-line react/display-name
  const CreateFormDrawer = memo(() => {
    const [createLoading, setCreateLoading] = useState(false);
    const createRegion = (formData) => {
      setCreateLoading(true);
      const requestData = {
        regionName: formData.regionName,
        startTime: formData.startTime.format('HH:mm'),
        endTime: formData.endTime.format('HH:mm'),
      };
      ApiHandler({
        url: Api.region,
        method: HTTP_METHOD.POST,
        mediaType: MEDIA_TYPE.JSON,
        requestData,
      })
        .then((response) => {
          // fetchData();
        })
        .catch((error) => {})
        .finally(() => {
          setCreateLoading(false);
          closeCreateFormDrawer();
        });
    };
    return (
      <Drawer
        title="Create a new region"
        width={500}
        onClose={closeCreateFormDrawer}
        open={openCreateFormDrawer}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form layout="vertical" onFinish={createRegion}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="regionName" label={'Region Name'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVInput bgcolor={'#f1f1f1'} placeholder={'Enter region name'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="startTime" label={'From Time'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVTimePicker bgcolor={'#f1f1f1'} format={'HH:mm'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="endTime" label={'To Time'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVTimePicker bgcolor={'#f1f1f1'} format={'HH:mm'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={createLoading}>
                  Create
                </NJVButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }, []);

  // eslint-disable-next-line react/display-name
  const UpdateFormDrawer = memo(() => {
    const [updateLoading, setUpdateLoading] = useState(false);
    const updateRegion = (formData) => {
      setUpdateLoading(true);
      const requestData = {
        id: updateDataTemp.id,
        regionName: formData.regionName,
        startTime: formData.startTime.format('HH:mm'),
        endTime: formData.endTime.format('HH:mm'),
      };
      ApiHandler({
        url: Api.region,
        method: HTTP_METHOD.PUT,
        mediaType: MEDIA_TYPE.JSON,
        requestData,
      })
        .then((response) => {
          setUpdateDataTemp(null);
        })
        .catch((error) => {})
        .finally(() => {
          setUpdateLoading(false);
          closeUpdateFormDrawer();
        });
    };
    return (
      <Drawer
        title="Update region"
        width={500}
        onClose={closeUpdateFormDrawer}
        open={openUpdateFormDrawer}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          layout="vertical"
          onFinish={updateRegion}
          initialValues={{
            regionName: updateDataTemp?.regionName,
            startTime: updateDataTemp?.startTime,
            endTime: updateDataTemp?.endTime,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="regionName" label={'Region Name'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVInput value={updateDataTemp?.regionName} bgcolor={'#f1f1f1'} placeholder={'Enter region name'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="startTime" label={'Start Time'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVTimePicker bgcolor={'#f1f1f1'} format={'HH:mm'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="endTime" label={'End Time'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVTimePicker bgcolor={'#f1f1f1'} format={'HH:mm'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={updateLoading}>
                  Update
                </NJVButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }, []);

  return (
    <>
      {openCreateFormDrawer && <CreateFormDrawer />}
      {openUpdateFormDrawer && <UpdateFormDrawer />}
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Region'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NJVAddButton type={type} handleClick={() => setOpenCreateFormDrawer(true)} />
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default RegionPage;
