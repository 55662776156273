import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Checkbox, Col, Form, Row, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import { GetListFromEnumObject } from '../../core/utils';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

const NotificationChannelUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const formRef = useRef();
  const [userTypes, setUserTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
    fetchUserTypes();
    fetchNotificationType();
  }, []);

  const fetchData = async () => {
    await ApiHandler({
      url: Api.notification_channel,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        if (response.status && response.status === 'Active') {
          response['active'] = true;
        } else {
          response['active'] = false;
        }
        if (response?.userTypes) {
          response['userTypeList'] = response?.userTypes.split(',');
        }
        setData(response);
        formRef.current.setFieldsValue(response);
      })
      .catch(() => setLoading(false));
  };

  const handleChange = ({ fileList: newFileList }) => {
    const updateFileList = [];
    newFileList &&
      newFileList.map((file) => {
        updateFileList.push({ ...file, status: 'done' });
      });
    setFileList(updateFileList);
  };

  const fetchUserTypes = async () => {
    const params = {
      enumType: 'USER_TYPE',
    };
    await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, requestParams: params })
      .then((response) => {
        setUserTypes(GetListFromEnumObject(response));
      })
      .catch(() => {});
  };
  const fetchNotificationType = async () => {
    const params = {
      enumType: 'NOTIFICATION_TYPE',
    };
    await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, requestParams: params })
      .then((response) => {
        setNotificationTypes(GetListFromEnumObject(response));
      })
      .catch(() => {});
  };

  const updateNotificationChannel = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('id', data?.id);
    formData.append('title', values.title);
    formData.append('content', values.content);
    formData.append('status', values.active ? 'Active' : 'Inactive');
    formData.append('userType', values.userType);
    formData.append('notificationType', values.notificationType);
    if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
      formData.append('file', values.photoUrl.file.originFileObj);
    }
    await ApiHandler({
      url: Api.notification_channel,
      method: HTTP_METHOD.PUT,
      mediaType: MEDIA_TYPE.FORM_DATA,
      requestData: formData,
    })
      .then(() => {
        navigate(CustomPath.notification_channel);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.notification_channel}>NotificationChannel</Link>,
              },
              {
                title: 'Form',
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
            bordered={false}
            title={'NotificationChannel'}
            extra={
              <Button type="primary" onClick={() => navigate(CustomPath.notification_channel)}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" ref={formRef} onFinish={updateNotificationChannel}>
              <Form.Item name="photoUrl" label={'Upload Notification Photo'}>
                <Upload
                  accept={['image/png', 'image/jpg', 'image/jpeg']}
                  listType="picture-card"
                  fileList={fileList}
                  action={null}
                  showUploadList={{ showPreviewIcon: false }}
                  onChange={handleChange}
                >
                  {fileList?.length >= 1 ? null : (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
              <Form.Item name="channel" label={'Channel'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVInput disabled />
              </Form.Item>
              <Form.Item name="title" label={'Title'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVInput />
              </Form.Item>
              <Form.Item name="content" label={'Content'} rules={[{ required: true, message: '*(requried)' }]}>
                <NJVInput
                  istextareainput={true}
                  maxLength={1000}
                  showCount={true}
                  bordered={false}
                  placeholder={'Something.....'}
                  style={{ backgroundColor: '#fff', height: 200 }}
                />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="notificationType"
                    label={'Notification Type'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select notification type"
                      options={notificationTypes}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="userType" label={'Recipient Type'}>
                    <NJVSelect
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select recipient type"
                      options={userTypes}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="active" valuePropName="checked">
                <Checkbox>Active</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NotificationChannelUpdate;
