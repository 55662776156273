import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCQrVPA00YurPSDb8J2oj_5LAK70OFw6o0',
  authDomain: 'zeimm-6feb9.firebaseapp.com',
  projectId: 'zeimm-6feb9',
  storageBucket: 'zeimm-6feb9.appspot.com',
  messagingSenderId: '397046965346',
  appId: '1:397046965346:web:361bb3061986636f1c5d3b',
  measurementId: 'G-9C44ZYY5SQ',
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
