import { Col, Flex, Row, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getRowClassName,
  NJVAddButton,
  NJVButton,
  NJVEditButton,
  NJVExportButton,
  NJVInput,
  NJVTable,
  TitleLevel3,
} from '../../component/core-component';
import { Constant } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, downloadExcelFile } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

function VehiclePage() {
  const pageSize = Constant.pageSize;
  const type = 'VEHICLE';

  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [dataFetching, setDataFetching] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [downloadingExcel, setDownloadingExcel] = useState(false);

  const handleSearch = () => {
    fetchData();
  };

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      licenseNumber: filterData?.searchLicenseNumber?.trim() ? filterData?.searchLicenseNumber?.trim() : '',
    };

    ApiHandler({
      url: Api.vehicle_filter,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content);
      })
      .catch(() => {})
      .finally(() => setDataFetching(false));
  };

  useEffect(() => {
    setDataFetching(true);
    fetchData();
  }, []);

  const onChangeFilter = (key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 5,
    },
    {
      title: 'License Plate Number',
      dataIndex: 'licenseNumber',
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
    },
    {
      title: 'Model',
      dataIndex: 'model',
    },
    {
      title: 'Color',
      dataIndex: 'color',
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
    },
    {
      title: 'Action',
      dataIndex: '',
      textAlign: 'center',
      width: 60,
      render: ({ id }) => (
        <Space>
          <Link to={`${CustomPath.vehicle_update}/${id}`}>
            <NJVEditButton type={type} />
          </Link>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };
  return (
    <>
      <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <TitleLevel3 label={'Vehicle'} />
        </Col>
        <Col span={12}>
          <Flex justify="flex-end">
            <NJVExportButton
              onClick={() => {
                setDownloadingExcel(true);
                downloadExcelFile({
                  url: Api.vehicle_export_export,
                  requestParams: {},
                  name: 'vehicles-excel-export',
                })
                  .then(() => {})
                  .catch(() => {})
                  .finally(() => setDownloadingExcel(false));
              }}
              loading={downloadingExcel}
            />
            <Link to={CustomPath.vehicle_form}>
              <NJVAddButton type={type} />
            </Link>
          </Flex>
        </Col>
        <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by LincensePlate Number"
            onChange={(e) => onChangeFilter('searchLicenseNumber', e.target.value)}
          />
        </Col>
        {/* <Col span={6}>
          <NJVInput
            bgcolor="#f1f1f1"
            placeholder="Search by gps device number"
            onChange={(e) => onChangeFilter('searchLicenseNumber', e.target.value)}
          />
        </Col> */}
        <Col span={6}>
          <NJVButton type="primary" style={{ width: '100%' }} onClick={handleSearch}>
            Search
          </NJVButton>
        </Col>
        <Col span={24}>
          {dataFetching ? (
            <Skeleton active />
          ) : (
            <NJVTable
              rowKey={'id'}
              style={{ borderRadius: 10 }}
              pagination={{
                position: 'top' | 'bottom',
                total: totalPagination,
                current: page + 1,
                onChange: handlePaginationChange,
                defaultPageSize: pageSize,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={data}
              rowClassName={getRowClassName}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default VehiclePage;
