import { Breadcrumb, Button, Card, Col, Form, message, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NJVButton, NJVInput, NJVRangeDatePicker, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import CustomPath from '../../route/custom-path';
import { ApiHandler } from '../../network/network-manager';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { PromotionEligibleUserType, PromotionType } from '../../core/constant';
import dayjs from 'dayjs';
import { NumberOnlyForOptionalValidator, NumberOnlyValidator } from '../../core/validator';

function PromotionUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const fromRef = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    findPromotion(id);
  }, []);

  const findPromotion = async () => {
    await ApiHandler({
      url: Api.promotion,
      method: HTTP_METHOD.GET,
      specificId: id,
    })
      .then((response) => {
        response = {
          ...response,
          dateRange: [
            dayjs(response.startDateTime, 'YYYY-MM-DD HH:mm'),
            dayjs(response.endDateTime, 'YYYY-MM-DD HH:mm'),
          ],
        };
        setData(response);
        fromRef.current.setFieldsValue(response);
      })
      .catch((error) => setLoading(false));
  };

  const updatePromotion = (formData) => {
    const [startDate, endDate] = formData.dateRange;
    if (formData.type === PromotionType.Percentage && formData.amount > 100) {
      message.error('The promotion percentage cannot exceed 100%');
      return;
    }
    setLoading(true);

    ApiHandler({
      url: Api.promotion,
      method: HTTP_METHOD.PUT,
      requestData: {
        ...formData,
        id: data.id,
        startDateTime: startDate.format('YYYY-MM-DD HH:mm'),
        endDateTime: endDate.format('YYYY-MM-DD HH:mm'),
      },
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.promotion);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.promotion}>Promotion</Link>,
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Promotion Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" onFinish={updatePromotion} ref={fromRef}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="promotionCode"
                    label={'Promotion Code'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVInput disabled placeholder={'Enter Promotion Code'} style={{ color: '#333' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="type" label={'Promotion Type'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVSelect disabled>
                      {Object.entries(PromotionType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="dateRange"
                    label={'Eligible Date Time'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVRangeDatePicker
                      size="large"
                      style={{ width: '100%' }}
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="amount"
                    label={'Amount'}
                    rules={[{ required: true, message: '*(requried)' }, { validator: NumberOnlyValidator }]}
                  >
                    <NJVInput placeholder={'Enter Amount'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="minimumAmount"
                    label={'Minimum Amount'}
                    rules={[{ validator: NumberOnlyForOptionalValidator }]}
                  >
                    <NJVInput placeholder={'Enter Minimum Amount'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="limitPerUser"
                    label={'Limit Per User'}
                    rules={[{ validator: NumberOnlyForOptionalValidator }]}
                  >
                    <NJVInput placeholder={'Enter Limit Per User'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="userLimit"
                    label={'User Limit'}
                    rules={[{ validator: NumberOnlyForOptionalValidator }]}
                  >
                    <NJVInput placeholder={'Enter User Limit'} type="number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="description" label={'Description'}>
                    <NJVInput
                      istextareainput={true}
                      autosize={{ minRows: 3, maxRows: 5 }}
                      placeholder={'Enter Description'}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="eligibleUserType"
                    label={'Eligible User Type'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect disabled>
                      {Object.entries(PromotionEligibleUserType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) => {
                      return prevValues.eligibleUserType !== currentValues.eligibleUserType;
                    }}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('eligibleUserType') === 'LOYALTY' ? (
                        <Form.Item
                          name="numberOfTrip"
                          label={'Number Of Trip'}
                          rules={[{ required: true, message: '*(requried)' }]}
                        >
                          <NJVInput placeholder={'Enter Number Of Trip'} type="number" />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={loading}>
                  Update
                </NJVButton>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PromotionUpdate;
