import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import CustomPath from '../../route/custom-path';
const PageNotFound = () => {
  const navigate = useNavigate();
  const backToHome = () => navigate(CustomPath.admin);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={backToHome} type="primary">
          Back Home
        </Button>
      }
    />
  );
};
export default PageNotFound;
