import { Breadcrumb, Button, Card, Checkbox, Col, Form, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NJVButton, NJVInput, NJVSelect } from '../../component/core-component';
import Theme from '../../component/theme';
import { CoreConfigType } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import CustomPath from '../../route/custom-path';

function CoreConfigForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const createCoreConfig = (formData) => {
    setLoading(true);

    const requestData = {
      ...formData,
      status: formData.active ? 'Active' : 'Inactive',
    };

    ApiHandler({
      url: Api.core_config,
      method: HTTP_METHOD.POST,
      mediaType: MEDIA_TYPE.JSON,
      requestData,
    })
      .then((response) => {
        backToHome();
      })
      .catch((error) => setLoading(false));
  };

  const backToHome = () => navigate(CustomPath.core_config);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Breadcrumb
            items={[
              {
                title: 'Home',
              },
              {
                title: <Link to={CustomPath.core_config}>Core Config</Link>,
              },
              {
                title: 'Core Config Form',
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: Theme.colors.card_bg_color,
              boxShadow: 'none',
            }}
            bordered={false}
            title={'Core Config Form'}
            extra={
              <Button type="primary" onClick={backToHome}>
                Back
              </Button>
            }
          >
            <Form layout="vertical" initialValues={{ active: true }} onFinish={createCoreConfig}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="key" label={'Key'} rules={[{ required: true, message: '*(requried)' }]}>
                    <NJVInput />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="content"
                    label={'Content'}
                    rules={[{ required: true, message: '*(requried)' }]} //
                  >
                    <NJVInput />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="coreConfigType"
                    label={'Core Config Type'}
                    rules={[{ required: true, message: '*(requried)' }]}
                  >
                    <NJVSelect size="large" style={{ width: '100%' }}>
                      {Object.entries(CoreConfigType).map(([key, value]) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </NJVSelect>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="active" valuePropName="checked" label="Active">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <NJVButton type="primary" htmlType="submit" loading={loading}>
                  Create
                </NJVButton>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CoreConfigForm;
