import { Col, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { Constant } from '../core/constant';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import { ApiHandler } from '../network/network-manager';
import { getRowClassName, NJVTable } from './core-component';

const CoreTableComponent = ({ url, componentColumns, filterData, refreshKey }) => {
  const pageSize = Constant.pageSize;
  const [page, setPage] = useState(0);
  const [totalPagination, setTotalPagination] = useState(0);
  const [data, setData] = useState([]);
  const [isFetching, toggleDataFetching] = useToggle(false);

  const fetchData = (pageNumber) => {
    const requestParams = {
      size: pageSize,
      page: pageNumber ? pageNumber - 1 : page,
      ...filterData,
    };

    Object.keys(requestParams).forEach((key) => {
      if (requestParams[key] === null || requestParams[key] === undefined || requestParams[key] === '') {
        delete requestParams[key];
      }
    });

    ApiHandler({
      url,
      method: HTTP_METHOD.GET,
      MediaType: MEDIA_TYPE.JSON,
      requestParams,
    })
      .then((response) => {
        setPage(pageNumber ? pageNumber - 1 : page);
        setTotalPagination(response?.totalElements);
        setData(response?.content ? response?.content : response);
      })
      .catch(() => {})
      .finally(() => toggleDataFetching(false));
  };

  useEffect(() => {
    toggleDataFetching();
    fetchData();
  }, [refreshKey]);

  useEffect(() => {
    fetchData(1);
  }, [filterData]);

  const handlePaginationChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => <span>{page * pageSize + index + 1}</span>,
      width: 80,
      fixed: 'left',
    },
    ...componentColumns,
  ];

  return (
    <>
      <Col span={24}>
        {isFetching ? (
          <Skeleton active />
        ) : (
          <NJVTable
            rowKey={'id'}
            style={{ borderRadius: 10 }}
            pagination={{
              position: 'top' | 'bottom',
              total: totalPagination,
              current: page + 1,
              onChange: handlePaginationChange,
              defaultPageSize: pageSize,
              showSizeChanger: false,
            }}
            columns={columns}
            dataSource={data}
            rowClassName={getRowClassName}
          />
        )}
      </Col>
    </>
  );
};

export default CoreTableComponent;
